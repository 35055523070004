import { useEffect, useRef, useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import CryptoJS from "crypto-js";
import AlertModal2 from "../../utility/component/modals/alertWithLottie";
import logo2 from "../../utility/Logo/icons/orangeIcon.png";
import logo from "../../utility/Logo/TextLogo/onlyBlackText.svg";
import { useNavigate } from "react-router-dom";
import {
  selectEmailVerifyStatus,
  selectSpinnerLoading,
  setALertStatus,
  setAlertMsg,
  setAlertWithTitle,
  setCheckEmailAlert,
  setEmailVerificationStatus,
  setSpinnerLoading,
} from "../../utility/Redux/modal";
import CallAPI from "../../utility/functions/getData";
import { useFocus } from "../../utility/hooks/hooks";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";

import {
  selectOtpModalStatus,
  selectRandomOtp,
  setOtpStatus,
  setToken,
} from "../../utility/Redux/security";
import SpinnerLoading from "../../utility/component/modals/spinnerLoading";
import moment from "moment";
import axios from "axios";
import styles from "../../utility/styles/LoginForm.module.css";
import GeniusFooterLogo from "../../utility/images/ico.png";
import { isTablet } from "react-device-detect";
import { selectLatitude, selectLongitude } from "../../utility/Redux/profile";
import ForgetModel from "./forget";
import CheckEmailModall from "../../utility/component/modals/resetPassword";
import CustomeModal from "../../utility/component/modals/customeModal";
import DraftsTwoToneIcon from "@mui/icons-material/DraftsTwoTone";
import clear from "../../utility/images/clear2.svg";
import HandleIndexedDB from "../../Test/handleIndexDb";
import deleteWholeDatabase from "../../utility/functions/deleteWholeDatabase";
import {
  decodeToken,
  isStateCheckEnabeld,
  licenceExpired_alert,
} from "../../utility/constant";
import { checkStateAllowedOrNot, runLoginFunc } from "./func";
import RetailxLogo from "../../utility/component/styles/homeLogo";
import OtpAlertModal from "../../utility/component/modals/otpAlert";
import { CallAPIPromise } from "../../billing/common";
import Lottie from "lottie-react";
import spinner from "../../utility/json/Spinner.json";

const LoginForm = () => {
  const [gmailAccountDetails, setGmailAccountDetails] = useState("");
  const loading = useSelector(selectSpinnerLoading);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [emailRef, setEmailFocus] = useFocus();
  const [passwordRef, setPasswordFocus] = useFocus();
  const [forget, setForget] = useState(false);
  const [location, setLocation] = useState(null);
  const otpStatus = useSelector(selectOtpModalStatus);
  const randomOTP = useSelector(selectRandomOtp);
  const [userInfo, setUserInfo] = useState("");
  const [err, setErr] = useState("");
  const [mobileErr, setMobileErr] = useState("");
  const [passwrdERR, setpasswrdErr] = useState("");
  const isEmailVerifyModelOpn = useSelector(selectEmailVerifyStatus);
  const move = useNavigate();

  const dispatch = useDispatch();
  const secretPass = process.env.REACT_APP_SECRET_KEY;

  const storeRememberData = async (email, passwrd) => {
    const e = CryptoJS.AES.encrypt(
      JSON.stringify(email),
      secretPass
    ).toString();
    const p = CryptoJS.AES.encrypt(
      JSON.stringify(passwrd),
      secretPass
    ).toString();

    localStorage.setItem(
      "rememberd",
      JSON.stringify({
        email: e,
        password: p,
      })
    );
  };

  const forgetPassword = (emailCheck) => {
    const secretPass = process.env.REACT_APP_AUTH_SECRET_KEY;

    // const hmac = CryptoJS.HmacSHA256(message, secret);
    const hmac = CryptoJS.HmacSHA256(username, secretPass);
    // Convert the HMAC to a string and encode it using Base64
    const hmacString = hmac.toString(CryptoJS.enc.Base64);
    let re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const phoneRegex = /^[6-9]\d{9}$/;
    if (re.test(emailCheck) || phoneRegex.test(emailCheck)) {
      dispatch(setSpinnerLoading("Loading"));
      CallAPIPromise(
        "/api/users/findUserDetail",
        {
          user_email: !phoneRegex.test(emailCheck) ? emailCheck : "",
          user_mobile: phoneRegex.test(emailCheck) ? emailCheck : "",
        },
        hmacString,
        dispatch
      )
        .then((res) => {
          if (res.data[0]) {
            CallAPIPromise(
              `/api/users/resetUserPasswordEmail`,
              {
                user_email: !phoneRegex.test(emailCheck) ? emailCheck : "",
                user_name: res.data[0].user_name,
                user_mobile: phoneRegex.test(emailCheck) ? emailCheck : "",
              },
              hmacString,
              dispatch
            )
              .then((r) => {
                dispatch(setCheckEmailAlert(true));
                // move('/auth/checkEmail')
              })
              .catch((err) => {
                dispatch(setAlertMsg(err.code + " " + err.message));
                dispatch(setALertStatus(true));
              })
              .finally(() => {
                dispatch(setSpinnerLoading(false));
              });
          } else {
            dispatch(setAlertMsg("Not registered user"));
            dispatch(setALertStatus(true));
          }
        })
        .catch((e) => {
          dispatch(setAlertMsg("Something wrong"));
          dispatch(setALertStatus(true));
        });
    } else {
      dispatch(setAlertMsg("Invailed email address"));
      dispatch(setALertStatus(true));
    }
  };
  localStorage.removeItem("financial");
  localStorage.removeItem("financial_company");
  localStorage.removeItem("masterPrefix");
  localStorage.removeItem("user");
  useEffect(() => {
    const isRemmberd = JSON.parse(localStorage.getItem("rememberd"));

    if (isRemmberd) {
      const bytes = CryptoJS.AES.decrypt(isRemmberd.email, secretPass);
      const bytes2 = CryptoJS.AES.decrypt(isRemmberd.password, secretPass);

      if (bytes.sigBytes < 0 || bytes2.sigBytes < 0) {
        localStorage.removeItem("rememberd");
      } else {
        const decEmail = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        const decPass = JSON.parse(bytes2.toString(CryptoJS.enc.Utf8));

        setUsername(decEmail);
        setPassword(decPass);
        setRememberMe(true);
      }
    }
    deleteWholeDatabase("pathHistory");
    localStorage.removeItem("userDefaultSetting");
  }, []);

  const forgetModel = (e) => {
    let re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const phoneRegex = /^[6-9]\d{9}$/;
    if (re.test(e)) {
      setForget(true);
    } else {
      setEmailFocus();
      setMobileErr("Please Enter Valid Email");
      setTimeout(() => {
        setMobileErr("");
      }, 5000);
    }
  };

  const longitude = useSelector(selectLongitude);
  const latitude = useSelector(selectLatitude);
  const loginApi = (data, hmac) => {
    const d = {
      code: data.code,
      user_email: username,
      user_mobile: "",
      user_password: password,
      latidude: latitude ? latitude : "",
      longitude: longitude ? longitude : "",
      ip_address: "",
    };
    if (
      moment(data.end_date).format("YYYY-MM-DD") >=
      moment().format("YYYY-MM-DD")
    ) {
      dispatch(setSpinnerLoading("Authenticating user"));
      CallAPIPromise("/api/users/login/", d, hmac, dispatch)
        .then((e) => {
          if (e.data.token) {
            if (isStateCheckEnabeld) {
              checkStateAllowedOrNot(
                fetchAllowedState,
                data,
                dispatch,
                e,
                rememberMe,
                storeRememberData,
                username,
                password,
                move
              );
            } else {
              runLoginFunc(
                dispatch,
                e,
                data,
                rememberMe,
                storeRememberData,
                username,
                password,
                move
              );
            }
          } else {
            dispatch(
              setAlertWithTitle({
                title: "Alert",
                msg: "Something went wrong",
              })
            );
          }
        })
        .catch((e) => {
          console.log(e);
          dispatch(
            setAlertWithTitle({
              title: "Alert",
              msg: e.response.data.error,
            })
          );
        });
    } else {
      dispatch(
        setAlertWithTitle({
          title: "Alert",
          msg: licenceExpired_alert,
        })
      );
    }
  };

  const handleLogin = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailRegex.test(username)) {
      if (username && password) {
        const secretPass = process.env.REACT_APP_AUTH_SECRET_KEY;
        // const hmac = CryptoJS.HmacSHA256(message, secret);
        const hmac = CryptoJS.HmacSHA256(username, secretPass);
        // Convert the HMAC to a string and encode it using Base64
        const hmacString = hmac.toString(CryptoJS.enc.Base64);
        const data = {
          user_email: username,
          user_mobile: "",
        };
        dispatch(setSpinnerLoading("Getting User Detail"));
        CallAPI(
          "/api/users/userInfo",
          data,
          hmacString,
          (e) => {
            if (e.data.response[0]) {
              const data = e.data.response[0];
              setUserInfo(data);
              if (data.email_verified === "Y") {
                if (data.valid_user === 0) {
                  dispatch(
                    setAlertWithTitle({
                      title: "Alert",
                      msg: "We have received your account activation request. Please wait for account activation.",
                    })
                  );
                } else {
                  loginApi(e.data.response[0], hmacString);
                }
              } else {
                dispatch(
                  setAlertWithTitle({
                    title: "Alert",
                    msg: "We have sent a verification email to your company email. Please verify it to log in.",
                  })
                );
              }
            } else {
              setPasswordFocus();
              dispatch(setSpinnerLoading(false));
              dispatch(
                setAlertWithTitle({
                  title: "Alert",
                  msg: "User not found",
                })
              );
            }
          },
          dispatch
        );
      } else {
        setPasswordFocus();

        setpasswrdErr("Please Enter Password");
        setTimeout(() => {
          setpasswrdErr("");
        }, 5000);
      }
    } else {
      setEmailFocus();
      setMobileErr("Please Enter Email");
      setTimeout(() => {
        setMobileErr("");
      }, 5000);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const formRef = useRef(null);
  // useEffect(() => {
  //   if (indexedDB.databases) {
  //     indexedDB
  //       .databases()
  //       .then((databases) => {
  //         databases.forEach((dbInfo) => {
  //           if (dbInfo.name !== 'SaveIssueCart') {
  //             if (dbInfo.name !== 'Calculations') {
  //               indexedDB.deleteDatabase(dbInfo.name);

  //             }
  //           }
  //         });
  //       })
  //       .catch((error) => {
  //         console.error('Error listing databases:', error);
  //       });
  //   } else {
  //     // Fallback method for browsers that do not support indexedDB.databases()
  //     console.log('indexedDB.databases() is not supported in this browser.');
  //   }
  // }, []);
  const fetchLocation = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios.get(
          "https://ipinfo.io?token=1defe893bbea80"
        );

        const location = response.data;

        resolve(location); // Resolve if the region is Punjab
      } catch (error) {
        reject();
      }
    });
  };

  const fetchAllowedState = (email) => {
    return new Promise(async (resolve, reject) => {
      const secretPass = process.env.REACT_APP_AUTH_SECRET_KEY;
      const hmac = CryptoJS.HmacSHA256(email, secretPass);
      // Convert the HMAC to a string and encode it using Base64
      const hmacString = hmac.toString(CryptoJS.enc.Base64);
      try {
        CallAPI(
          "/api/users/listAllowedStates",
          {
            user_email: email,
          },
          hmacString,
          (e) => {
            resolve(e.data.response);
          },
          dispatch
        );
      } catch (error) {
        resolve([]);
      }
    });
  };

  return (
    <div className=" flex items-center justify-center ">
      <div
        ref={formRef}
        className="fixed inset-0 bg-gray-400 bg-opacity-5 flex items-center justify-center z-50"
      >
        <div className="bg-white rounded-lg shadow-lg  w-full ms:max-w-[350px] sm:max-w-md">
          <div style={newStyles.header}>
            {/* Grid Background */}
            <div style={newStyles.gridContainer}>
              {[...Array(60)].map((_, index) => (
                <div
                  key={index}
                  style={
                    index <= 2
                      ? {}
                      : index <= 11
                      ? newStyles.gridCell
                      : index <= 15
                      ? {}
                      : index <= 19
                      ? {}
                      : index < 25
                      ? newStyles.gridCell
                      : index < 30
                      ? {}
                      : index <= 35
                      ? {}
                      : index <= 38
                      ? {}
                      : {}
                  }
                ></div>
              ))}
            </div>

            {/* Logo Section */}
          </div>
          <div style={{}} className="p-6 ">
            {/* Modal Content */}

            <div
              style={{
                marginTop: -50,
              }}
              className="flex flex-col justify-end    items-center "
            >
              <div style={{}} className="flex items-center ">
                <img
                  src={logo2}
                  style={{
                    height: 35,
                    width: 35,
                  }}
                />
                <img
                  src={logo}
                  style={{
                    width: 60,
                  }}
                />
              </div>
              {/* <div
                  style={{
                    marginTop: -7,
                  }}
                  className="text-[25px]  font-semibold "
                >
                  Login with
                </div> */}
              <div
                style={{
                  marginTop: -2,
                }}
                className="text-sm text-gray-500 "
              >
                Please enter your details to{" "}
                <label className="font-semibold text-gray-600">SignIn</label>
              </div>
            </div>

            {!gmailAccountDetails && (
              <div style={{ marginTop: 10 }} className="text-center mt-4 ">
                <div className="flex justify-center space-x-4 ">
                  <GoogleOAuthProvider clientId="63087371523-ile4jrs20gof4l4mvequ2i91btj9kg0h.apps.googleusercontent.com">
                    <GoogleLogin
                      onSuccess={(response) => {
                        const details = decodeToken(response.credential);
                        console.log(details);
                        const hmac = CryptoJS.HmacSHA256(
                          process.env.REACT_APP_AUTH_HMA_KEY,
                          process.env.REACT_APP_AUTH_SECRET_KEY
                        );
                        const hmacString = hmac.toString(CryptoJS.enc.Base64);
                        dispatch(setSpinnerLoading("Verifying"));
                        CallAPIPromise(
                          "/api/googleAuth2Login/googleTokenVerify",
                          {
                            token: response.credential,
                          }
                        )
                          .catch(() => {
                            dispatch(
                              setAlertWithTitle({
                                title: "Alert",
                                msg: "Google Login Error",
                              })
                            );
                          })
                          .then((e) => {
                            if (e?.data?.token) {
                              const data = e.data.userDetails;
                              setUserInfo(data);
                              setUsername(data.user_email);

                              if (isStateCheckEnabeld) {
                                checkStateAllowedOrNot(
                                  fetchAllowedState,
                                  data,
                                  dispatch,
                                  e,
                                  false,
                                  storeRememberData,
                                  data.user_email,
                                  "",
                                  move
                                );
                              } else {
                                runLoginFunc(
                                  dispatch,
                                  e,
                                  data,
                                  false,
                                  storeRememberData,
                                  data.user_email,
                                  "",
                                  move
                                );
                              }
                            }
                            //   onClose();
                          });
                      }}
                      width={200}
                      onError={() => console.log("Login Failed")}
                    ></GoogleLogin>
                  </GoogleOAuthProvider>
                </div>
                <p className="flex items-center text-gray-500 bg-red mt-4">
                  <span className="flex-1 flex border-t border-gray-200"></span>
                  <span className="mx-2">OR</span>
                  <span className="flex-1 flex border-t border-gray-200"></span>
                </p>
              </div>
            )}

            {
              <div className="">
                <div className="mb-4">
                  <label className="block text-gray-900 text-[13px] mb-2">
                    Your Email Address
                  </label>
                  <input
                    type="email"
                    placeholder={mobileErr ? mobileErr : "Your Email"}
                    ref={emailRef}
                    onKeyDown={(e) => {
                      if (e.code === "Enter" || e.code === "NumpadEnter") {
                        setPasswordFocus();
                      }
                    }}
                    id="username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    className={`w-full px-3 py-2 ${
                      mobileErr
                        ? "placeholder:text-red-500 text-red-500"
                        : "text-black"
                    } border rounded-lg focus:outline-none text-[14px] focus:ring-2 focus:ring-blue-500`}
                  />
                </div>

                <div className="">
                  <label className="block text-gray-900 text-[13px] mb-2">
                    Your password
                  </label>
                  <div className="relative w-full">
                    <input
                      ref={passwordRef}
                      placeholder={passwrdERR ? passwrdERR : "Password"}
                      onKeyDown={(e) => {
                        if (e.code === "Enter" || e.code === "NumpadEnter") {
                          handleLogin();
                        }
                      }}
                      className={`w-full ${
                        passwrdERR
                          ? "placeholder:text-red-500 text-red-500:"
                          : "text-black"
                      } px-3 py-2 border rounded-lg focus:outline-none text-[14px] focus:ring-2 focus:ring-blue-500 pr-10`}
                      type={showPassword ? "text" : "password"}
                      id="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <div
                      className="absolute inset-y-0 right-3 flex items-center cursor-pointer"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? (
                        // Eye icon (Show Password)
                        <i className="bi bi-eye"></i>
                      ) : (
                        // Eye with Slash icon (Hide Password)
                        <i className="bi bi-eye-slash"></i>
                      )}
                    </div>
                  </div>
                </div>

                <div className="flex w-full justify-between items-center mb-3 mt-4">
                  <label className="flex ml-2">
                    <input
                      type="checkbox"
                      color="orange"
                      className="mr-1 "
                      checked={rememberMe}
                      onChange={() => setRememberMe(!rememberMe)}
                    />
                    <div style={{ color: "black" }} className="text-sm">
                      Remember Me
                    </div>
                  </label>
                  <div className="mr-2">
                    <button
                      onClick={() => {
                        if (username) {
                          forgetModel(username);
                        } else {
                          dispatch(
                            setAlertMsg(
                              "Please enter user email address for change password"
                            )
                          );
                          dispatch(setALertStatus(true));
                        }
                      }}
                    >
                      <a
                        style={{
                          textDecoration: "none",
                        }}
                        className="text-orange text-[14px]"
                      >
                        Forgot Password?
                      </a>
                    </button>
                  </div>
                </div>

                <button
                  onClick={handleLogin}
                  type="button"
                  disabled={loading}
                  className="w-full bg-black flex justify-center text-sm items-center text-white py-2 rounded-lg hover:bg-gray-800"
                >
                  {loading && (
                    <Lottie
                      animationData={spinner}
                      loop={true}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        height: "20px",
                        marginRight: "8px",
                      }}
                    />
                  )}
                  Sign in
                </button>

                <button
                  onClick={() => {
                    move("/auth/mobile_signup");
                  }}
                  type="button"
                  className="w-full bg-gray-200 mt-2 text-sm text-black py-2 rounded-lg hover:bg-gray-300"
                >
                  Sign Up
                </button>
              </div>
            }
          </div>

          <div className="py-3 bg-gray-50 border-t border-gray-300 rounded-b-[12px] ">
            <div className="text-xs whitespace-nowrap flex items-center justify-center font-ubuntu text-gray-700">
              <span className="pr-1">A product of</span>
              <div className="flex items-center justify-center px-1">
                <img
                  alt="Genius Office Logo"
                  src={GeniusFooterLogo}
                  width={16}
                  className="inline-block"
                />
              </div>
              <span className="font-semibold text-gray-900">genius office</span>
              <span className="px-1">| 2025. All rights reserved.</span>
            </div>
          </div>
        </div>
      </div>

      <AlertModal2 />
      <ForgetModel
        forget={forget}
        setForget={setForget}
        forgetPassword={forgetPassword}
        email={username}
      />
      <CustomeModal
        status={isEmailVerifyModelOpn}
        data={
          <div className="bg-white t:max-w-[450px] max-w-[320px]">
            <div className="flex justify-end p-3 pb-0 items-center">
              <button
                onClick={() => dispatch(setEmailVerificationStatus(false))}
              ></button>
              <img
                alt=""
                src={clear}
                style={{
                  width: "15px",
                }}
              />
            </div>
            <div
              onClick={() => dispatch(setEmailVerificationStatus(false))}
              className="flex justify-center"
            >
              <DraftsTwoToneIcon color={"warning"} fontSize={"large"} />
            </div>
            <div className="text-center font-semibold mt-3">
              Welcome To Retailx
            </div>
            <div className="text-center mt-3 px-4 ">
              let's get Started, We've just sent you a quick verification email
              to your inbox. verify if for continue.
            </div>
            <div className=" flex justify-center p-4">
              <div>
                <button
                  onClick={() => dispatch(setEmailVerificationStatus(false))}
                  className="bg-orange text-white p-2 text-sm px-5 rounded-sm "
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        }
      />
      <CheckEmailModall />
      <OtpAlertModal
        status={otpStatus}
        otp={randomOTP}
        onCancel={() => {
          dispatch(setOtpStatus(false));
        }}
        onSubmit={() => {
          if (userInfo) {
            const databaseName = "userDetails";
            const storeName = "data";
            deleteWholeDatabase(databaseName);

            const dataToSave = [
              {
                start_date: userInfo.start_date,
                end_date: userInfo.end_date,
                currency_name: userInfo.currency_name,
                currency_symbol: userInfo.currency_symbol,
                user_email: userInfo.user_email,
                ICODE: 1,
                lastLoginDate: moment().format("YYYY-MM-DD"),
              },
            ];

            HandleIndexedDB({
              databaseName,
              storeName,
              dataToSave,
            });
          }
          move("/auth/financial_year", {
            state: {
              CompanyEmail: userInfo?.company_email,
              UserEmail: username,
              companyName: userInfo?.company_name,
            },
          });
        }}
      />
    </div>
  );
};

export default LoginForm;
const newStyles = {
  header: {
    borderTopLeftRadius: "12px",
    padding: 4,
    borderTopRightRadius: "12px",
    boxShadow: "inset 50px 50px 100px #ffffff", // Same horizontal and vertical offset
  },
  gridContainer: {
    inset: 0,
    display: "grid",
    gridTemplateColumns: "repeat(15, 1fr)",
    gridTemplateRows: "repeat(3, 1fr)",
    opacity: 0.1,
    pointerEvents: "none",
    height: 90,
    borderTopLeftRadius: "6px",
    borderTopRightRadius: "6px",

    background: "linear-gradient(to bottom, orange, #eee3cc)",
  },
  gridCell: {
    border: "1px solid #e47036", // Light gray border for the grid
  },
  content: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    zIndex: 1,
  },
  logo: {
    width: "32px",
    height: "16px",
    backgroundColor: "#2563eb", // Blue color for the semi-circle
    borderTopLeftRadius: "16px",
    borderTopRightRadius: "16px",
  },
  title: {
    marginTop: "8px",
    fontSize: "20px",
    fontWeight: "600",
    color: "#111827", // Dark text color
  },
};
