import React, { useRef } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { Country, State, City } from "country-state-city";
import { useNavigate } from "react-router-dom";
import { selectToken } from "../../utility/Redux/security";
import { selectPrefix, selectWidth } from "../../utility/Redux/profile";
import {
  setAlertWithTitle,
  setSessionExpired,
  setSpinnerLoading,
} from "../../utility/Redux/modal";
import ModalHeaderStyle from "../../utility/component/styles/modalHeader";
import {
  ModifyButton,
  SaveButton,
  UndoButton,
} from "../../utility/component/buttons";
import BasicInformation from "./basicInformation";
import AddressInformation from "./addressInformation";
import PartyList from "./partyList";
import AccountMasterHelp from "../../help/listAccountMasterHelp";
import { PartyColumns } from "./columns";
import { CallAPIPromise } from "../../billing/common";

const Party_Master = ({ hidePartyList, onClose, afterCreate }) => {
  const headerRef = useRef();
  const [id, setId] = useState("");
  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const [partyMasterHelpStatus, setPartyMasterhelpStatus] = useState(false);
  const [stationName, setStationName] = useState("");
  const prefix = useSelector(selectPrefix);
  const [tabScreen, setTabScreen] = useState(1);
  const [partyName, setPartyName] = useState("");
  const [accountNo, setAccountNo] = useState("");
  const [gstNo, setGstNo] = useState("");
  const [panNo, setPanNo] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [website, setWebsite] = useState("");
  const [creditAllow, setCreditAllow] = useState("");
  const [creditPeriod, setCreditPeriod] = useState("");
  const [stationId, setStationId] = useState("");
  const [latitude, setlatitude] = useState("123");
  const [longitude, setLongitude] = useState("123");
  const [address, setAddress] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [city, setCity] = useState("");
  const [opening, setOpening] = useState("");
  const [openingType, setOpeningType] = useState("");
  const [currencySymbol, setCurrencySymbol] = useState("");
  const [currencyName, setCurrencyName] = useState("");
  const [fullList, setFullList] = useState([]);
  const [parties, setParties] = useState([]);
  const [stop, setStop] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [stateList, setStatelist] = useState([]);
  const [cityList, setCitylist] = useState([]);
  const [missingId, setMissingId] = useState("partyName");
  const [addressMissingId, setAddressMissingId] = useState("address");
  const handleStateChange = (e) => {
    setSelectedState(e);
  };

  const countries = Country.getAllCountries();

  const [selectedCountry, setSelectedCountry] = useState("");

  const handleCountryChange = (e) => {
    setSelectedCountry(e);
  };

  useEffect(() => {
    if (selectedCountry) {
      const sCountry = countries.filter(
        (country) => country.name === selectedCountry
      );
      if (sCountry[0]) {
        setStatelist(State.getStatesOfCountry(sCountry[0].isoCode));
      }
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (selectedState && stateList[0]) {
      const sState = stateList.filter((state) => state.name === selectedState);
      if (sState[0]) {
        setCitylist(
          City.getCitiesOfState(sState[0].countryCode, sState[0].isoCode)
        );
      }
    }
  }, [selectedState, stateList]);

  const phoneRegex = /^[6-9]\d{9}$/;

  const savePartyMasterApi = () => {
    if (partyName && mobile && phoneRegex.test(mobile) && address) {
      const data = {
        table_prefix: prefix,
        ACCOUNT_NO: id ? id : "",
        NAME: partyName,
        TAX_NUMBER: gstNo,
        PAN: panNo,
        ADDRESS: address,
        COUNTRY: selectedCountry,
        CURRENCY_SYMBOL: currencySymbol,
        CURRENCY_NAME: currencyName,
        STATE: selectedState,
        CITY: city,
        PIN_CODE: pinCode,
        PHONE: phoneNo,
        MOBILE: mobile,
        EMAIL: email,
        WEBSITE: website,

        CREDIT_LIMIT_PERIOD: creditPeriod ? Number(creditPeriod) : 0,
        CREDIT_LIMIT_ALLOWED: Number(creditAllow),
        LATITUDE: latitude,
        LONGITITUDE: longitude,
      };

      {
        id
          ? dispatch(setSpinnerLoading("Updating"))
          : dispatch(setSpinnerLoading("Saving"));
      }

      CallAPIPromise(`/api/master/saveAccountMaster`, data, token, dispatch)
        .then(async function (response) {
          if (!response.data.error) {
            if (afterCreate) {
              afterCreate();
            }
            id
              ? dispatch(
                  setAlertWithTitle({
                    title: "Success.",
                    msg: "Data Updated successfully.",
                    lottie: "success",
                  })
                )
              : dispatch(
                  setAlertWithTitle({
                    title: "Success.",
                    msg: "Data Saved successfully.",
                    lottie: "success",
                  })
                );
            setFullList([]);
          } else {
            dispatch(
              setAlertWithTitle({
                title: "Error 112",
                msg: response.data.error,
              })
            );
          }
        })
        .catch(function (error) {
          if (error.response.data.name === "TokenExpiredError") {
            dispatch(setSessionExpired(true));
          } else {
            dispatch(
              setAlertWithTitle({
                title: "ERROR",
                msg: "Something went wrong.",
                lottie: "reject",
              })
            );
          }
        })
        .finally(() => {
          dispatch(setSpinnerLoading(false));
          clearState();
        });
    } else {
      if (!partyName) {
        setTabScreen(1);
        setMissingId("partyName");

        dispatch(
          setAlertWithTitle({
            title: "Alert",
            msg: "Please Enter Party Name.",
          })
        );
      } else {
        if (!mobile || !phoneRegex.test(mobile)) {
          setTabScreen(1);
          setMissingId("mobile");

          dispatch(
            setAlertWithTitle({
              title: "Alert",
              msg: !mobile
                ? "Please Enter Mobile."
                : "Please Enter valid Mobile.",
            })
          );
        } else {
          if (!address) {
            setTabScreen(2);
            setAddressMissingId("address");

            dispatch(
              setAlertWithTitle({
                title: "Alert",
                msg: "Please Enter Address.",
              })
            );
          }
        }
      }
    }
  };

  const clearState = () => {
    setId("");
    setPartyName("");
    setGstNo("");
    setPanNo("");
    setPhoneNo("");
    setMobile("");
    setEmail("");
    setWebsite("");
    setCreditAllow("");
    setCreditPeriod("");
    setStationId("");
    setlatitude("");
    setLongitude("");
    setAddress("");
    setSelectedCountry("");
    setSelectedState("");
    setOpening("");
    setOpeningType("");
    setCity("");
    setPinCode("");
    setCurrencySymbol("");
    setCurrencyName("");
    setAccountNo("");
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.altKey && e.key === "s") {
        e.preventDefault();
        savePartyMasterApi();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [
    id,
    partyName,
    gstNo,
    panNo,
    phoneNo,
    mobile,
    email,
    website,
    creditAllow,
    creditPeriod,
    opening,
    openingType,
    stationId,
    address,
    city,
    selectedCountry,
    selectedState,
    stop,
    accountNo,
  ]);

  const width = useSelector(selectWidth);
  const navigate = useNavigate();

  const handleClose = () => {
    navigate("/masters");
  };

  const coloumns = PartyColumns(width);
  const partyRef = useRef(null);
  const addressRef = useRef(null);

  return (
    <div className="w-full h-full  bg-[#f5f5f7]   max-sm:py-2 ">
      <div
        style={{
          paddingTop: !hidePartyList ? "16px" : "0px",
          paddingBottom: !hidePartyList ? "16px" : "0px",
        }}
        className="flex justify-center  h-full "
      >
        <div
          style={{ borderRadius: "12px", height: "100%" }}
          className=" flex items-center h-full overflow-y-auto"
        >
          <div className="h-full flex flex-col">
            <div ref={headerRef}>
              <ModalHeaderStyle
                header={"Party Master"}
                onClose={onClose ? onClose : handleClose}
              />
            </div>
            <div className="flex flex-col  ">
              <div className="h-full bg-white rounded-b-sm">
                <div
                  style={{
                    minHeight: window.innerHeight - 400,
                  }}
                  className="  px-8 py-4 flex flex-col   max-sm:px-2"
                >
                  <div className=" flex flex-col">
                    <div
                      style={{
                        border: "1px solid #b5b3b3",
                      }}
                      className=" w-full  rounded-sm flex"
                    >
                      <div
                        onClick={() => {
                          setTabScreen(1);
                          setMissingId("partyName");
                        }}
                        style={{
                          color: tabScreen === 1 ? "white" : "gray",
                        }}
                        className={`flex-1 p-2 ${
                          tabScreen === 1
                            ? "bg-[#0d0d0d] "
                            : " hover:bg-[#f1f5f9]  bg-[#ebe8e8]  hover:cursor-pointer "
                        }  flex justify-center items-center rounded-l-sm `}
                      >
                        <div
                          style={{
                            fontSize: "16px",
                            fontWeight: tabScreen === 1 ? 600 : 400,
                          }}
                          className=" h-full flex items-center"
                        >
                          Basic Info
                        </div>
                      </div>
                      <div
                        style={{
                          color: tabScreen === 2 ? "white" : "gray",
                        }}
                        onClick={() => {
                          setTabScreen(2);
                        }}
                        className={`flex-1 p-2 ${
                          tabScreen === 2
                            ? "bg-[#0d0d0d] "
                            : " hover:bg-[#f1f5f9] bg-[#ebe8e8]  hover:cursor-pointer "
                        }  flex justify-center items-center  `}
                      >
                        <div
                          style={{
                            fontSize: "16px",
                            fontWeight: tabScreen === 2 ? 600 : 400,
                          }}
                          className="  flex items-center"
                        >
                          Address
                        </div>
                      </div>

                      {!hidePartyList && (
                        <div
                          style={{
                            // backgroundColor: tabScreen === 3 ? '#0d0d0d' : 'white',

                            color: tabScreen === 3 ? "white" : "gray",
                          }}
                          onClick={() => {
                            setTabScreen(3);
                          }}
                          className={`flex-1 p-2 ${
                            tabScreen === 3
                              ? "bg-[#0d0d0d] "
                              : " hover:bg-[#f1f5f9] bg-[#ebe8e8] hover:cursor-pointer "
                          }  flex justify-center items-center rounded-r-sm `}
                        >
                          <div
                            style={{
                              fontWeight: tabScreen === 3 ? 600 : 400,

                              fontSize: "16px",
                            }}
                            className="  flex items-center"
                          >
                            Party List
                          </div>
                        </div>
                      )}
                    </div>

                    <div className=" flex flex-col ll:h-[400px] min-h-[320px]">
                      <div className="h-full">
                        {tabScreen === 1 && (
                          <BasicInformation
                            partyRefs={partyRef}
                            partyName={partyName}
                            setPartyName={setPartyName}
                            accountNo={accountNo}
                            setAccountNo={setAccountNo}
                            gstNo={gstNo}
                            missingId={missingId}
                            setGstNo={setGstNo}
                            panNo={panNo}
                            setPanNo={setPanNo}
                            phoneNo={phoneNo}
                            setPhoneNo={setPhoneNo}
                            mobile={mobile}
                            setMissingId={setMissingId}
                            setMobile={setMobile}
                            email={email}
                            setEmail={setEmail}
                            website={website}
                            setWebsite={setWebsite}
                            creditAllow={creditAllow}
                            creditPeriod={creditPeriod}
                            setCreditAllow={setCreditAllow}
                            setCreditPeriod={setCreditPeriod}
                            stationId={stationId}
                            opening={opening}
                            setOpening={setOpening}
                            openingType={openingType}
                            setOpeningType={setOpeningType}
                            setTabScreen={setTabScreen}
                            setPartyMasterhelpStatus={setPartyMasterhelpStatus}
                          />
                        )}
                        {tabScreen === 2 && (
                          <AddressInformation
                            address={address}
                            setSelectedCountry={setSelectedCountry}
                            addressMissingId={addressMissingId}
                            setAddressMissingId={setAddressMissingId}
                            addressRef={addressRef}
                            setAddress={setAddress}
                            selectedState={selectedState}
                            setSelectedState={setSelectedState}
                            stateList={stateList}
                            setStatelist={setStatelist}
                            cityList={cityList}
                            city={city}
                            setCity={setCity}
                            setCitylist={setCitylist}
                            handleCountryChange={handleCountryChange}
                            countries={countries}
                            pinCode={pinCode}
                            setPinCode={setPinCode}
                            handleStateChange={handleStateChange}
                            selectedCountry={selectedCountry}
                            stop={stop}
                            setStop={setStop}
                            currencyName={currencyName}
                            currencySymbol={currencySymbol}
                            setCurrencyName={setCurrencyName}
                            setCurrencySymbol={setCurrencySymbol}
                            setTabScreen={setTabScreen}
                          />
                        )}
                        {tabScreen === 3 && (
                          <PartyList
                            fullList={fullList}
                            setFullList={setFullList}
                            parties={parties}
                            setParties={setParties}
                            stationId={stationId}
                            stationName={stationName}
                            setStationId={setStationId}
                            setStationName={setStationName}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    borderTop: "1px solid #D2D2D7",
                  }}
                  className="flex justify-end bg-white py-3 rounded-b-sm  px-3 "
                >
                  <div className="flex justify-end items-center max-sm:justify-between">
                    <div className="mr-1 ">
                      {id ? (
                        <ModifyButton onClick={savePartyMasterApi} />
                      ) : (
                        <SaveButton onClick={savePartyMasterApi} />
                      )}
                    </div>
                    <UndoButton onClick={clearState} />
                  </div>

                  <AccountMasterHelp
                    status={partyMasterHelpStatus}
                    onRowClick={(p) => {
                      setPartyName(p.row.NAME);
                      setAccountNo(p.row.ACCOUNT_NO);
                      setGstNo(p.row.TAX_NUMBER);
                      setMobile(p.row.MOBILE);
                      setEmail(p.row.EMAIL);
                      setWebsite(p.row.WEBSITE);
                      setCreditAllow(p.row.CREDIT_LIMIT_ALLOWED);
                      setCreditPeriod(p.row.CREDIT_LIMIT_PERIOD);
                      setStationId(p.row.SID);
                      setlatitude(p.row.LATITUDE);
                      setLongitude(p.row.LONGITUDE);
                      setAddress(p.row.ADDRESS);
                      setCity(p.row.CITY);
                      setSelectedState(p.row.STATE);
                      setSelectedCountry(p.row.COUNTRY);
                      setPinCode(p.row.PIN_CODE);
                      setId(p.row.ACCOUNT_NO);
                      setOpeningType(p.row.OPENING_BALANCE_TYPE);
                      setOpening(p.row.OPENING_BALANCE);
                      setPartyMasterhelpStatus(false);
                      const panRegex = /[A-Z]{5}[0-9]{4}[A-Z]{1}/;
                      const match = p.row.TAX_NUMBER.match(panRegex);

                      if (match) {
                        setPanNo(match[0]);
                      }
                    }}
                    onClose={() => {
                      setPartyMasterhelpStatus(false);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Party_Master;
