import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectToken } from "../utility/Redux/security";
import {
  selectEmail,
  selectPrefix,
  selectUserType,
  setdefaultProfileSetting,
} from "../utility/Redux/profile";
import { setAlertWithTitle, setSpinnerLoading } from "../utility/Redux/modal";
import CallAPI from "../utility/functions/getData";
import GeniustextInput from "../utility/component/mastertext";
import { SaveButton, UndoButton } from "../utility/component/buttons";
import AlertModalTwoButton from "../utility/component/modals/alertWithTwoButton";
import ModalHeaderStyle from "../utility/component/styles/modalHeader";
import { useNavigate } from "react-router-dom";
import ModUserHelp from "../help/userhelp";
import CompanyHelp from "../help/companyHelp";
const saleOption = [
  {
    name: "Inclusive",
    value: "Inclusive",
  },
  {
    name: "Exclusive",
    value: "Exclusive",
  },
];
export const emptyUserSetting = {
  CODE: 0,
  SALE_TYPE: saleOption[0].value,
  SEND_BILL_BY_WHATSAPP: "NO",
  SEND_BILL_BY_SMS: "No",
  CHECK_STOCK: "No",
  PRINT_YOU_SAVED: "No",
  MODIFY_SALE_BILL: "No",
  MODIFY_PURCHASE_BILL: "No",
  LOGIN_OTP: "No",
  VALID_FOR_DAYS: 1,
  OFFER_SALE_PRICE: "No",
  PERSONAL_NO_WHATSAPP: "No",
  PRINT_BAR_CODE_IN_BILL: "No",
  PRINT_PAYMENT_QR_IN_BILL: "No",
};
const UserDefaultSetting = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [userhelpStatus, setuserhelpStatus] = useState(false);
  const [companyModaStatus, setCompanyModalStatus] = useState(false);
  const [defaultSettingEmail, setDefaultSettingEmail] = useState("");
  const [companyDetails, setcompanyDetails] = useState("");
  const orginalUserType = useSelector(selectUserType);

  const [userSetting, setUserSetting] = useState(emptyUserSetting);

  const [alertTwoButtonModal, setAlertTwoButtonModal] = useState({
    title: "",
    msg: "",
    status: false,
    setStatus: "",
    button1: "",
    button1Click: "",
    button2: "",
    button2Click: "",
  });
  const token = useSelector(selectToken);
  const dispatch = useDispatch();
  const prefix = useSelector(selectPrefix);
  const userEmail = useSelector(selectEmail);
  const saveSystemDefaultSetting = () => {
    dispatch(setSpinnerLoading("Saving"));
    const data = {
      table_prefix_master: companyDetails
        ? companyDetails.table_prefix.replace(/_.+_/, "_")
        : prefix.replace(/_.+_/, "_"),
      ...userSetting,
    };

    CallAPI(
      "/api/userSettings/changeUserDefaultSetting",
      data,
      token,
      (res) => {
        if (!res.data.error) {
          if (userEmail === defaultSettingEmail) {
            dispatch(setdefaultProfileSetting(data));
          }
          dispatch(setSpinnerLoading("Saved Successfully"));
          dispatch(
            setAlertWithTitle({
              title: "Data saved successfully",
              msg: "Please log in again to apply these changes to your account",
              lottie: "success",
            })
          );
        } else {
          dispatch(
            setAlertWithTitle({
              title: "Error",
              msg: "Something went wrong",
            })
          );
        }
      },
      dispatch,
      () => {
        dispatch(setSpinnerLoading(false));
        back();
      }
    );
  };

  const back = () => {
    setUserSetting(emptyUserSetting);

    setDefaultSettingEmail("");
  };

  const getUserDefaultSetting = (code) => {
    let re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const data = {
      code: code,
      table_prefix: prefix.replace(/_.+_/, "_"),
    };
    CallAPI(
      "/api/userSettings/userDefaultSetting",
      data,
      token,
      (res) => {
        if (!res.data.error) {
          if (res.data.response) {
            setUserSetting(
              res.data.response[0]
                ? res.data.response[0]
                : {
                    ...emptyUserSetting,
                    CODE: code,
                  }
            );
          } else {
            dispatch(
              setAlertWithTitle({
                title: "No data",
                msg: "No data found",
              })
            );
          }
        }
      },
      dispatch
    );
  };
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const changeInput = (id, value) => {
    setUserSetting((prev) => {
      return {
        ...prev,
        [id]: value,
      };
    });
  };
  const formRef = useRef(null);
  return (
    <div
      ref={formRef}
      className="w-full  flex justify-center bg-[#f5f5f7] h-full py-3"
    >
      <div className="text-sm min-w-[340px]  rounded-sm   flex flex-col   h-full px-3 ">
        <div>
          <ModalHeaderStyle
            header={"User Default Settings"}
            onClose={() => {
              navigate("/settings");
            }}
          />
        </div>

        <div className="grid grid-cols-1 t:grid-cols-1 gap-2 rounded-b-sm  bg-white border-brown px-4 max-sm:px-2">
          {orginalUserType === "ADMIN" && (
            <div className="flex-1 mt-4">
              <GeniustextInput
                title={"Company Email"}
                grid={true}
                width={windowWidth > 764 ? "250px" : "150px"}
                type={"text"}
                search={() => setCompanyModalStatus(true)}
                value={companyDetails?.company_email}
              />
            </div>
          )}
          <div className="flex-1 ">
            <GeniustextInput
              title={"User Email"}
              grid={true}
              width={windowWidth > 764 ? "250px" : "150px"}
              type={"text"}
              search={() => setuserhelpStatus(true)}
              value={defaultSettingEmail}
            />
          </div>
          {orginalUserType === "ADMIN" && (
            <GeniustextInput
              grid={true}
              option={saleOption}
              value={userSetting.SALE_TYPE}
              onChange={(e) => {
                changeInput("SALE_TYPE", e.target.value);
              }}
              width={windowWidth > 764 ? "250px" : "150px"}
              title={"Sale Type"}
            />
          )}
          {orginalUserType === "MANAGER" && (
            <GeniustextInput
              grid={true}
              type={"number"}
              value={userSetting.VALID_FOR_DAYS}
              onChange={(e) => {
                changeInput("VALID_FOR_DAYS", e.target.value);
              }}
              width={windowWidth > 764 ? "250px" : "150px"}
              title={"OTP Valid Days"}
            />
          )}
          Permissions
          <div className=" w-full border p-2  ">
            <div className="grid grid-cols-1 t:grid-cols-2 gap-2 w-full mt-1 ">
              <div className="flex items-center">
                <input
                  type="checkbox"
                  checked={userSetting.SEND_BILL_BY_WHATSAPP === "Yes"}
                  onChange={(e) => {
                    changeInput(
                      "SEND_BILL_BY_WHATSAPP",
                      e.target.checked ? "Yes" : "No"
                    );
                  }}
                />
                <div
                  data-tooltip-place="bottom"
                  data-tooltip-id="tip4"
                  data-tooltip-offset={25}
                  data-tooltip-content={
                    "By checking the user can send bill copy in whatsapp"
                  }
                  className="ml-1 text-[15px] font-semibold"
                >
                  Send Bill by WhatsApp
                </div>
              </div>
              <div className="flex items-center ">
                <input
                  type="checkbox"
                  checked={userSetting.SEND_BILL_BY_SMS === "Yes"}
                  onChange={(e) => {
                    changeInput(
                      "SEND_BILL_BY_SMS",
                      e.target.checked ? "Yes" : "No"
                    );
                  }}
                />
                <div
                  data-tooltip-place="bottom"
                  data-tooltip-id="tip4"
                  data-tooltip-offset={25}
                  data-tooltip-content={"By check user can send bill sms"}
                  className="ml-1 text-[15px] font-semibold"
                >
                  Send Bill by SMS
                </div>
              </div>
            </div>
            <div className="flex w-full  mt-2">
              <div className="grid grid-cols-1 t:grid-cols-2 gap-2 w-full mt-1 ">
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    checked={userSetting.CHECK_STOCK === "Yes"}
                    onChange={(e) => {
                      changeInput(
                        "CHECK_STOCK",
                        e.target.checked ? "Yes" : "No"
                      );
                    }}
                  />
                  <div
                    data-tooltip-place="bottom"
                    data-tooltip-id="tip4"
                    data-tooltip-offset={25}
                    data-tooltip-content={
                      "By checking, the user can bill only if the item is in stock."
                    }
                    className="ml-1 text-[15px] font-semibold"
                  >
                    Check Stock
                  </div>
                </div>

                <div className="flex items-center ">
                  <input
                    type="checkbox"
                    checked={userSetting.PRINT_YOU_SAVED === "Yes"}
                    onChange={(e) => {
                      changeInput(
                        "PRINT_YOU_SAVED",
                        e.target.checked ? "Yes" : "No"
                      );
                    }}
                  />
                  <div
                    data-tooltip-place="bottom"
                    data-tooltip-id="tip4"
                    data-tooltip-offset={25}
                    data-tooltip-content={
                      "By checking, the user will see save amount bill."
                    }
                    className="ml-1 text-[15px] font-semibold"
                  >
                    Show Saved Amount in bill
                  </div>
                </div>
              </div>
            </div>
            <div className="flex w-full  mt-2">
              <div className="grid grid-cols-1 t:grid-cols-2 gap-2 w-full mt-1 ">
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    checked={userSetting.MODIFY_SALE_BILL === "Yes"}
                    onChange={(e) => {
                      changeInput(
                        "MODIFY_SALE_BILL",
                        e.target.checked ? "Yes" : "No"
                      );
                    }}
                  />
                  <div
                    data-tooltip-place="bottom"
                    data-tooltip-id="tip4"
                    data-tooltip-offset={25}
                    data-tooltip-content={
                      "By checking, the user can modify the bill."
                    }
                    className="ml-1 text-[15px] font-semibold"
                  >
                    Modify billing
                  </div>
                </div>

                <div className="flex items-center ">
                  <input
                    type="checkbox"
                    checked={userSetting.MODIFY_PURCHASE_BILL === "Yes"}
                    onChange={(e) => {
                      changeInput(
                        "MODIFY_PURCHASE_BILL",
                        e.target.checked ? "Yes" : "No"
                      );
                    }}
                  />
                  <div
                    data-tooltip-place="bottom"
                    data-tooltip-id="tip4"
                    data-tooltip-offset={25}
                    data-tooltip-content={
                      "By checking, the user can modify the purchase."
                    }
                    className="ml-1 text-[15px] font-semibold"
                  >
                    Modify Purchase
                  </div>
                </div>
              </div>
            </div>
            <div className="flex w-full  mt-2">
              <div className="grid grid-cols-1 t:grid-cols-2 gap-2 w-full mt-1 ">
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    checked={userSetting.OFFER_SALE_PRICE === "Yes"}
                    onChange={(e) => {
                      changeInput(
                        "OFFER_SALE_PRICE",
                        e.target.checked ? "Yes" : "No"
                      );
                    }}
                  />
                  <div
                    data-tooltip-place="bottom"
                    data-tooltip-id="tip4"
                    data-tooltip-offset={25}
                    data-tooltip-content={
                      "By checking, the user billing check offer before making bill of item."
                    }
                    className="ml-1 text-[15px] font-semibold"
                  >
                    Check Offer
                  </div>
                </div>
                {orginalUserType === "MANAGER" ||
                orginalUserType === "ADMIN" ? (
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      checked={userSetting.LOGIN_OTP === "Yes"}
                      onChange={(e) => {
                        changeInput(
                          "LOGIN_OTP",
                          e.target.checked ? "Yes" : "No"
                        );
                      }}
                    />
                    <div
                      data-tooltip-place="bottom"
                      data-tooltip-id="tip4"
                      data-tooltip-offset={25}
                      data-tooltip-content={
                        "By checking, the user will recieve otp for login."
                      }
                      className="ml-1 text-[15px] font-semibold"
                    >
                      Login Otp
                    </div>
                  </div>
                ) : null}
                {orginalUserType === "MANAGER" && (
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      checked={userSetting.PRINT_BAR_CODE_IN_BILL === "Yes"}
                      onChange={(e) => {
                        changeInput(
                          "PRINT_BAR_CODE_IN_BILL",
                          e.target.checked ? "Yes" : "No"
                        );
                      }}
                    />
                    <div
                      data-tooltip-place="bottom"
                      data-tooltip-id="tip4"
                      data-tooltip-offset={25}
                      data-tooltip-content={
                        "By checking, the user will see bar code in print."
                      }
                      className="ml-1 text-[15px] font-semibold"
                    >
                      Print bar code in bill
                    </div>
                  </div>
                )}

                {orginalUserType === "MANAGER" && (
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      checked={userSetting.PRINT_PAYMENT_QR_IN_BILL === "Yes"}
                      onChange={(e) => {
                        changeInput(
                          "PRINT_PAYMENT_QR_IN_BILL",
                          e.target.checked ? "Yes" : "No"
                        );
                      }}
                    />
                    <div
                      data-tooltip-place="bottom"
                      data-tooltip-id="tip4"
                      data-tooltip-offset={25}
                      data-tooltip-content={
                        "By checking, the user will see payment QR code in bill."
                      }
                      className="ml-1 text-[15px] font-semibold"
                    >
                      Print Payment Qr bill
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          Service
          <div className="w-full border p-2">
            <div className="flex font-semibold">WhatsApp Type</div>
            <div className="flex  mt-1">
              <div className="flex items-center ml-3">
                <input
                  type="radio"
                  checked={userSetting.PERSONAL_NO_WHATSAPP === "No"}
                  onChange={(e) => {
                    changeInput(
                      "PERSONAL_NO_WHATSAPP",
                      e.target.checked ? "Yes" : "No"
                    );
                  }}
                />
                <div className="ml-1 text-[15px] ">Commercial</div>
              </div>
            </div>
            {userSetting.PERSONAL_NO_WHATSAPP === "Yes" && (
              <div className="ml-4 mt-1">
                <div>Disclaimer</div>
                <div className="max-w-[390px] text-red-700">
                  It is not guaranteed that you will not be
                  <label className="font-semibold text-red-900 mx-1">
                    blocked
                  </label>
                  by using this method. WhatsApp does not allow bots or
                  unofficial clients on their platform, so this method cannot be
                  considered entirely safe. We highly recommend using a
                  commercial method.
                </div>
              </div>
            )}
          </div>
          <div className="flex w-full justify-end  py-3">
            <SaveButton
              onClick={() => {
                if (defaultSettingEmail) {
                  setAlertTwoButtonModal({
                    ...alertTwoButtonModal,
                    status: true,
                    title: "Save",
                    msg: "Are you want to save this Data ?",
                    button1: "Yes",
                    button2: "No",

                    button1Click: () => {
                      setAlertTwoButtonModal({
                        ...alertTwoButtonModal,
                        status: false,
                      });
                      saveSystemDefaultSetting();
                    },
                    button2Click: () => {
                      setAlertTwoButtonModal({
                        ...alertTwoButtonModal,
                        status: false,
                      });
                    },
                  });
                } else {
                  dispatch(
                    setAlertWithTitle({
                      title: "Alert",
                      msg: "Please select user first",
                    })
                  );
                }
              }}
            />
            <UndoButton onClick={back} />
          </div>
          <AlertModalTwoButton
            details={alertTwoButtonModal}
            setAlertTwoButtonModal={setAlertTwoButtonModal}
          />
        </div>
      </div>
      <ModUserHelp
        onRowClick={(p) => {
          setDefaultSettingEmail(p.row.USER_EMAIL);
          setuserhelpStatus(false);
          getUserDefaultSetting(p.row.CODE);
        }}
        status={userhelpStatus}
        comapany={companyDetails}
        onClose={() => {
          setuserhelpStatus(false);
        }}
      />
      <CompanyHelp
        onRowClick={(p) => {
          setcompanyDetails(p.row);
          setCompanyModalStatus(false);
        }}
        status={companyModaStatus}
        onClose={() => {
          setCompanyModalStatus(false);
        }}
      />
    </div>
  );
};

export default UserDefaultSetting;
