import React, { useState } from "react";
import GeniustextInput from "../../utility/component/mastertext";
import { toProperCase } from "../../utility/component/format";
import { useDispatch, useSelector } from "react-redux";
import BarcodeScanner from "../../utility/component/barcodeScanner";
import { selectPrefix, selectWidth } from "../../utility/Redux/profile";
import { DownloadFile } from "../../utility/functions/firebase";
import { getItemByAlios } from "../../utility/constant";
import { selectToken } from "../../utility/Redux/security";
import moment from "moment";
import { FormatNumber2 } from "../../utility/functions/formatNumber";
import ProfilePicFrame from "../../utility/component/styles/profilePicFrame";
const ItemDetails = ({
  data,
  formRef,
  setDepartmentMasterHelpStatus,
  setpath,
  setFirebaseUrl,
  setAlertTwoButtonModal,
  setSubSectionMaterHelpStatus,
  setdata,
  setItemMasterHelpStatus,
  setSectionMaterHelpStatus,
  uploadImgList,
}) => {
  const [barCodeScannerStatus, setbarCodeScannerStatus] = useState(false);
  const width = useSelector(selectWidth);
  const inputWidth = width < 500 ? "240px" : "308px";
  const prefix = useSelector(selectPrefix);
  const token = useSelector(selectToken);
  const dispatch = useDispatch();
  return (
    <>
      <div
        id={"Item Details"}
        className="grid md:grid-cols-[200px,1fr] ll:grid-cols-[400px,1fr]  border-b pb-2 p-3 "
      >
        <div>
          <div className="font-semibold text-[16px] text-black  w-fit ">
            Item Details
          </div>
          <div className="text-[12px] text-gray-600  w-fit ">
            Edit item details
          </div>
          <div className=" justify-center  grid grid-cols-4 gap-2">
            {data.IMAGE_1 || uploadImgList[0] ? (
              <div className="  rounded-sm mt-3  ">
                <ProfilePicFrame
                  customerFirebasePic={
                    data.IMAGE_1 ? data.IMAGE_1 : uploadImgList[0]
                  }
                  readOnly={true}
                />
              </div>
            ) : null}

            {data.IMAGE_2 || uploadImgList[1] ? (
              <div className="  rounded-sm mt-3  ">
                <ProfilePicFrame
                  customerFirebasePic={
                    data.IMAGE_2 ? data.IMAGE_2 : uploadImgList[1]
                  }
                  readOnly={true}
                />
              </div>
            ) : null}
            {data.IMAGE_3 || uploadImgList[2] ? (
              <div className=" rounded-sm mt-3  ">
                <ProfilePicFrame
                  customerFirebasePic={
                    data.IMAGE_3 ? data.IMAGE_3 : uploadImgList[2]
                  }
                  readOnly={true}
                />
              </div>
            ) : null}
            <div></div>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-2 ">
          <GeniustextInput
            max={15}
            autofocus={true}
            value={data.ICODE}
            title={"Item Code"}
            type={"text"}
            readOnly={true}
            grid={true}
          />

          <GeniustextInput
            rightIcon={
              width <= 1280 ? (
                <button
                  className="bg-gray-700 h-full px-2"
                  onClick={() => setbarCodeScannerStatus(true)}
                >
                  <i className="bi text-orange bi-upc-scan"></i>
                </button>
              ) : null
            }
            max={15}
            autofocus={true}
            id={"ALIAS_CODE"}
            value={data.ALIAS_CODE}
            title={"Bar Code"}
            type={"text"}
            onClick={(e) => {
              // Check if the clicked target is an input element
              if (e.target.tagName.toLowerCase() === "input") {
                e.target.select(); // Select the text within the input field
              }
            }}
            onChange={(e) => {
              setdata((p) => {
                return {
                  ...p,
                  ALIAS_CODE: e.target.value,
                };
              });
            }}
            onBlur={async (e) => {
              if (e.target.value) {
                console.log(e.target.value);
                const isDuplicate = await getItemByAlios(
                  prefix,
                  e.target.value.toString(),
                  token,
                  dispatch
                );

                if (!isDuplicate[0] || data.ICODE === isDuplicate[0].ICODE) {
                  setdata((p) => {
                    return {
                      ...p,
                      ALIAS_CODE: e.target.value,
                    };
                  });
                } else {
                  setAlertTwoButtonModal({
                    title: "Alert",
                    msg: "Bar code already exist do you want to edit it",
                    status: true,
                    setStatus: "",
                    button1: "Edit",
                    button1Click: () => {
                      setAlertTwoButtonModal((o) => {
                        return {
                          ...o,
                          status: false,
                        };
                      });
                      const icode = isDuplicate[0].ICODE;
                      const path = `retailx/items/${prefix + icode}`;
                      DownloadFile(path)
                        .then((e) => {
                          if (!e.includes("FirebaseError")) {
                            setFirebaseUrl(e);
                            setpath(path);
                          } else {
                            setpath("");

                            setFirebaseUrl("");
                          }
                        })
                        .catch((e) => {
                          // setCapturedImage('');
                          setFirebaseUrl("");
                        });
                      setdata({
                        table_prefix: prefix,

                        ...isDuplicate[0],
                      });
                    },
                    button2: "Cancel",
                    button2Click: () => {
                      setAlertTwoButtonModal((o) => {
                        return {
                          ...o,
                          status: false,
                        };
                      });
                    },
                  });
                  setdata((p) => {
                    return {
                      ...p,
                      ALIAS_CODE: "",
                    };
                  });
                }
              }
            }}
            grid={true}
          />

          <GeniustextInput
            max={100}
            onKeyDown={(e) => {
              if (e.code === "Enter") {
                if (data.ITEMNAME) {
                }
              }
            }}
            id={"ITEMNAME"}
            grid={true}
            titleBold={500}
            fontBold={500}
            width={inputWidth}
            search={() => {
              setItemMasterHelpStatus(true);
            }}
            title={"Item Name"}
            type={"text"}
            value={data.ITEMNAME}
            onChange={(e) =>
              setdata((p) => {
                return {
                  ...p,
                  ITEMNAME: toProperCase(e.target.value),
                };
              })
            }
          />

          <GeniustextInput
            max={100}
            id={"ITEM_DESC"}
            grid={true}
            width={inputWidth}
            title={"Item Description"}
            type={"text"}
            value={data.ITEM_DESC}
            onChange={(e) =>
              setdata((p) => {
                return {
                  ...p,
                  ITEM_DESC: e.target.value,
                };
              })
            }
          />
        </div>
      </div>

      <div
        id={"Section Details"}
        className="grid md:grid-cols-[200px,1fr] ll:grid-cols-[400px,1fr]   border-b pb-2 p-3"
      >
        <div>
          <div className="font-semibold text-[16px] text-black  w-fit ">
            Section Details
          </div>
          <div className="text-[12px] text-gray-600  w-fit ">
            Modify and update your section information
          </div>
        </div>
        <div className="grid grid-cols-2 gap-2 ">
          <GeniustextInput
            grid={true}
            onKeyDown={(e) => {
              if (e.code === "Enter") {
                const inputFields =
                  formRef.current.querySelectorAll("input, select");
                if (data.SECTION_NAME) {
                  inputFields[5].focus();
                } else {
                  setSectionMaterHelpStatus(true);
                }
              }
            }}
            onClear={() => {
              setdata((o) => {
                return {
                  ...o,
                  SECTION_NAME: "",
                };
              });
            }}
            id={"SECTION_NAME"}
            search={() => {
              setSectionMaterHelpStatus(true);
            }}
            width={inputWidth}
            onChange={() => setSectionMaterHelpStatus(true)}
            title={"Section Name"}
            type={"text"}
            value={data.SECTION_NAME}
          />

          <GeniustextInput
            grid={true}
            onKeyDown={(e) => {
              if (e.code === "Enter") {
                const inputFields =
                  formRef.current.querySelectorAll("input, select");
                if (data.SUB_SEC_NAME) {
                  inputFields[6].focus();
                } else {
                  setSubSectionMaterHelpStatus(true);
                }
              }
            }}
            id={"SUB_SEC_NAME"}
            search={() => {
              setSubSectionMaterHelpStatus(true);
            }}
            width={inputWidth}
            title={"Sub. Section Name"}
            onChange={() => setSubSectionMaterHelpStatus(true)}
            type={"text"}
            value={data.SUB_SEC_NAME}
          />

          <GeniustextInput
            grid={true}
            id={"DEP_NAME"}
            search={() => {
              setDepartmentMasterHelpStatus(true);
            }}
            width={inputWidth}
            onKeyDown={(e) => {
              if (e.code === "Enter") {
                const inputFields =
                  formRef.current.querySelectorAll("input, select");
                if (data.DEP_NAME) {
                  inputFields[7].focus();
                } else {
                  setDepartmentMasterHelpStatus(true);
                }
              }
            }}
            onChange={() => setDepartmentMasterHelpStatus(true)}
            title={"Department Name"}
            type={"text"}
            value={data.DEP_NAME}
          />

          <GeniustextInput
            grid={true}
            id={"BRAND_NAME"}
            width={inputWidth}
            title={"Brand"}
            type={"text"}
            value={data.BRAND_NAME}
            onChange={(e) =>
              setdata((p) => {
                return {
                  ...p,
                  BRAND_NAME: e.target.value,
                };
              })
            }
          />
        </div>
      </div>

      <div
        id={"Unit Details"}
        className="grid md:grid-cols-[200px,1fr] ll:grid-cols-[400px,1fr]  border-b pb-2  p-3"
      >
        <div>
          <div className="font-semibold text-[16px] text-black  w-fit ">
            Unit Details
          </div>
          <div className="text-[12px] text-gray-600  w-fit ">
            Manage and customize your unit information
          </div>
        </div>
        <div>
          <div className="grid grid-cols-4 gap-2">
            <GeniustextInput
              id={"UNIT"}
              grid={true}
              width={`${Number(inputWidth.replace("px", "")) / 2 - 5}px`}
              title={"Unit"}
              option={[
                {
                  value: "KG",
                  name: "KG",
                },
                {
                  value: "GRM",
                  name: "GRM",
                },
                {
                  value: "PCS",
                  name: "PCS",
                },
                {
                  value: "LTR",
                  name: "LTR",
                },
                {
                  value: "NO",
                  name: "NO",
                },
              ]}
              value={data.UNIT}
              onChange={(e) =>
                setdata((p) => {
                  return {
                    ...p,
                    UNIT: e.target.value,
                  };
                })
              }
            />
            <GeniustextInput
              grid={true}
              id={"PACKING"}
              max={20}
              width={`${Number(inputWidth.replace("px", "")) / 2 - 5}px`}
              title={"Packing"}
              type={"text"}
              value={data.PACKING}
              onChange={(e) =>
                setdata((p) => {
                  return {
                    ...p,
                    PACKING: e.target.value,
                  };
                })
              }
            />
            <GeniustextInput
              id={"STOP_YN"}
              grid={true}
              width={`${Number(inputWidth.replace("px", "")) / 2 - 5}px`}
              option={[
                {
                  value: "Yes",
                  name: "Yes",
                },
                {
                  value: "No",
                  name: "No",
                },
              ]}
              title={"Stop Item"}
              value={data.STOP_YN}
              onChange={(e) =>
                setdata((p) => {
                  return {
                    ...p,
                    STOP_YN: e.target.value,
                  };
                })
              }
            />
            <GeniustextInput
              id={"STOCKYN"}
              grid={true}
              width={`${Number(inputWidth.replace("px", "")) / 2 - 5}px`}
              option={[
                {
                  value: "Yes",
                  name: "Yes",
                },
                {
                  value: "No",
                  name: "No",
                },
              ]}
              title={"Stockable Item"}
              value={data.STOCKYN}
              onChange={(e) =>
                setdata((p) => {
                  return {
                    ...p,
                    STOCKYN: e.target.value,
                  };
                })
              }
            />
          </div>
          <div className="grid grid-cols-2 gap-2">
            <GeniustextInput
              width={inputWidth}
              grid={true}
              title={"Trading / IN House Mfg."}
              option={[
                {
                  value: "Purchase",
                  name: "Purchase",
                },
                {
                  value: "IN House Mfg",
                  name: "IN House Mfg",
                },
              ]}
              value={data.NON_MOVING}
              onChange={(e) =>
                setdata((p) => {
                  return {
                    ...p,
                    NON_MOVING: e.target.value,
                  };
                })
              }
            />

            <div className="grid grid-cols-2 gap-2">
              {/* <GeniustextInput
                grid={true}
                width={`${Number(inputWidth.replace("px", "")) / 2 - 5}px`}
                option={[
                  {
                    value: "Yes",
                    name: "Yes",
                  },
                  {
                    value: "No",
                    name: "No",
                  },
                ]}
                title={"Gift Pack"}
                value={data.GIFT_PACK}
                onChange={(e) =>
                  setdata((p) => {
                    return {
                      ...p,
                      GIFT_PACK: e.target.value,
                    };
                  })
                }
              /> */}
              <GeniustextInput
                grid={true}
                width={`${Number(inputWidth.replace("px", "")) / 2 - 5}px`}
                title={"Print Order Slip"}
                option={[
                  {
                    value: "Yes",
                    name: "Yes",
                  },
                  {
                    value: "No",
                    name: "No",
                  },
                ]}
                value={data.PRINT_ORDER_SLIP}
                onChange={(e) =>
                  setdata((p) => {
                    return {
                      ...p,
                      PRINT_ORDER_SLIP: e.target.value,
                    };
                  })
                }
              />
            </div>
          </div>

          <BarcodeScanner
            setStatus={setbarCodeScannerStatus}
            status={barCodeScannerStatus}
            onChange={async (e) => {
              const value = e;
              if (value) {
                const isDuplicate = await getItemByAlios(
                  prefix,
                  value.toString(),
                  token,
                  dispatch
                );

                if (!isDuplicate[0] || data.ICODE === isDuplicate[0].ICODE) {
                  setdata((p) => {
                    return {
                      ...p,
                      ALIAS_CODE: value,
                    };
                  });
                } else {
                  setAlertTwoButtonModal({
                    title: "Alert",
                    msg: "Bar code already exist do you want to edit it",
                    status: true,
                    setStatus: "",
                    button1: "Edit",
                    button1Click: () => {
                      setAlertTwoButtonModal((o) => {
                        return {
                          ...o,
                          status: false,
                        };
                      });
                      const icode = isDuplicate[0].ICODE;
                      const path = `retailx/items/${prefix + icode}`;
                      DownloadFile(path)
                        .then((e) => {
                          if (!e.includes("FirebaseError")) {
                            setFirebaseUrl(e);
                            setpath(path);
                          } else {
                            setpath("");

                            setFirebaseUrl("");
                          }
                        })
                        .catch((e) => {
                          // setCapturedImage('');
                          setFirebaseUrl("");
                        });
                      setdata({
                        table_prefix: prefix,

                        ...isDuplicate[0],
                      });
                    },
                    button2: "Cancel",
                    button2Click: () => {
                      setAlertTwoButtonModal((o) => {
                        return {
                          ...o,
                          status: false,
                        };
                      });
                    },
                  });
                  setdata((p) => {
                    return {
                      ...p,
                      ALIAS_CODE: "",
                    };
                  });
                }
              }
            }}
          />
        </div>
      </div>
    </>
  );
};

export default ItemDetails;
