import CryptoJS from "crypto-js";

const emailencryted = localStorage.getItem("email");
const secretPass = process.env.REACT_APP_SECRET_KEY;

export const getUserEmail = () => {
  if (emailencryted) {
    const bytes = CryptoJS.AES.decrypt(emailencryted, secretPass);
    if (bytes.sigBytes < 0) {
      localStorage.removeItem("prefix");
      localStorage.removeItem("expiresAt");
    } else {
      const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

      var email = data;

      return email;
    }
  }

  return null;
};
