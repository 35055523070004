import React, { useEffect, useRef, useState } from "react";
import { Country, State, City } from "country-state-city";
import { useDispatch, useSelector } from "react-redux";
import AlertModal2 from "../../utility/component/modals/alertWithLottie";
import GeniustextInput from "../../utility/component/mastertext";
import ProfilePicFrame from "../../utility/component/styles/profilePicFrame";
import UploadImageButtons from "../../utility/component/UploadImageButtons";
import ModalHeaderStyle from "../../utility/component/styles/modalHeader";
import CallAPI from "../../utility/functions/getData";
import {
  ModifyButton,
  SaveButton,
  UndoButton,
} from "../../utility/component/buttons";
import {
  setAlertWithTitle,
  setSessionExpired,
  setSpinnerLoading,
} from "../../utility/Redux/modal";
import { useFocus } from "../../utility/hooks/hooks";
import { useNavigate } from "react-router-dom";
import { selectToken } from "../../utility/Redux/security";
import { toProperCase } from "../../utility/component/format";
import CustomerMasterHelp from "../../help/customermasterHelp";
import {
  selectCompanyAddress,
  selectPrefix,
  selectWidth,
} from "../../utility/Redux/profile";
import { DownloadFile, UploadFile } from "../../utility/functions/firebase";
import { CallAPIPromise, emailRegex } from "../../billing/common";
import Lottie from "lottie-react";
import spinner from "../../../src/utility/json/Spinner.json";
import SendOtp from "../../utility/functions/sendOtp";
import { getRandomNumber } from "../../auth/login/func";
import OtpAlertModal from "../../utility/component/modals/otpAlert";
import EmailChangeModal from "./emailChangeModal";
import {
  decryptData,
  encryptData,
} from "../../utility/component/encryptDecrypt";
import MobileChangeModal from "./mobileChangeModal";
const CustomerMaster = () => {
  const [id, setId] = useState("");
  const [randome, setRandome] = useState("");
  const [otpStatus, setOtpStatus] = useState(false);
  const [mobile, setMobile] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [emailVerfied, setEmailVerified] = useState(false);
  const [mobileVerfied, setMobileVerfied] = useState(false);
  const [address, setAddress] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [alert, setAlert] = useState("");
  const [type, setType] = useState("Customer");
  const [status, setStatus] = useState("Active");
  const [prevBal, setPrevBal] = useState("");
  const [balType, setBalType] = useState("Dr.");
  const [customerList, setCustomerList] = useState([]);
  const [emailStatus, setEmailStatus] = useState(false);
  const [mobileStatus, setMobileStatus] = useState(false);
  const [companyData, setCompanyData] = useState({
    COMPANY_NAME: "",
    COMPANY_ADDRESS: "",
    COMPANY_MOBILE: "",
    TAX_NUMBER: "",
    PAN_NO: "",
    TAN: "",
  });
  const [discount, setDiscount] = useState("");
  const [loyalty, setLoyalty] = useState("");
  const [selectedFile, setSelectedFile] = useState("");
  const [webcamStatus, setWebcamStatus] = useState("");
  const [capturedImage, setCapturedImage] = useState("");
  const webcamRef = useRef(null);
  const [selectedState, setSelectedState] = useState("Punjab");
  const [stateList, setStatelist] = useState([]);
  const [cityList, setCitylist] = useState([]);
  const [city, setCity] = useState("Jalandhar");
  const handleStateChange = (e) => {
    setSelectedState(e.target.value);
  };

  const countries = Country.getAllCountries();

  const [selectedCountry, setSelectedCountry] = useState("India");

  const handleCountryChange = (e) => {
    setSelectedCountry(e.target.value);
  };

  useEffect(() => {
    if (selectedCountry) {
      const sCountry = countries.filter(
        (country) => country.name === selectedCountry
      );
      setStatelist(State.getStatesOfCountry(sCountry[0].isoCode));
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (selectedState && stateList[0]) {
      const sState = stateList.filter((state) => state.name === selectedState);

      setCitylist(
        City.getCitiesOfState(sState[0].countryCode, sState[0].isoCode)
      );
    }
  }, [selectedState, stateList]);

  const [customerMasterHelpStatus, setCustomerMasterHelpStatus] =
    useState(false);

  const [mobileRef, setMobileError] = useFocus();
  const [customerNameRef, setCustomerNameError] = useFocus();
  const [emailRef, setEmailError] = useFocus();
  const [addressRef, setAddressError] = useFocus();
  const [pinCodeRef, setPinCodeError] = useFocus();
  const [typeRef, setTypeError] = useFocus();
  const [prevBalRef, setPrevBalError] = useFocus();
  const [discountRef, setDiscountError] = useFocus();
  const [loyaltyRef, setLoayaltyError] = useFocus();
  const [firebaseImage, setFirebaseUrl] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const prefix = useSelector(selectPrefix);
  const token = useSelector(selectToken);
  const [Path, setpath] = useState("");
  const saveCustomerMaster = () => {
    if (customerName && mobile) {
      if (id) {
        saveCustomer(id);
      } else {
        CallAPIPromise(
          "/api/master/listCustomerMaster",
          {
            table_prefix_master: prefix,
            CUSTOMER_MOBILE: mobile,
            CUSTOMER_NAME: "",
          },
          token,
          dispatch
        ).then((res) => {
          if (res.data.response[0]) {
            saveCustomer(res.data.response[0].CUSTOMER_CODE);
          } else {
            saveCustomer("");
          }
        });
      }
    } else {
      if (!companyData.TAX_NUMBER) {
        setMobileError();
        dispatch(
          setAlertWithTitle({
            title: "Alert",
            msg: "Please Enter GST Number.",
          })
        );
      }

      if (!customerName) {
        setCustomerNameError();
        dispatch(
          setAlertWithTitle({
            title: "Alert",
            msg: "Please Enter Customer Name.",
          })
        );
      }
      if (!mobile) {
        setMobileError();
        dispatch(
          setAlertWithTitle({
            title: "Alert",
            msg: "Please Enter Mobile.",
          })
        );
      }
    }
  };
  const saveCustomer = (code) => {
    const data = {
      table_prefix_master: prefix.replace(/_.+_/, "_"),
      CUSTOMER_CODE: code,
      CUSTOMER_NAME: customerName,
      CUSTOMER_ADDRESS: address,
      EMAIL_VERIFIED: emailVerfied ? "Yes" : "No",
      MOBILE_VERIFIED: mobileVerfied ? "Yes" : "No",
      CUSTOMER_MOBILE: mobile,
      CUSTOMER_EMAIL: email,
      CUSTOMER_TYPE: type ? type : "Customer",
      CITY: city,
      COUNTRY: selectedCountry,
      PIN_CODE: pinCode,
      DISQ_RATE: discount ? Number(discount) : 0,
      LOYALTY_POINTS: loyalty ? loyalty : "0",
      CUSTOMER_STATUS: status ? status : "Active",
      PRV_BAL: prevBal ? prevBal : "0",
      BAL_TYPE: balType ? balType : "Dr.",
      COMPANY_NAME: companyData.COMPANY_NAME,
      COMPANY_ADDRESS: companyData.COMPANY_ADDRESS,
      COMPANY_MOBILE: companyData.COMPANY_MOBILE,
      TAX_NUMBER: companyData.TAX_NUMBER,
      PAN_NO: companyData.PAN_NO,
      TAN: companyData.TAN,
    };
    dispatch(setSpinnerLoading("Saving"));
    CallAPI(
      "/api/master/saveCustomerMaster",
      data,
      token,
      (e) => {
        if (!e.data.error) {
          if (capturedImage || selectedFile) {
            const camera = capturedImage
              ? fetch(capturedImage).then((response) => response.blob())
              : "";
            const file = selectedFile ? selectedFile : camera;
            const name =
              data.table_prefix +
              `${
                data.CUSTOMER_CODE ? data.CUSTOMER_CODE : e.data.result.insertId
              }`;
            const path = "retailx/customers/";
            UploadFile(file, path, name);
          }
          dispatch(
            setAlertWithTitle({
              title: "Alert",
              msg: id ? "Data Updated." : "Data Saved Successfully.",
            })
          );
          clearState();
          getData();
        } else {
          dispatch(
            setAlertWithTitle({
              title: "Alert",
              msg: "Something Went Wrong.",
            })
          );
        }
      },
      dispatch,
      () => {
        dispatch(setSpinnerLoading(false));
      }
    );
  };
  const clearState = () => {
    setMobile("");
    setCustomerName("");
    setId("");
    setEmail("");
    setAddress("");
    setSelectedCountry("India");
    setSelectedState("Punjab");
    setCity("Jalandhar");
    setPinCode("");
    setType("");
    setStatus("");
    setPrevBal("");
    setBalType("");
    setDiscount("");
    setLoyalty("");
    setSelectedFile(null);
    setCapturedImage(null);
    setEmailVerified(false);
    setMobileVerfied(false);
    setFirebaseUrl("");
    setCompanyData({
      COMPANY_NAME: "",
      COMPANY_ADDRESS: "",
      COMPANY_MOBILE: "",
      TAX_NUMBER: "",
      PAN_NO: "",
      TAN: "",
    });
  };

  const formRef = useRef(null);

  useEffect(() => {
    const inputFields = formRef.current.querySelectorAll(
      "input, select,textarea"
    );

    inputFields.forEach((field, index) => {
      field.addEventListener("keydown", (event) => {
        if (event.key === "Enter") {
          event.preventDefault(); // Prevent form submission
          const nextIndex = (index + 1) % inputFields.length;
          if (index === 20) {
            inputFields[0].focus();
          } else {
            if (index === 7) {
              inputFields[11].focus();
            } else {
              inputFields[nextIndex].focus();
            }
          }
        }
      });
    });

    return () => {
      inputFields.forEach((field) => {
        field.removeEventListener("keydown", () => {});
      });
    };
  }, []);

  const getData = async () => {
    dispatch(setSpinnerLoading("Loading"));
    const data = {
      table_prefix_master: prefix.replace(/_.+_/, "_"),
      CUSTOMER_MOBILE: "",
      CUSTOMER_NAME: "",
    };
    CallAPIPromise("/api/master/listCustomerMaster", data, token, dispatch)
      .then((p) => {
        setCustomerList(p.data.response);
      })
      .catch((error) => {
        console.log(error);
        if (error.response.data.name === "TokenExpiredError") {
          dispatch(setSessionExpired(true));
          console.log(error.response);
        }
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
      });
  };

  useEffect(() => {
    if (status) {
      getData();
    }
  }, [status]);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    function handleKeyPress(event) {
      // Check if Ctrl or Alt key is pressed along with 'S'
      if ((event.ctrlKey || event.altKey) && event.key === "s") {
        // Call your function here
        saveCustomerMaster();
      }
    }

    // Add event listener when component mounts
    window.addEventListener("keydown", handleKeyPress);

    // Remove event listener when component unmounts
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [
    customerName,
    prefix,
    email,
    address,
    mobile,
    selectedCountry,
    city,
    pinCode,
    type,
    status,
    prevBal,
    discount,
    balType,
    loyalty,
    companyData.COMPANY_NAME,
    companyData.COMPANY_ADDRESS,
    companyData.COMPANY_MOBILE,
    companyData.TAX_NUMBER,
    companyData.PAN_NO,
    companyData.TAN,
  ]);
  const windowWidth = useSelector(selectWidth);

  const VerfiyEmailSentAPI = (newEmail) => {
    return new Promise((resolve, reject) => {
      CallAPIPromise(
        "/api/master/resendVerifyEmail",
        {
          table_prefix: prefix,
          customerName: customerName,
          code: id,
          to: newEmail ? newEmail : email,
        },
        token,
        dispatch
      )
        .then((t) => resolve(t))
        .catch((e) => {
          reject(e);
        });
    });
  };
  const VerfiyMobileAPI = (newMobile) => {
    return new Promise((resolve, reject) => {
      CallAPIPromise(
        "/api/master/updateVerifyCustomerMobile",
        {
          table_prefix: prefix,
          CUSTOMER_CODE: id,
          MOBILE_VERIFIED: "Yes",
          CUSTOMER_MOBILE: newMobile ? newMobile : mobile,
        },
        token,
        dispatch
      )
        .then((t) => resolve(t))
        .catch((e) => {
          reject(e);
        });
    });
  };
  const OtpSentAPI = (newMobile) => {
    return new Promise((resolve, reject) => {
      const randomeNumber = getRandomNumber();
      setRandome(randomeNumber);
      SendOtp(
        newMobile ? newMobile : mobile,
        prefix.replace(/_.+_/, "_"),
        randomeNumber,
        email,
        dispatch,
        token,
        "Customer Verify",
        "otp"
      )
        .then((e) => {
          resolve(e);
        })
        .catch((e) => reject(e));
    });
  };

  return (
    <div
      ref={formRef}
      className="w-full  flex justify-center bg-[#f5f5f7] h-full  px-3"
    >
      <div className="text-sm   rounded-sm   flex flex-col   h-full py-4 ">
        <div>
          <ModalHeaderStyle
            header={"Customer Master"}
            onClose={() => {
              navigate("/masters");
            }}
          />
        </div>
        <div
          style={{
            height: `90%`,
          }}
          className="   rounded-b-sm  bg-[#fdfffe] flex flex-col   p-1 "
        >
          <div className="h-[16px] text-red-500 pl-6">{alert}</div>
          <div className=" h-full overflow-y-auto overflow-x-hidden   text-black   flex-col l:flex l:flex-row px-4 pb-4">
            <div className="h-fit ">
              <div className="border max-w-[400px] border-[#b5b3b3] min-w-[280px] bg-white rounded-sm p-4 mt-2 grid grid-cols-1 ">
                <GeniustextInput
                  search={() => {
                    setCustomerMasterHelpStatus(true);
                  }}
                  refrance={mobileRef}
                  title={"Mobile"}
                  type={"number"}
                  error={"mobile"}
                  width={"auto"}
                  readOnly={id ? true : false}
                  grid={true}
                  titleButton={
                    <div className="ml-1 text-red-500 text-[14px]">
                      {mobileVerfied && id && mobile.length > 9 ? (
                        <button
                          onClick={() => {
                            setMobileStatus(true);
                          }}
                        >
                          {" "}
                          Change Mobile
                        </button>
                      ) : null}
                    </div>
                  }
                  inputButton={
                    !mobileVerfied && id && mobile.length > 9 ? (
                      <button
                        onClick={() => {
                          if (loading !== "mobile") {
                            setLoading("mobile");
                            OtpSentAPI()
                              .then((e) => {
                                setOtpStatus(true);
                                setMobileVerfied(true);
                              })
                              .catch((e) => console.log(e))
                              .finally(() => {
                                setLoading(false);
                              });
                          }
                        }}
                        className=" px-3  h-full text-sm bg-red-500 text-white flex items-center"
                      >
                        {loading === "mobile" ? (
                          <div className="w-6 h-6">
                            <Lottie
                              animationData={spinner}
                              loop={true}
                              style={{ fontSize: 15 }}
                              color="white"
                            />
                          </div>
                        ) : (
                          "Verify"
                        )}
                      </button>
                    ) : mobileVerfied && id && mobile.length > 9 ? (
                      <button className=" px-2  h-full text-sm bg-[#41ae39] text-white   flex items-center">
                        <i className="bi bi-patch-check text-[20px]"></i>
                      </button>
                    ) : null
                  }
                  value={mobile}
                  onChange={(t) => {
                    if (t.target.value.length < 16) {
                      const isDuplicate = customerList.filter(
                        (c) => c.CUSTOMER_MOBILE === t.target.value
                      );
                      if (!isDuplicate[0]) {
                        setMobile(t.target.value);
                      } else {
                        dispatch(
                          setAlertWithTitle({
                            title: "Alert",
                            msg: "Mobile already exist",
                          })
                        );
                      }
                    } else {
                      dispatch(
                        setAlertWithTitle({
                          title: "Alert",
                          msg: "Please enter a valid  mobile number.",
                        })
                      );
                    }
                  }}
                />

                <GeniustextInput
                  title={"Customer Name"}
                  type={"text"}
                  refrance={customerNameRef}
                  value={customerName}
                  width="auto"
                  grid={true}
                  onChange={(e) =>
                    setCustomerName(toProperCase(e.target.value))
                  }
                />
                <GeniustextInput
                  title={"Email"}
                  type={"text"}
                  error={"email"}
                  grid={true}
                  titleButton={
                    <div className="ml-1 text-red-500 text-[14px]">
                      {emailVerfied && id && emailRegex.test(email) ? (
                        <button
                          onClick={() => {
                            setEmailStatus(true);
                          }}
                        >
                          {" "}
                          Change Email
                        </button>
                      ) : null}
                    </div>
                  }
                  readOnly={id ? true : false}
                  refrance={emailRef}
                  value={email}
                  inputButton={
                    !emailVerfied && id && emailRegex.test(email) ? (
                      <button
                        onClick={() => {
                          if (loading !== "email") {
                            setLoading("email");
                            VerfiyEmailSentAPI()
                              .then((e) => {
                                setEmailVerified(true);
                                setAlert("Verification Email Sent");
                                setTimeout(() => {
                                  setAlert("");
                                }, 6000);
                              })
                              .catch((e) => console.log(e))
                              .finally(() => {
                                setLoading(false);
                              });
                          }
                        }}
                        className=" px-3  h-full text-sm bg-red-500 text-white   flex items-center"
                      >
                        {loading === "email" ? (
                          <div className="w-6 h-6">
                            <Lottie
                              animationData={spinner}
                              loop={true}
                              style={{ fontSize: 15 }}
                              color="white"
                            />
                          </div>
                        ) : (
                          "Verify"
                        )}
                      </button>
                    ) : emailVerfied && id && emailRegex.test(email) ? (
                      <button className=" px-2  h-full text-sm bg-[#41ae39] text-white   flex items-center">
                        <i className="bi bi-patch-check text-[20px]"></i>
                      </button>
                    ) : null
                  }
                  onChange={(t) => {
                    const isDuplicate = customerList.filter(
                      (c) =>
                        c.CUSTOMER_EMAIL === t.target.value && c.CUSTOMER_EMAIL
                    );
                    if (!isDuplicate[0]) {
                      setEmail(t.target.value);
                    } else {
                      dispatch(
                        setAlertWithTitle({
                          title: "Alert",
                          msg: "Email already exist",
                        })
                      );
                    }
                  }}
                />

                <GeniustextInput
                  title={"Address"}
                  type={"text"}
                  grid={true}
                  width="auto"
                  value={address}
                  refrance={addressRef}
                  onChange={(e) => setAddress(toProperCase(e.target.value))}
                />
                <div className="grid grid-cols-1 t:grid-cols-2 gap-2 ">
                  <GeniustextInput
                    title={"Country"}
                    width="auto"
                    grid={true}
                    country={true}
                    option={countries}
                    value={selectedCountry}
                    onChange={handleCountryChange}
                  />

                  <GeniustextInput
                    title={"State"}
                    country={true}
                    width="auto"
                    option={stateList}
                    value={selectedState}
                    onChange={handleStateChange}
                  />
                </div>

                <div className="grid grid-cols-1 t:grid-cols-2 gap-2">
                  <GeniustextInput
                    title={"City"}
                    grid={true}
                    country={true}
                    width="auto"
                    option={cityList}
                    value={city}
                    onChange={(e) => {
                      setCity(e.target.value);
                    }}
                  />
                  <div className="">
                    <GeniustextInput
                      title={"Pin Code"}
                      type={"text"}
                      refrance={pinCodeRef}
                      value={pinCode}
                      grid={true}
                      width="auto"
                      onChange={(e) => setPinCode(e.target.value)}
                    />
                  </div>
                </div>

                <div className="grid grid-cols-2 gap-2">
                  <GeniustextInput
                    title={"Type"}
                    option={[
                      {
                        name: "Customer",
                        value: "Customer",
                      },
                      {
                        name: "NC Guest",
                        value: "NC Guest",
                      },
                    ]}
                    width="auto"
                    grid="true"
                    value={type}
                    refrance={typeRef}
                    onChange={(e) => setType(e.target.value)}
                  />
                  <div className="">
                    <GeniustextInput
                      title={"Status"}
                      value={status}
                      option={[
                        {
                          name: "Active",
                          value: "Active",
                        },
                        {
                          name: "Inactive",
                          value: "Inactive",
                        },
                      ]}
                      grid="true"
                      width="auto"
                      onChange={(e) => setStatus(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="h-fit max-w-[400px] ">
              <div className="mt-2 l:ml-4">
                <div className="border bg-white grid grid-cols-1 min-w-[280px] border-[#b5b3b3] rounded-sm p-4">
                  <div className=" ml-2 ">
                    <div
                      style={{
                        border: "1px solid #b5b3b3",
                        width: "180px",
                        height: "180px",
                      }}
                      className=" p-2 rounded-sm"
                    >
                      <ProfilePicFrame
                        customerFirebasePic={firebaseImage}
                        selectedFile={selectedFile}
                        setSelectedFile={setSelectedFile}
                        webcamStatus={webcamStatus}
                        setWebcamStatus={setWebcamStatus}
                        capturedImage={capturedImage}
                        setCapturedImage={setCapturedImage}
                        webcamRef={webcamRef}
                        path={Path}
                        setFirebaseUrl={setFirebaseUrl}
                      />
                    </div>
                    <div
                      className="flex mt-2"
                      style={{
                        width: "180px",
                      }}
                    >
                      <UploadImageButtons
                        title={"Customer Image"}
                        setSelectedFile={setSelectedFile}
                        webcamStatus={webcamStatus}
                        setWebcamStatus={setWebcamStatus}
                      />
                    </div>
                  </div>

                  <div className="grid  grid-cols-1 l:grid-cols-2 gap-2 mt-3">
                    <GeniustextInput
                      title={"Prev. Balance"}
                      type={"text"}
                      value={prevBal}
                      refrance={prevBalRef}
                      grid={true}
                      onChange={(e) => setPrevBal(e.target.value)}
                    />
                    <GeniustextInput
                      title={"Balance Type"}
                      value={balType}
                      option={[
                        {
                          name: "Dr.",
                          value: "Dr.",
                        },
                        {
                          name: "Cr.",
                          value: "Cr.",
                        },
                      ]}
                      grid={true}
                      onChange={(e) => setBalType(e.target.value)}
                    />
                  </div>

                  <GeniustextInput
                    title={"Discount %"}
                    type={"number"}
                    value={discount}
                    refrance={discountRef}
                    grid={true}
                    onChange={(e) => setDiscount(e.target.value)}
                  />
                  <GeniustextInput
                    title={"Loyalty Point (Per 100 Rs.)"}
                    type={"text"}
                    value={loyalty}
                    refrance={loyaltyRef}
                    grid={true}
                    onChange={(e) => setLoyalty(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="h-fit   l:ml-4 max-w-[400px]">
              <div className="border border-[#b5b3b3] grid grid-cols-1 bg-white rounded-sm p-4 mt-2">
                <GeniustextInput
                  title={"Company Name"}
                  type={"text"}
                  grid={true}
                  value={companyData.COMPANY_NAME}
                  onChange={(t) => {
                    setCompanyData((old) => {
                      return {
                        ...old,
                        COMPANY_NAME: toProperCase(t.target.value),
                      };
                    });
                  }}
                />

                <GeniustextInput
                  title={"Company Mobile"}
                  type={"mobile"}
                  grid={true}
                  value={companyData.COMPANY_MOBILE}
                  onChange={(t) => {
                    setCompanyData((old) => {
                      return {
                        ...old,
                        COMPANY_MOBILE: t.target.value,
                      };
                    });
                  }}
                />

                <GeniustextInput
                  title={"GST Number"}
                  type={"text"}
                  grid={true}
                  value={companyData.TAX_NUMBER}
                  onChange={(t) => {
                    const panRegex = /[A-Z]{5}[0-9]{4}[A-Z]{1}/;
                    const match = t.target.value.match(panRegex);
                    if (match) {
                      setCompanyData((old) => {
                        return {
                          ...old,
                          TAX_NUMBER: t.target.value,
                          PAN_NO: match[0],
                        };
                      });
                    } else {
                      setCompanyData((old) => {
                        return {
                          ...old,
                          TAX_NUMBER: t.target.value,
                        };
                      });
                    }
                  }}
                />

                <GeniustextInput
                  title={"PAN Number"}
                  grid={true}
                  type={"text"}
                  value={companyData.PAN_NO}
                  onChange={(t) => {
                    setCompanyData((old) => {
                      return {
                        ...old,
                        PAN_NO: t.target.value,
                      };
                    });
                  }}
                />

                <GeniustextInput
                  title={"TAN Number"}
                  type={"text"}
                  grid={true}
                  value={companyData.TAN}
                  onChange={(t) => {
                    setCompanyData((old) => {
                      return {
                        ...old,
                        TAN: t.target.value,
                      };
                    });
                  }}
                />

                <GeniustextInput
                  title={"Address"}
                  textArea={true}
                  grid={true}
                  value={companyData.COMPANY_ADDRESS}
                  onChange={(t) => {
                    setCompanyData((old) => {
                      return {
                        ...old,
                        COMPANY_ADDRESS: t.target.value,
                      };
                    });
                  }}
                />
              </div>

              <div className="mt-2 flex justify-end">
                <div>
                  {id ? (
                    <ModifyButton onClick={saveCustomerMaster} />
                  ) : (
                    <SaveButton onClick={saveCustomerMaster} />
                  )}
                </div>

                <div className="ml-1">
                  <UndoButton onClick={clearState} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CustomerMasterHelp
        status={customerMasterHelpStatus}
        onClose={() => {
          setCustomerMasterHelpStatus(false);
        }}
        onRowClick={(p) => {
          setId(p.row.CUSTOMER_CODE);
          setMobile(p.row.CUSTOMER_MOBILE);
          setCustomerName(p.row.CUSTOMER_NAME);
          setEmail(p.row.CUSTOMER_EMAIL);
          setAddress(p.row.CUSTOMER_ADDRESS);
          setType(p.row.CUSTOMER_TYPE);
          setStatus(p.row.CUSTOMER_STATUS);
          setCity(p.row.CITY);
          setSelectedCountry(p.row.COUNTRY);
          setPinCode(p.row.PIN_CODE);
          setDiscount(p.row.DISQ_RATE);
          setLoyalty(p.row.LOYALTY_POINTS);
          setPrevBal(p.row.PRV_BAL);
          setEmailVerified(p.row.EMAIL_VERIFIED === "Yes");
          setMobileVerfied(p.row.MOBILE_VERIFIED === "Yes");
          setPrevBal(p.row.PRV_BAL);
          setCompanyData({
            COMPANY_NAME: p.row.COMPANY_NAME,
            COMPANY_ADDRESS: p.row.COMPANY_ADDRESS,
            COMPANY_MOBILE: p.row.COMPANY_MOBILE,
            TAX_NUMBER: p.row.TAX_NUMBER,
            PAN_NO: p.row.PAN_NO,
            TAN: p.row.TAN,
          });
          setBalType(p.row.BAL_TYPE);
          const icode = p.row.CUSTOMER_CODE;
          const path = `retailx/customers/${prefix + icode}`;
          DownloadFile(path)
            .then((e) => {
              if (!e.includes("FirebaseError")) {
                setFirebaseUrl(e);
                setpath(path);
              } else {
                setpath("");
                setFirebaseUrl("");
              }
            })
            .catch((e) => {
              // setCapturedImage('');
              setFirebaseUrl("");
            });

          setCustomerMasterHelpStatus(false);
        }}
      />
      {!mobileStatus && (
        <OtpAlertModal
          status={otpStatus}
          otp={randome}
          onCancel={() => {
            setOtpStatus(false);
          }}
          onSubmit={() => {
            setOtpStatus(false);
            VerfiyMobileAPI().then((e) => console.log(e));
          }}
        />
      )}

      <AlertModal2 />
      <EmailChangeModal
        status={emailStatus}
        email={email}
        list={customerList}
        VerfiyEmailSentAPI={VerfiyEmailSentAPI}
        onClose={() => {
          setEmailStatus(false);
        }}
      />

      <MobileChangeModal
        status={mobileStatus}
        mobile={mobile}
        randome={randome}
        sendOTP={OtpSentAPI}
        list={customerList}
        VerifyMobileSentAPI={VerfiyMobileAPI}
        onClose={() => {
          setMobileStatus(false);
        }}
        onRefresh={clearState}
      />
    </div>
  );
};

export default CustomerMaster;
