import React, { useState } from "react";
import logo from "../../utility/Logo/TextLogo/blackTextLogo.svg";
import { useDispatch, useSelector } from "react-redux";
import { selectToken } from "../../utility/Redux/security";
import { selectWidth } from "../../utility/Redux/profile";
const sidebarClass = " flex flex-col";
const linkClass = "py-4 px-6 w-full text-gray-700 text-lg font-bold";
const borderBottomClass = "border-b border-border";

const SidebarLink = ({ text, borderBottom, onClick }) => {
  return (
    <div
      onClick={onClick}
      className={`${linkClass} ${borderBottom ? borderBottomClass : ""}`}
    >
      {text}
    </div>
  );
};
export const headerClasses =
  "flex fixed top-0 items-center bg-white justify-between p-1 bg-white border-b border-zinc-200 w-screen  z-50";
const logoClasses =
  "w-[100px] sm:w-[100px]  transition-transform transform hover:scale-105";
const locationButtonClasses =
  "text-gray-800 flex items-center hover:text-black transition-colors duration-200";
const buttonClasses =
  "text-gray-800 hover:bg-gray-100 transition-colors duration-200 rounded-md px-3 py-2 text-[12px] l:text-[15px]  whitespace-nowrap";
const signInButtonClasses =
  "bg-[#f88f09] text-white font-semibold hover:bg-[#f88f09] py-2 px-4 rounded-md ml-2 text-[12px] l:text-[15px]    transition-colors duration-200 shadow-md w-full";
const iconClasses = "w-6 h-6 text-[#f88f09]";

const Header = ({ navigate }) => {
  const [isSidebarOpen, setSidebarOpen] = useState(false); // State for sidebar
  const token = useSelector(selectToken);
  const toggleSidebar = () => setSidebarOpen(!isSidebarOpen);
  const width = useSelector(selectWidth);
  return (
    <header className={headerClasses}>
      <div
        style={{
          flexDirection: width > 768 ? "row" : "column",
        }}
        className="flex   w-full container"
      >
        {/* Left side - Logo and Location */}
        <div className="flex items-center  justify-between w-full ">
          <button
            onClick={() => {
              navigate("/");
            }}
            className=""
          >
            <img src={logo} className={logoClasses} alt="Logo" />
          </button>

          {/* Menu Icon for Mobile */}
          <button
            onClick={toggleSidebar}
            style={{
              display: width > 768 ? "none" : "flex",
            }}
            className=" p-2 text-[#f88f09] hover:text-black"
          >
            <i className="bi bi-list text-2xl"></i>
          </button>
        </div>

        {/* Right side - Links and Buttons */}
        <div
          style={{
            display: width > 768 ? "flex" : "none",
          }}
          className=" items-center "
        >
          <button
            className={buttonClasses}
            // onClick={() => navigation("/register_company")}
          >
            Terms & Conditions
          </button>
          <button
            className={buttonClasses}
            onClick={() =>
              window.open("https://www.geniusoffice.com/contact", "blank")
            }
          >
            Contact Us
          </button>

          <button
            className={buttonClasses}
            onClick={() => navigate("/auth/login")}
          >
            Login
          </button>
        </div>
      </div>

      {/* Sidebar for Mobile */}
      {isSidebarOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-25 z-40 md:hidden"
          onClick={toggleSidebar}
        >
          <div className="absolute right-0 top-0 w-64 h-full bg-white shadow-lg ">
            <div className=" bg-gray-100 w-full mb-4 ">
              <button className="text-right p-3" onClick={toggleSidebar}>
                <i className="bi bi-x-lg text-xl text-gray-600"></i>
              </button>
            </div>

            <div className={sidebarClass}>
              <SidebarLink
                text="About Us"
                borderBottom
                onClick={() => {
                  // navigation("/about_us");
                  toggleSidebar();
                }}
              />
              <SidebarLink
                text="Contact Us"
                onClick={() => {
                  // navigation("/contact_us");
                  toggleSidebar();
                }}
                borderBottom
              />
              <SidebarLink
                text="Register"
                onClick={() => {
                  // navigation("/register_company");
                  toggleSidebar();
                }}
              />
            </div>
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;
