import React, { useEffect, useRef, useState } from "react";
import ViewBillImage from "../billing/viewBillImage";
import CallAPI from "../utility/functions/getData";
import CryptoJS from "crypto-js";
import { useDispatch, useSelector } from "react-redux";
import { setSpinnerLoading } from "../utility/Redux/modal";
import { selectToken } from "../utility/Redux/security";
import { useSearchParams } from "react-router-dom";
import { CallAPIPromise } from "../billing/common";

const InvoiceBill = () => {
  const [billStatus, setBillStatus] = useState(true);
  const [orderList, setOrderList] = useState([]);
  const componentRef = useRef(null);
  const dispatch = useDispatch();
  const [data, setData] = useState([]);

  const [companyData, setCompanyData] = useState({
    code: "",
    company_email: "",
    company_name: "",
    company_mobile: "",
    company_website: "",
    group_name: null,
    address: "",
    city: "",
    state: "",
  });

  const [searchPeram] = useSearchParams(window.location.search);
  const prefix = searchPeram.get("prefix");
  const bill = searchPeram.get("bill");
  const companyEmail = searchPeram.get("companyEmail");
  useEffect(() => {
    if (companyEmail) {
      const secretPass = process.env.REACT_APP_AUTH_SECRET_KEY;
      const hmac = CryptoJS.HmacSHA256(companyEmail, secretPass);

      const hmacString = hmac.toString(CryptoJS.enc.Base64);
      const d = {
        company_email: companyEmail,
        company_mobile: "",
      };
      CallAPI(
        "/api/users/companyInfo",
        d,
        hmacString,
        (e) => {
          if (e.data.response[0]) {
            setCompanyData(e.data.response[0]);
          }
        },
        dispatch
      );
    }
  }, [companyEmail]);
  const getViewBillDetatils = () => {
    dispatch(setSpinnerLoading("Loading bill"));
    const hmac = CryptoJS.HmacSHA256(
      process.env.REACT_APP_AUTH_HMA_KEY,
      process.env.REACT_APP_AUTH_SECRET_KEY
    );

    const hmacString = hmac.toString(CryptoJS.enc.Base64);
    CallAPIPromise(
      "/api/loadDigiBill/digiBill",

      {
        table_prefix: prefix,
        INVNO: bill,
        BILL_PERFORMA: "bills",
      },
      hmacString,
      dispatch
    )
      .then((response) => {
        const dataList = response.data.response;
        setData(dataList);
      })
      .catch((e) => console.log(e))
      .finally(() => {
        dispatch(setSpinnerLoading(false));
      });
  };

  useEffect(() => {
    if (bill) {
      getViewBillDetatils();
    }
  }, [bill]);
  return (
    <div className="py-4 w-screen h-screen  flex justify-center bg-[#dce2ef]  items-start">
      <div className="shadow-lg w-fit bg-white rounded-[12px] overflow-hidden">
        <div className="h-[22px] w-[22px] flex  justify-self-center -mt-3 bg-[#dce2ef] rounded-full "></div>
        <ViewBillImage
          bill_performa={"bills"}
          companyData={companyData}
          tPrefix={prefix}
          byHmac={true}
          setBillStatus={setBillStatus}
          setOrderList={setOrderList}
          ref={componentRef}
          billNo={bill}
          data={data}
        />
      </div>
    </div>
  );
};

export default InvoiceBill;
