import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setAlertWithTitle,
  setSessionExpired,
  setSpinnerLoading,
} from "../utility/Redux/modal";
import { selectCompanyAddress } from "../utility/Redux/profile";
import axios from "axios";
import CryptoJS from "crypto-js";
import { PiRadioButtonDuotone } from "react-icons/pi";
import ModalHeaderStyle from "../utility/component/styles/modalHeader";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { RestaurantOutlined } from "@mui/icons-material";
import uniqeId from "uniqid";
import moment from "moment";
import html2canvas from "html2canvas";
import "./qrCss.css";
import { QRCodeSVG } from "qrcode.react";
import { QRCodeGenerator } from "./qrCodeModal";
import { useReactToPrint } from "react-to-print";
import { CallAPIPromise } from "../billing/common";
import GeniustextInput from "../utility/component/mastertext";
const CrossCompanyList = () => {
  const dispatch = useDispatch();
  const [rows, setRows] = useState([]);
  const [orignalRows, setOrignalRows] = useState([]);
  const [websiteLink, setWebSiteLink] = useState("");
  const [fullList, setFullList] = useState([]);
  const [qrCodeModalStatus, setQrCodeModalStatus] = useState(false);
  const statusList = [
    {
      status: "Active",
      icon: (
        <PiRadioButtonDuotone
          style={{
            fontSize: "20px",
            color: "green",
          }}
        />
      ),
    },
    {
      status: "Inactive",
      icon: (
        <PiRadioButtonDuotone
          style={{
            fontSize: "20px",
            color: "gray",
          }}
        />
      ),
    },
  ];

  const statusOption = statusList.map((o) => ({
    value: o.status,
    label: o.status,
    icon: o.icon,
  }));
  const [filter, setFilter] = useState(statusOption[0]);
  const [searchQuery, setSearchQuery] = useState("");

  const formatOptionLabel = ({ icon, label }) => (
    <div className="flex items-center">
      <div className="mr-1">{icon}</div>
      <span className="">{label}</span>
    </div>
  );
  const colums = [
    {
      name: "Status",
      width: 140,
    },
    {
      name: "Company",
      width: 220,
    },
    // {
    //   name: "Price",
    //   width: 100,
    // },
    {
      name: "Shop Id",
      width: 140,
    },
    {
      name: "Shop Name",
      width: 240,
    },
    {
      name: "Shop Radius",
      width: 240,
    },
    {
      name: "Start Date",
      width: 180,
    },
    {
      name: "End Date",
      width: 180,
    },
  ];
  const secretPass = process.env.REACT_APP_AUTH_SECRET_KEY;

  const companyDetail = useSelector(selectCompanyAddress);
  const hmac = CryptoJS.HmacSHA256(companyDetail.email, secretPass);

  const hmacString = hmac.toString(CryptoJS.enc.Base64);
  const getData = async () => {
    dispatch(setSpinnerLoading("Loading"));
    const data = {
      company_email: companyDetail.email,
      company_mobile: "",
    };
    CallAPIPromise("/api/users/companyHelp", data, hmacString, dispatch)
      .then((p) => {
        if (!p.data.error) {
          let list = [];
          const data = p.data.response;

          for (let i = 0; i < data.length; i++) {
            list.push({
              ...data[i],
              id: i + 1,
            });
          }
          setRows(list);
          setFullList(list);
          setOrignalRows(list);
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response.data.name === "TokenExpiredError") {
          dispatch(setSessionExpired(true));
          console.log(error.response);
        } else {
          dispatch(
            setAlertWithTitle({
              title: "ERROR",
              msg: "Something went wrong.",
              lottie: "reject",
            })
          );
        }
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
      });
  };
  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    if (searchQuery) {
      const regex = /\d+/;

      const searchItems = fullList.filter((item) => {
        if (regex.test(searchQuery.substring(0, 1))) {
          return item.id.toString().includes(searchQuery);
        } else {
          return item.company_name
            .toLowerCase()
            .includes(searchQuery.toLowerCase());
        }
      });

      setRows(searchItems);
    } else {
      setRows(fullList);
    }
  }, [searchQuery]);

  const UpdateCompanyDetailsAPI = (companyInfo) => {
    if (
      (companyInfo.cross_start_date &&
        companyInfo.cross_end_date &&
        companyInfo.shop_name) ||
      !companyInfo.shop_id
    ) {
      const secretPass = process.env.REACT_APP_AUTH_SECRET_KEY;
      const email = companyInfo.company_email;
      // const hmac = CryptoJS.HmacSHA256(message, secret);
      const hmac = CryptoJS.HmacSHA256(email, secretPass);
      // Convert the HMAC to a string and encode it using Base64
      const hmacString = hmac.toString(CryptoJS.enc.Base64);
      const data = {
        company_email: companyInfo.company_email,

        cross_start_date: moment(companyInfo.cross_start_date).format(
          "YYYY-MM-DD hh:mm:ss"
        ),
        cross_end_date: moment(companyInfo.cross_end_date).format(
          "YYYY-MM-DD hh:mm:ss"
        ),
        shop_name: companyInfo.shop_name,
        shop_id: companyInfo.shop_id,
        shop_radius: companyInfo.shop_radius,
        shop_status: "Active",
      };
      dispatch(setSpinnerLoading("Updating..."));
      CallAPIPromise(
        `/api/users/activateCrossShop`,

        data,
        hmacString,
        dispatch
      )
        .then(async function async(response) {
          if (response.status === 200) {
            dispatch(
              setAlertWithTitle({
                title: "Success",
                msg: response.data.message,
              })
            );

            getData();
          }
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(() => {
          dispatch(setSpinnerLoading(false));
        });
    } else {
      if (!companyInfo.cross_start_date || !companyInfo.cross_end_date) {
        dispatch(
          setAlertWithTitle({
            title: "Alert",
            msg: "Date are required",
          })
        );
      } else {
        dispatch(
          setAlertWithTitle({
            title: "Alert",
            msg: "Shop name required",
          })
        );
      }
    }
  };

  const qrRef = useRef();
  const [qrValue, setQrValue] = useState("");

  const [filename, setFileName] = useState("");
  const handlePrint = useReactToPrint({
    content: () => qrRef.current,
    pageStyle: `
    @page {
      size: 320px 433px;
      margin: 0px;
      padding: 0px

    }
  `,
  });
  const downloadQRCode = async () => {
    const canvas = await html2canvas(qrRef.current);
    const dataUrl = canvas.toDataURL("image/png");

    const link = document.createElement("a");
    link.href = dataUrl;
    link.download = `${filename}.png`; // Name of the downloaded file
    link.click();
  };
  const navigate = useNavigate();
  return (
    <div className="p-4 flex bg-[#f3f5f9] justify-center  h-full  text-sm items-center w-full">
      <div className="min-w-[80%] h-[95%]">
        <div className="bg-white   ">
          <ModalHeaderStyle
            header={"Cross Company List"}
            bg={"#f3f5f9"}
            border={"1px solid #babab8"}
            onClose={() => navigate("/settings")}
          />
        </div>
        <div
          style={{
            borderLeft: "1px solid #babab8",
            borderRight: "1px solid #babab8",
            borderBottom: "1px solid #babab8",
            height: `calc(100% - 80px)`,
          }}
          className="p-4 rounded-b-sm "
        >
          <div className="flex  ">
            {colums.map((o, key) => {
              return (
                <div
                  key={key}
                  style={{
                    width: o.width,
                  }}
                  className="px-4 mb-2 font-semibold text-[16px]"
                >
                  {o.name}
                </div>
              );
            })}
          </div>
          <div
            style={{
              height: `calc(100% - 100px)`,
            }}
            className="overflow-y-auto pr-2"
          >
            {rows.map((company, i) => {
              const orignal = orignalRows.find((c) => c.code === company.code);
              return (
                <div className="flex bg-white justify-between p-4 rounded-sm  mt-3 ">
                  <div className="flex">
                    {colums.map((column, key) => {
                      return (
                        <div
                          key={key}
                          style={{
                            width: column.width,
                          }}
                        >
                          {column.name === "Company" && (
                            <div className="h-full items-center flex">
                              {company.company_name}
                            </div>
                          )}
                          {console.log(company.shop_status)}
                          {column.name === "Status" && (
                            <div className="h-full items-center flex">
                              <Select
                                options={statusOption}
                                isSearchable={false} // Disable searching within the dropdown
                                isClearable={false} // Disable the clear option (if using)
                                formatOptionLabel={formatOptionLabel}
                                onChange={(e) => {
                                  const value = e.value;
                                  setFullList((f) =>
                                    f.map((fl) => {
                                      if (fl.code === company.code) {
                                        return {
                                          ...company,
                                          shop_status: value,
                                        };
                                      } else {
                                        return fl;
                                      }
                                    })
                                  );

                                  setRows((f) =>
                                    f.map((fl) => {
                                      if (fl.code === company.code) {
                                        return {
                                          ...company,
                                          shop_status: value,
                                        };
                                      } else {
                                        return fl;
                                      }
                                    })
                                  );
                                }}
                                value={{
                                  label: company.shop_status,
                                  value: company.shop_status,
                                }}
                                className="basic-single w-[120px] outline-none"
                                classNamePrefix="select"
                                styles={{
                                  // Styles for the single selected value
                                  singleValue: (base) => ({
                                    ...base,
                                    color: "black", // Text color for the selected option
                                    padding: "2px", // Padding inside the single value
                                    borderRadius: "5px", // Rounded corners for the single value
                                    border: "none", // Remove border around the single value
                                  }),

                                  // Styles for the control (the input field)
                                  control: (base) => ({
                                    ...base,
                                    color: "black", // Text color for the control
                                    fontSize: "12px", // Font size for the control
                                    border: "none", // Remove border for a cleaner look
                                    outline: "none", // Remove outline on focus
                                    borderRadius: "7px", // Rounded corners for the control
                                    height: "30px", // Set height of the control
                                    minHeight: "30px", // Ensure a minimum height
                                    padding: "0px", // No padding inside the control
                                    boxShadow: "none", // Remove any box shadow,
                                  }),

                                  // Styles for the options in the dropdown
                                  option: (base, state) => ({
                                    ...base,
                                    backgroundColor: state.isSelected
                                      ? "#e7f0ff"
                                      : "white", // Background color for options
                                    color: "black", // Text color for options
                                    display: "flex", // Use flexbox for alignment
                                    alignItems: "center", // Center items vertically
                                    padding: "10px", // Padding for each option
                                    cursor: "pointer", // Pointer cursor on hover
                                    fontSize: "14px", // Size of
                                    "&:hover": {
                                      backgroundColor: "#f0f0f0", // Change background on hover
                                    },
                                  }),

                                  // Optional: Styles for the dropdown indicator (chevron)
                                  dropdownIndicator: (base) => ({
                                    ...base,
                                    color: "white", // Color for the dropdown indicator
                                    padding: "3px",
                                    fontWeight: "normal",
                                  }),

                                  // Optional: Styles for the clear indicator (if using)
                                  clearIndicator: (base) => ({
                                    ...base,
                                    color: "white", // Color for the clear indicator
                                  }),

                                  // Optional: Styles for the loading indicator (if using)
                                  loadingIndicator: (base) => ({
                                    ...base,
                                    color: "white", // Color for the loading indicator
                                  }),

                                  // Optional: Styles for the placeholder (if using)
                                  placeholder: (base) => ({
                                    ...base,
                                    color: "white", // Color for the placeholder text
                                    opacity: 0.8, // Slightly transparent for the placeholder
                                  }),
                                }}
                              />
                            </div>
                          )}

                          {column.name === "Shop Id" && (
                            <div>
                              <div>
                                {" "}
                                {company.shop_id ? (
                                  company.shop_id
                                ) : (
                                  <button
                                    onClick={() => {
                                      const shop_id = uniqeId();
                                      setFullList((f) =>
                                        f.map((fl) => {
                                          if (fl.code === company.code) {
                                            return {
                                              ...company,
                                              shop_id: shop_id,
                                            };
                                          } else {
                                            return fl;
                                          }
                                        })
                                      );

                                      setRows((f) =>
                                        f.map((fl) => {
                                          if (fl.code === company.code) {
                                            return {
                                              ...company,
                                              shop_id: shop_id,
                                            };
                                          } else {
                                            return fl;
                                          }
                                        })
                                      );
                                    }}
                                    className="text-[#ff6a11]"
                                  >
                                    Generate Shop Id{" "}
                                  </button>
                                )}
                              </div>
                            </div>
                          )}
                          {column.name === "Shop Name" && (
                            <div className="h-full items-center flex">
                              <GeniustextInput
                                type={"text"}
                                hideHeader={true}
                                value={company.shop_name}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  setFullList((f) =>
                                    f.map((fl) => {
                                      if (fl.code === company.code) {
                                        return {
                                          ...company,
                                          shop_name: value,
                                        };
                                      } else {
                                        return fl;
                                      }
                                    })
                                  );

                                  setRows((f) =>
                                    f.map((fl) => {
                                      if (fl.code === company.code) {
                                        return {
                                          ...company,
                                          shop_name: value,
                                        };
                                      } else {
                                        return fl;
                                      }
                                    })
                                  );
                                }}
                              />
                            </div>
                          )}

                          {column.name === "Shop Radius" && (
                            <div className="h-full items-center flex">
                              <GeniustextInput
                                type={"text"}
                                hideHeader={true}
                                value={company.shop_radius}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  setFullList((f) =>
                                    f.map((fl) => {
                                      if (fl.code === company.code) {
                                        return {
                                          ...company,
                                          shop_radius: value,
                                        };
                                      } else {
                                        return fl;
                                      }
                                    })
                                  );

                                  setRows((f) =>
                                    f.map((fl) => {
                                      if (fl.code === company.code) {
                                        return {
                                          ...company,
                                          shop_radius: value,
                                        };
                                      } else {
                                        return fl;
                                      }
                                    })
                                  );
                                }}
                              />
                            </div>
                          )}
                          {column.name === "Start Date" && (
                            <div>
                              <input
                                type="date"
                                className="outline-none"
                                value={moment(company.cross_start_date).format(
                                  "YYYY-MM-DD"
                                )}
                                onChange={(e) => {
                                  const date = moment(e.target.value).format(
                                    "YYYY-MM-DD"
                                  );
                                  setFullList((f) =>
                                    f.map((fl) => {
                                      if (fl.code === company.code) {
                                        return {
                                          ...company,
                                          cross_start_date: date,
                                        };
                                      } else {
                                        return fl;
                                      }
                                    })
                                  );

                                  setRows((f) =>
                                    f.map((fl) => {
                                      if (fl.code === company.code) {
                                        return {
                                          ...company,
                                          cross_start_date: date,
                                        };
                                      } else {
                                        return fl;
                                      }
                                    })
                                  );
                                }}
                              />
                            </div>
                          )}
                          {column.name === "End Date" && (
                            <div>
                              <input
                                type="date"
                                className="outline-none"
                                value={moment(company.cross_end_date).format(
                                  "YYYY-MM-DD"
                                )}
                                onChange={(e) => {
                                  const date = moment(e.target.value).format(
                                    "YYYY-MM-DD"
                                  );
                                  setFullList((f) =>
                                    f.map((fl) => {
                                      if (fl.code === company.code) {
                                        return {
                                          ...company,
                                          cross_end_date: date,
                                        };
                                      } else {
                                        return fl;
                                      }
                                    })
                                  );

                                  setRows((f) =>
                                    f.map((fl) => {
                                      if (fl.code === company.code) {
                                        return {
                                          ...company,
                                          cross_end_date: date,
                                        };
                                      } else {
                                        return fl;
                                      }
                                    })
                                  );
                                }}
                              />
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>

                  <div className="flex ">
                    <button
                      // disabled={orignal.shop_status === company.shop_status}
                      onClick={() => UpdateCompanyDetailsAPI(company)}
                      className="bg-[#FF6A11] text-white disabled:bg-[#B47A52] px-4 rounded-sm flex items-center"
                    >
                      Save
                    </button>
                    <button
                      onClick={() => {
                        setQrCodeModalStatus(true);
                        setWebSiteLink(``);
                      }}
                      className="bg-[#2c3e50] ml-3 text-white px-2 rounded-sm flex items-center"
                    >
                      <i className="bi bi-qr-code-scan "></i>
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
          <QRCodeGenerator
            qrValue={""}
            downloadQRCode={downloadQRCode}
            isModalOpen={qrCodeModalStatus}
            closeModal={() => setQrCodeModalStatus(false)}
            idRef={qrRef}
            handlePrint={handlePrint}
          />
          <div
            style={{
              border: "1px solid #babab8",
            }}
            className="rounded-sm w-full p-2 mt-3"
          >
            <input
              className="w-full bg-transparent outline-none"
              placeholder="Seacrh"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>

          <div className="py-20">
            <div
              // ref={qrRef}
              style={{
                padding: 50,
                width: 2100,
                height: 2100,
              }}
            >
              <div id={"print-container"}>
                <QRCodeSVG size={2000} value={qrValue} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CrossCompanyList;
