import React, { useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import ModalHeaderStyle from "../utility/component/styles/modalHeader";
import { useNavigate } from "react-router-dom";
import { UploadFile } from "../utility/functions/firebase";
import { CallAPIPromise } from "../billing/common";
import { useDispatch, useSelector } from "react-redux";
import { selectToken } from "../utility/Redux/security";
import { setAlertWithTitle, setSpinnerLoading } from "../utility/Redux/modal";

function ImageMaster() {
  const [images, setImages] = useState([]);
  const [title, setTitle] = useState("");

  // Handle files dropped
  const onDrop = (acceptedFiles) => {
    const newImages = acceptedFiles.map((file) => ({
      IMAGE_ID: Date.now(),
      url: URL.createObjectURL(file),
      file, // Keeping the file reference for upload
      IMAGE_TAG: "",
    }));
    setImages((prevImages) => [...prevImages, ...newImages]);
  };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: "image/*",
    multiple: true,
  });
  // Update tags for images
  const updateTags = (id, newTags) => {
    setImages((prevImages) =>
      prevImages.map((img) =>
        img.IMAGE_ID === id ? { ...img, IMAGE_TAG: newTags } : img
      )
    );
  };

  // Remove an image
  const removeImage = (id) => {
    setImages((prevImages) => prevImages.filter((img) => img.IMAGE_ID !== id));
  };
  const token = useSelector(selectToken);
  const dispatch = useDispatch();
  // Save images to backend
  const saveImages = async () => {
    images.map((img) => {
      const name = img.IMAGE_ID;
      const path = "retailx/image_library/";
      UploadFile(img.file, path, name);
    });
    dispatch(setSpinnerLoading("Uploading"));
    CallAPIPromise(
      `/api/images/saveImage`,
      {
        image_cart: images.map((img) => {
          return {
            IMAGE_ID: img.IMAGE_ID,
            IMAGE_TAG: img.IMAGE_TAG ? img.IMAGE_TAG + "," + title : title,
          };
        }),
      },
      token,
      dispatch
    )
      .then(() => {
        setTitle("");
        dispatch(
          setAlertWithTitle({
            title: "Alert",
            msg: "Image saved successfully",
          })
        );
        setImages([]);
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
      });
  };
  const headerRef = useRef();
  const navigate = useNavigate();
  const handleClose = () => {
    navigate("/masters");
  };
  return (
    <div className="w-full h-full  bg-[#f5f5f7]   max-sm:py-2 ">
      <div
        style={{
          paddingTop: "16px",
          paddingBottom: "16px",
        }}
        className="flex justify-center  h-full "
      >
        <div
          style={{ borderRadius: "12px", height: "100%" }}
          className=" flex items-center h-full overflow-y-auto"
        >
          <div className="h-full flex flex-col ">
            <div ref={headerRef}>
              <ModalHeaderStyle
                header={"Image Library"}
                onClose={handleClose}
              />
            </div>
            <div className="container p-4 bg-white">
              <div className="">
                <div className="mb-4">
                  <input
                    type="text"
                    placeholder="Enter a title for the image set"
                    className="border p-2 w-full"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </div>

                {/* Drag-and-Drop Zone */}
                <div
                  {...getRootProps()}
                  className={`border-2 border-dashed p-6 h-[200px] text-center ${
                    isDragActive ? "bg-blue-100" : "bg-gray-100"
                  }`}
                >
                  <input {...getInputProps()} />
                  {isDragActive ? (
                    <p>Drop the files here...</p>
                  ) : (
                    <p>Drag & drop some files here, or click to select files</p>
                  )}
                </div>

                {/* Preview Section */}
                <div
                  className="image-grid grid grid-cols-3 gap-4 mt-4 overflow-y-auto"
                  style={{ maxHeight: "300px" }}
                >
                  {images.map((img) => (
                    <div
                      key={img.IMAGE_ID}
                      className="image-card border p-2 relative"
                    >
                      <img
                        src={img.url}
                        alt="Preview"
                        className="w-full h-32 object-cover mb-2"
                      />
                      <input
                        type="text"
                        placeholder="Add tags (comma-separated)"
                        className="border p-1 w-full"
                        value={img.IMAGE_TAG}
                        onChange={(e) =>
                          updateTags(img.IMAGE_ID, e.target.value)
                        }
                      />
                      {/* Remove Button */}
                      <button
                        className="absolute top-1 right-1 bg-red-500 text-white p-1 rounded text-xs"
                        onClick={() => removeImage(img.IMAGE_ID)}
                      >
                        Remove
                      </button>
                    </div>
                  ))}
                </div>

                <div className="flex justify-end">
                  <button
                    className="bg-blue-500 text-white p-2 mt-4 rounded"
                    onClick={saveImages}
                    disabled={!images.length || !title}
                  >
                    Save Images
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ImageMaster;
