import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import GeniustextInput from "../../utility/component/mastertext";
import ModalHeaderStyle from "../../utility/component/styles/modalHeader";
import {
  ModifyButton,
  SaveButton,
  UndoButton,
} from "../../utility/component/buttons";
import GeniusDatagrid from "../../utility/component/datagrid/geniusDatagrid";
import {
  setAlertWithTitle,
  setSessionExpired,
  setSpinnerLoading,
} from "../../utility/Redux/modal";
import { useNavigate } from "react-router-dom";
import { selectPrefix, selectWidth } from "../../utility/Redux/profile";
import { selectToken } from "../../utility/Redux/security";
import { useFocus } from "../../utility/hooks/hooks";
import { CallAPIPromise } from "../../billing/common";
import { HsnMasterHelp, SectionMasterHelp } from "../../help";
import AlertModalTwoButton from "../../utility/component/modals/alertWithTwoButton";
const BulkChangeMaster = ({ onClose, afterCreate }) => {
  const dispatch = useDispatch();
  const prefix = useSelector(selectPrefix);
  const token = useSelector(selectToken);
  const [hsnCode, sethsnCode] = useState();
  const [taxRate, setTaxRate] = useState("");
  const [updateSectionData, setUpdateSectionData] = useState({
    OLD_SECTION_NAME: "",
    NEW_SECTION_NAME: "",
    table_prefix: prefix,
  });
  const [alertTwoButtonModal, setAlertTwoButtonModal] = useState({
    title: "",
    msg: "",
    status: false,
    setStatus: "",
    button1: "",
    button1Click: "",
    button2: "",
    button2Click: "",
  });
  const [updateHSNData, setUpdateHSNData] = useState({
    OLD_HSN_CODE: "",
    NEW_HSN_CODE: "",
    TAX1_RATE: "",
    TAX2_RATE: "",
    TAX3_RATE: "",
    OLD_TAX1_RATE: "",
    OLD_TAX2_RATE: "",
    OLD_TAX3_RATE: "",
    table_prefix: prefix,
  });
  const [hsnType, setHSNType] = useState("");
  const navigate = useNavigate();
  const windowWidth = useSelector(selectWidth);
  const [hsnRef, setHsmFocus] = useFocus();
  const [taxRateRef, setTaxRateFocus] = useFocus();
  const [sectionType, setSectionType] = useState("");
  const [sectionMasterhelpStatus, setSectionMasterhelpStatus] = useState(false);
  const [hsnMasterHelpStatus, setHsnmasterHelpStatus] = useState(false);
  const isPadding = window.location.pathname.includes("hsn_master");

  return (
    <div
      style={{
        height: afterCreate ? window.innerHeight - 150 : "100%",
      }}
      className="w-full  flex justify-center bg-[#f5f5f7] items-center py-10 "
    >
      <div
        style={{
          padding: isPadding ? "16px" : "0px",
        }}
        className="text-sm  rounded-sm   flex flex-col   h-full  "
      >
        <div>
          <ModalHeaderStyle
            header={"Bulk Changes Master"}
            onClose={() => {
              if (onClose) {
                onClose();
              } else {
                navigate("/masters");
              }
            }}
          />
        </div>
        <div
          style={{
            height: "calc(100% - 160px)",
          }}
          className=" bg-[#fdfffe] rounded-b-sm   border-brown p-3 max-sm:px-2"
        >
          <div id="input" className="flex ">
            <div className="flex">
              <GeniustextInput
                title={"Old Section Name"}
                width={windowWidth > 768 ? 300 : 200}
                type={"text"}
                value={updateSectionData.OLD_SECTION_NAME}
                refrance={hsnRef}
                search={() => {
                  setSectionType("OLD_SECTION_NAME");
                  setSectionMasterhelpStatus(true);
                }}
                autofocus={true}
              />
              <div className="ml-2">
                <GeniustextInput
                  refrance={taxRateRef}
                  title={"New Section Name"}
                  width={windowWidth > 768 ? 300 : 100}
                  type={"text"}
                  titleColor={"red"}
                  valueColor={"red"}
                  search={() => {
                    setSectionType("NEW_SECTION_NAME");
                    setSectionMasterhelpStatus(true);
                  }}
                  value={updateSectionData.NEW_SECTION_NAME}
                />
              </div>
            </div>
          </div>

          <div className="flex w-full justify-end items-center pt-3">
            <SaveButton
              onClick={() => {
                if (
                  updateSectionData.NEW_SECTION_NAME &&
                  updateSectionData.OLD_SECTION_NAME
                ) {
                  setAlertTwoButtonModal({
                    title: "Update Section Name",
                    msg: `Are you sure you want to update Section Name  from ${updateSectionData.OLD_SECTION_NAME} to ${updateSectionData.NEW_SECTION_NAME}`,
                    status: true,
                    button1: "Yes",
                    button2: "Cancel",
                    button1Click: () => {
                      dispatch(setSpinnerLoading("Updating"));
                      CallAPIPromise(
                        "/api/master/updateSectionName",
                        updateSectionData,
                        token
                      )
                        .then((res) => {
                          dispatch(
                            setAlertWithTitle({
                              title: "Alert",
                              msg: "HSN Updated",
                            })
                          );
                          setUpdateSectionData({
                            OLD_SECTION_NAME: "",
                            NEW_SECTION_NAME: "",
                            table_prefix: prefix,
                          });
                        })
                        .finally(() => {
                          dispatch(setSpinnerLoading(false));
                        });
                      setAlertTwoButtonModal((o) => {
                        return {
                          ...o,
                          status: false,
                        };
                      });
                    },

                    button2Click: () => {
                      setAlertTwoButtonModal((o) => {
                        return {
                          ...o,
                          status: false,
                        };
                      });
                    },
                  });
                }
              }}
            />
          </div>

          <div id="input" className="  border-t pt-4 mt-4">
            <div className="flex">
              <GeniustextInput
                title={"Old HSN Code"}
                width={windowWidth > 768 ? 300 : 200}
                type={"text"}
                value={updateHSNData.OLD_HSN_CODE}
                refrance={hsnRef}
                search={() => {
                  setHSNType("OLD_HSN_CODE");
                  setHsnmasterHelpStatus(true);
                }}
              />
              <div className="ml-2">
                <GeniustextInput
                  refrance={taxRateRef}
                  title={"New HSN Code"}
                  titleColor={"red"}
                  valueColor={"red"}
                  width={windowWidth > 768 ? 300 : 100}
                  type={"text"}
                  search={() => {
                    setHSNType("NEW_HSN_CODE");
                    setHsnmasterHelpStatus(true);
                  }}
                  value={updateHSNData.NEW_HSN_CODE}
                />
              </div>
            </div>

            <div className="flex justify-between">
              <div className="flex ">
                <GeniustextInput
                  title={"CGST"}
                  type={"text"}
                  width={80}
                  value={updateHSNData.OLD_TAX1_RATE}
                  refrance={hsnRef}
                />
                <div className="ml-2">
                  <GeniustextInput
                    refrance={taxRateRef}
                    title={"SGST"}
                    type={"text"}
                    width={80}
                    value={updateHSNData.OLD_TAX2_RATE}
                  />
                </div>

                <div className="ml-2">
                  <GeniustextInput
                    refrance={taxRateRef}
                    title={"IGST"}
                    type={"text"}
                    width={80}
                    value={updateHSNData.OLD_TAX3_RATE}
                  />
                </div>
              </div>

              <div className="flex ">
                <GeniustextInput
                  title={"CGST"}
                  type={"text"}
                  width={80}
                  value={updateHSNData.TAX1_RATE}
                  refrance={hsnRef}
                  titleColor={"red"}
                  valueColor={"red"}
                />
                <div className="ml-2">
                  <GeniustextInput
                    refrance={taxRateRef}
                    title={"SGST"}
                    type={"text"}
                    titleColor={"red"}
                    valueColor={"red"}
                    width={80}
                    value={updateHSNData.TAX2_RATE}
                  />
                </div>

                <div className="ml-2 flex items-center">
                  <GeniustextInput
                    refrance={taxRateRef}
                    title={"IGST"}
                    type={"text"}
                    width={80}
                    titleColor={"red"}
                    valueColor={"red"}
                    value={updateHSNData.TAX3_RATE}
                  />
                  <button
                    onClick={() => {
                      if (
                        !updateHSNData.TAX3_RATE ||
                        updateHSNData.TAX3_RATE === 0 ||
                        updateHSNData.TAX3_RATE === "0"
                      ) {
                        setUpdateHSNData((d) => {
                          return {
                            ...d,
                            TAX1_RATE: 0,
                            TAX2_RATE: 0,
                            TAX3_RATE: d.TAX1_RATE + d.TAX2_RATE,
                          };
                        });
                      }
                    }}
                    className="bg-red-500 ml-2 p-2 rounded-sm mt-2 h-fit"
                  >
                    <i class="bi bi-hand-index-thumb-fill text-white"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="flex w-full justify-end items-center pt-3">
            <SaveButton
              onClick={() => {
                if (updateHSNData.NEW_HSN_CODE && updateHSNData.OLD_HSN_CODE) {
                  setAlertTwoButtonModal({
                    title: "Update HSN CODE",
                    msg: `Are you sure you want to update HSN CODE and Taxes from ${updateHSNData.OLD_HSN_CODE} to ${updateHSNData.NEW_HSN_CODE}`,
                    status: true,
                    button1: "Yes",
                    button2: "Cancel",
                    button1Click: () => {
                      dispatch(setSpinnerLoading("Updating"));
                      CallAPIPromise(
                        "/api/master/updateHsnCode",
                        updateHSNData,
                        token
                      )
                        .then((res) => {
                          dispatch(
                            setAlertWithTitle({
                              title: "Alert",
                              msg: "HSN Updated",
                            })
                          );
                          setUpdateHSNData({
                            OLD_HSN_CODE: "",
                            NEW_HSN_CODE: "",
                            TAX1_RATE: "",
                            TAX2_RATE: "",
                            TAX3_RATE: "",
                            OLD_TAX1_RATE: "",
                            OLD_TAX2_RATE: "",
                            OLD_TAX3_RATE: "",
                            table_prefix: prefix,
                          });
                        })
                        .finally(() => {
                          dispatch(setSpinnerLoading(false));
                        });
                      setAlertTwoButtonModal((o) => {
                        return {
                          ...o,
                          status: false,
                        };
                      });
                    },

                    button2Click: () => {
                      setAlertTwoButtonModal((o) => {
                        return {
                          ...o,
                          status: false,
                        };
                      });
                    },
                  });
                }
              }}
            />
          </div>
          <SectionMasterHelp
            status={sectionMasterhelpStatus}
            onClose={() => setSectionMasterhelpStatus(false)}
            onRowClick={(p) => {
              setSectionMasterhelpStatus(false);
              setUpdateSectionData((s) => {
                return {
                  ...s,
                  [sectionType]: p.row.SECTION_NAME,
                };
              });
            }}
          />
          <HsnMasterHelp
            status={hsnMasterHelpStatus}
            onClose={() => setHsnmasterHelpStatus(false)}
            onRowClick={(p) => {
              setHsnmasterHelpStatus(false);
              setUpdateHSNData((s) => {
                return {
                  ...s,
                  [hsnType]: p.row.HSN_CODE,
                  TAX1_RATE:
                    hsnType === "NEW_HSN_CODE"
                      ? p.row.TAX_RATE / 2
                      : s.TAX1_RATE,
                  TAX2_RATE:
                    hsnType === "NEW_HSN_CODE"
                      ? p.row.TAX_RATE / 2
                      : s.TAX2_RATE,
                  TAX3_RATE: hsnType === "NEW_HSN_CODE" ? "0" : s.TAX3_RATE,

                  OLD_TAX1_RATE:
                    hsnType === "OLD_HSN_CODE"
                      ? p.row.TAX_RATE / 2
                      : s.OLD_TAX1_RATE,
                  OLD_TAX2_RATE:
                    hsnType === "OLD_HSN_CODE"
                      ? p.row.TAX_RATE / 2
                      : s.OLD_TAX2_RATE,
                  OLD_TAX3_RATE:
                    hsnType === "OLD_HSN_CODE" ? "0" : s.OLD_TAX3_RATE,
                };
              });
            }}
          />
        </div>
      </div>
      <AlertModalTwoButton
        details={alertTwoButtonModal}
        setAlertTwoButtonModal={setAlertTwoButtonModal}
      />
    </div>
  );
};

export default BulkChangeMaster;
