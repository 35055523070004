import { useDispatch, useSelector } from "react-redux";
import { setSessionExpired, setSpinnerLoading } from "../utility/Redux/modal";
import { useState } from "react";
import { selectToken } from "../utility/Redux/security";
import { useEffect } from "react";
import { selectPrefix, selectWidth } from "../utility/Redux/profile";
import MasterHelp from "../utility/component/modals/masterHelp";
import moment from "moment";
import { CallAPIPromise } from "../billing/common";

const PendingBillHelp = ({
  onRowClick,
  status,
  onClose,
  setItemMasterHelpStatus,
}) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    height: "80%",
    width: "auto",
    padding: "2px",
    borderRadius: "8px",
    // Change the shadow color
    overflow: "hidden",
    backgroundColor: "white",
  };

  const [rows, setRows] = useState([]);
  const token = useSelector(selectToken);

  const prefix = useSelector(selectPrefix);

  const windowWidth = useSelector(selectWidth);
  const columns = [
    {
      width:
        windowWidth <= 400
          ? 70
          : windowWidth <= 940
          ? 100
          : windowWidth <= 1280
          ? 100
          : 120,
      headerAlign: "center",
      align: "left",
      field: "DATE",
      renderCell: (p) => {
        return moment(p.value).format("DD-MM-YYYY");
      },
      renderHeader: (params) => <div>Date</div>,
    },
    {
      width:
        windowWidth <= 400
          ? 70
          : windowWidth <= 940
          ? 70
          : windowWidth <= 1280
          ? 50
          : 70,
      headerAlign: "center",
      align: "left",
      field: "INVNO",
      renderHeader: (params) => <div>Invno</div>,
    },
    {
      width:
        windowWidth <= 400
          ? 70
          : windowWidth <= 940
          ? 150
          : windowWidth <= 1280
          ? 120
          : 150,
      headerAlign: "center",
      align: "left",
      field: "CUSTOMER_NAME",
      renderHeader: (params) => <div>Customer Name</div>,
    },
    {
      width:
        windowWidth <= 400
          ? 70
          : windowWidth <= 940
          ? 110
          : windowWidth <= 1280
          ? 110
          : 150,
      headerAlign: "center",
      align: "left",
      field: "CUSTOMER_MOBILE",
      renderHeader: (params) => <div>Mobile</div>,
    },

    {
      width:
        windowWidth <= 400
          ? 50
          : windowWidth <= 940
          ? 130
          : windowWidth <= 1280
          ? 110
          : 300,

      headerAlign: "center",
      align: "left",
      field: "NAME",
      renderHeader: (params) => <div>Party Name</div>,
    },
  ];
  const mobileColumns = [
    {
      width: 80,
      headerAlign: "center",
      align: "left",
      field: "DATE",
      renderCell: (p) => {
        return moment(p.value).format("DD-MM-YYYY");
      },
      renderHeader: (params) => <div>Date</div>,
    },
    {
      width: 60,
      headerAlign: "center",
      align: "left",
      field: "INVNO",
      renderHeader: (params) => <div>Invno</div>,
    },

    {
      width: 100,
      headerAlign: "center",
      align: "left",
      field: "CUSTOMER_MOBILE",
      renderHeader: (params) => <div>Mobile</div>,
    },

    {
      width: 100,

      headerAlign: "center",
      align: "left",
      field: "NAME",
      renderHeader: (params) => <div>Party Name</div>,
    },
  ];
  const [searchQuery, setSearchQuery] = useState("");
  const [fullList, setFullList] = useState([]);

  useEffect(() => {
    if (searchQuery) {
      const regex = /\d+/;

      const searchItems = fullList.filter((item) => {
        if (regex.test(searchQuery.substring(0, 1))) {
          return item.NAME.toString().includes(searchQuery);
        } else {
          return item.NAME.toLowerCase().includes(searchQuery.toLowerCase());
        }
      });

      setRows(searchItems);
    } else {
      setRows(fullList);
    }
  }, [searchQuery]);

  const dispatch = useDispatch();

  const getData = async () => {
    dispatch(setSpinnerLoading("Loading bill"));
    const data = {
      table_prefix: prefix,
    };
    CallAPIPromise("/api/billing/listHoldBills", data, token, dispatch)
      .then((p) => {
        if (!p.data.error) {
          let list = [];
          const data = p.data.response;

          for (let i = 0; i < data.length; i++) {
            list.push({
              ...data[i],
              id: i + 1,
            });
          }
          setFullList(list);
          setRows(list);
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response.data.name === "TokenExpiredError") {
          dispatch(setSessionExpired(true));
          console.log(error.response);
        }
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
      });
  };

  useEffect(() => {
    if (status) {
      getData();
    }
  }, [status]);

  const width = useSelector(selectWidth);

  return (
    <>
      <div className="bg-white">
        <MasterHelp
          status={status}
          style={style}
          onClose={() => {
            setSearchQuery("");
            onClose();
            setRows([]);
          }}
          title={"Hold Bill | Help"}
          columns={width > 700 ? columns : mobileColumns}
          list={rows}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          onRowClick={(p) => {
            setSearchQuery("");

            onRowClick(p);
            setRows([]);
          }}
        />
      </div>
    </>
  );
};

export default PendingBillHelp;
