import moment from "moment";
import {
  setAlertWithTitle,
  setSpinnerLoading,
} from "../../utility/Redux/modal";
import CallAPI from "../../utility/functions/getData";
import { DownloadFile, UploadFile } from "../../utility/functions/firebase";
import { setItemList } from "../../utility/Redux/list";
import uniqueId from "uniqid";
import { CallAPIPromise } from "../../billing/common";

export const OnSubmit = async (
  data,
  token,
  dispatch,
  formRef,
  setdata,
  capturedImage,
  selectedFile,
  setSelectedFile,
  setCapturedImage,
  setFirebaseUrl,
  setRows,
  rows,
  setItemMasterStatus,
  getData,
  customerFirebasePic,
  uploadImgList,
  setUploadImgList
) => {
  let blob = "";
  if (customerFirebasePic?.includes("pexels")) {
    const response = await fetch(customerFirebasePic);
    blob = await response.blob();
  }

  if (data.ITEMNAME) {
    if (data.SECTION_NAME) {
      if (data.SUB_SEC_NAME) {
        if (data.DEP_NAME) {
          if (data.HSN_CODE) {
            const itemData = {
              table_prefix: data.table_prefix,
              ICODE: data.ICODE,
              ALIAS_CODE: data.ALIAS_CODE ? data.ALIAS_CODE : "0",
              HEAD_CODE: data.HEAD_CODE ? data.HEAD_CODE : "0",
              ITEMNAME: data.ITEMNAME,
              ITEM_DESC: data.ITEM_DESC,
              SECTION_NAME: data.SECTION_NAME,
              SUB_SEC_NAME: data.SUB_SEC_NAME,
              UNIT: data.UNIT ? data.UNIT : "KG",
              PACKING: data.PACKING,
              BRAND_NAME: data.BRAND_NAME,
              PUR_PRICE: data.PUR_PRICE ? data.PUR_PRICE : 0,
              SALE_PRICE: data.SALE_PRICE ? data.SALE_PRICE : "0",
              MRP_RATE: data.MRP_RATE ? data.MRP_RATE : 0,
              MIN_STOCK: data.MIN_STOCK ? data.MIN_STOCK : "0",
              MAX_STOCK: data.MAX_STOCK ? data.MAX_STOCK : "0",
              TAX1_RATE: data.TAX1_RATE,
              TAX2_RATE: data.TAX2_RATE,
              TAX3_RATE: data.TAX3_RATE,
              OPBAL: data.OPBAL ? data.OPBAL : "0",
              OPBAL_DATE: data.OPBAL_DATE
                ? moment(data.OPBAL_DATE).format("YYYY-MM-DD")
                : moment().format("YYYY-MM-DD"),
              MARK_RATE: data.MARK_RATE
                ? isFinite(Number(data.MARK_RATE))
                  ? data.MARK_RATE
                  : "0"
                : "0",
              HSN_CODE: data.HSN_CODE,
              DISQ_YN: data.DISQ_YN ? data.DISQ_YN : "YES",
              DISQ_RATE: data.DISQ_RATE ? data.DISQ_RATE : "0",
              STOP_YN: data.STOP_YN ? data.STOP_YN : "NO",
              STOCKYN: data.STOCKYN,
              CESS_RATE: data.CESS_RATE ? data.CESS_RATE : "0",
              OPBAL_RATE: data.OPBAL_RATE ? data.OPBAL_RATE : "0",
              OPBAL_AMOUNT: data.OPBAL_AMOUNT ? data.OPBAL_AMOUNT : "0",
              PRINT_ORDER_SLIP: data.PRINT_ORDER_SLIP
                ? data.PRINT_ORDER_SLIP
                : "No",
              WHOLE_SALE_PRICE: data.WHOLE_SALE_PRICE
                ? data.WHOLE_SALE_PRICE
                : "0",
              GIFT_PACK: data.GIFT_PACK ? data.GIFT_PACK : "No",
              DEP_NAME: data.DEP_NAME,
              NON_MOVING: data.NON_MOVING ? data.NON_MOVING : "NO",
              ITEM_TYPE: data.ITEM_TYPE,
              AISLE: data.AISLE ? data.AISLE : "",
              SHELF: data.SHELF ? data.SHELF : "",
              BAY: data.BAY ? data.BAY : "",
              SUPPLIER_1: data.SUPPLIER_1 ? data.SUPPLIER_1 : "",
              SUPPLIER_2: data.SUPPLIER_2 ? data.SUPPLIER_2 : "",
              SUPPLIER_3: data.SUPPLIER_3 ? data.SUPPLIER_3 : "",
              ITEM_AVAILABILITY: data.ITEM_AVAILABILITY
                ? data.ITEM_AVAILABILITY
                : "Online & InStore",
            };

            dispatch(setSpinnerLoading(data.ICODE ? "Updating" : "Saving"));
            CallAPI(
              "/api/master/saveItemMaster",
              itemData,
              token,
              async (e) => {
                if (e.status === 200) {
                  const iCode = data.ICODE
                    ? data.ICODE
                    : e.data.result.insertId;
                  dispatch(
                    setAlertWithTitle({
                      title: `Item Code - ${iCode}`,
                      msg: e.data.message,
                      lottie: "success",
                    })
                  );

                  let firebaseImgLink = [];

                  for (let index = 0; index < uploadImgList.length; index++) {
                    const i = uploadImgList[index];

                    if (i) {
                      const name =
                        index === 0
                          ? data.table_prefix.replace(/_.+_/, "_") + iCode
                          : data.table_prefix.replace(/_.+_/, "_") +
                            iCode +
                            `_${index + 1}`;
                      const path = "retailx/items/";

                      try {
                        // Upload the file
                        await UploadFile(i, path, name);
                        // Get the download URL
                        const url = await DownloadFile(path + name);
                        firebaseImgLink[index] = url; // Update the corresponding index
                      } catch (error) {
                        console.error(
                          `Error uploading image at index ${index}:`,
                          error
                        );
                      }
                    } else {
                      console.log(
                        `Image at index ${index} is not available or upload canceled.`
                      );
                    }
                  }

                  // Once all images are processed, update state and backend
                  updateItemImages(
                    firebaseImgLink,
                    data.table_prefix,
                    data.ICODE,
                    token,
                    dispatch
                  );

                  setSelectedFile("");
                  if (setUploadImgList) {
                    setUploadImgList([]);
                  }
                  setCapturedImage("");
                  setFirebaseUrl("");
                  dispatch(setItemList([]));
                  setdata((e) => {
                    return {
                      ...e,
                      ICODE: "",
                      ALIAS_CODE: "",
                      HEAD_CODE: "",
                      ITEMNAME: "",
                      ITEM_DESC: "",
                      UNIT: "",
                      PACKING: "",
                      BRAND_NAME: "",
                      PUR_PRICE: "",
                      SALE_PRICE: "",
                      MRP_RATE: "",
                      MIN_STOCK: "",
                      MAX_STOCK: "",
                      OPBAL: "",
                      OPBAL_DATE: moment().format("YYYY-MM-DD"),
                      MARK_RATE: "",
                      DISQ_YN: "",
                      DISQ_RATE: "",
                      STOP_YN: "",
                      STOCKYN: "",
                      CESS_RATE: "",
                      OPBAL_RATE: "",
                      OPBAL_AMOUNT: "",
                      PRINT_ORDER_SLIP: "No",
                      WHOLE_SALE_PRICE: "",
                      GIFT_PACK: "No",
                      NON_MOVING: "",
                      ITEM_TYPE: "",
                      AISLE: "",
                      SHELF: "",
                      BAY: "",
                      IMAGE_1: "",
                      IMAGE_2: "",
                      IMAGE_3: "",
                      IMAGE_4: "",
                    };
                  });
                  getData();
                  const newICode = data.ICODE
                    ? data.ICODE
                    : e.data.result.insertId;
                  if (rows) {
                    const filteredRows = rows.filter(
                      (row) => row.ITEMNAME !== ""
                    );

                    const diq_amt = itemData.DISQ_RATE
                      ? (parseFloat(1) *
                          parseFloat(itemData.PUR_PRICE) *
                          itemData.DISQ_RATE) /
                        100
                      : "0";
                    const tax1Amt =
                      ((itemData.PUR_PRICE - diq_amt) * itemData.TAX1_RATE) /
                      100;
                    const tax2Amt =
                      ((1 * itemData.PUR_PRICE - diq_amt) *
                        itemData.TAX2_RATE) /
                      100;
                    const netAmount =
                      parseFloat(1) * parseFloat(itemData.PUR_PRICE) -
                      diq_amt +
                      parseFloat(tax1Amt) +
                      parseFloat(tax2Amt);

                    setRows([
                      ...filteredRows,
                      {
                        id: uniqueId(),
                        INVNO: "",
                        DATE: moment().format("YYYY-MM-DD"),
                        PUR_BILL_NO: "",
                        PUR_BILL_DATE: moment().format("YYYY-MM-DD"),
                        PUR_BILL_AMOUNT: "0",
                        ENT_TIME: moment().format("YYYY-MM-DD"),
                        BILL_MODE: rows[0].BILL_MODE,
                        BILL_TYPE: rows[0].BILL_TYPE,
                        ACCOUNT_NO: "",
                        NAME: "",
                        ADDRESS: "",
                        MOBILE: "",
                        TAX_NUMBER: "",
                        ITEMNAME: itemData.ITEMNAME,
                        PACKING: itemData.PACKING,
                        UNIT: itemData.UNIT,
                        SECTION_NAME: itemData.SECTION_NAME,
                        SUB_SEC_NAME: itemData.SUB_SEC_NAME,
                        BRAND_NAME: itemData.BRAND_NAME,
                        PUR_PRICE: itemData.PUR_PRICE,
                        MRP_RATE: itemData.MRP_RATE,
                        SALE_PRICE: itemData.SALE_PRICE,
                        QNTY: "1",
                        AMOUNT: itemData.PUR_PRICE,
                        DISQ_RT: itemData.DISQ_RATE,
                        DISQ_AMT: diq_amt ? diq_amt : 0,
                        TAX1_RATE: itemData.TAX1_RATE,
                        TAX1_AMT: tax1Amt ? tax1Amt : 0,
                        TAX2_RATE: itemData.TAX2_RATE,
                        TAX2_AMT: tax2Amt ? tax2Amt : 0,
                        TAX3_RATE: itemData.TAX3_RATE,
                        TAX3_AMT: 0,
                        NET_AMT: netAmount,
                        BASIC_RATE: itemData.BASIC_RATE
                          ? itemData.BASIC_RATE
                          : 0,
                        BASIC_AMT: itemData.BASIC_AMT ? itemData.BASIC_AMT : 0,
                        TOTAL: "0",
                        FREIGHT: itemData.FREIGHT,
                        REMARKS: "",
                        ROUND_OFF: "0",
                        GTOTAL: "0",
                        HSN_CODE: itemData.HSN_CODE,
                        GR_NO: "0",
                        PO_NO: "0",
                        INCL_EXCL: "Excluded",
                        CESS_RATE: "0",
                        CESS_AMOUNT: "0",
                        TCS_RATE: "0",
                        TCS_AMOUNT: "0",
                        ADDITIONAL_CESS: "0",
                        VAT_AMOUNT: "0",
                        SURCHARGE_RATE: "0",
                        SURCHARGE_AMT: "0.00",
                        VAT_RATE: "0",
                        SCHEME_RATE: "0",
                        SCHEME_AMOUNT: "0.00",
                        TOTAL_SCHEME_AMOUNT: "0.00",
                        ADD_CHARGES: "0.00",
                        LESS_CHARGES: "0.00",
                        INCL_TAX: "No",
                        DEP_NAME: itemData.DEP_NAME,
                        PURCHASE_PIC: itemData.PURCHASE_PIC
                          ? itemData.PURCHASE_PIC
                          : null,
                        EXP_DATE: moment().format("YYYY-MM-DD"),
                        BATCH: "",
                        ICODE: newICode,
                      },
                    ]);
                    setItemMasterStatus(false);
                  }
                } else {
                  dispatch(
                    setAlertWithTitle({
                      title: "Error",
                      msg: "Something went wrong",
                    })
                  );
                }
              },
              dispatch,
              () => {
                dispatch(setSpinnerLoading(false));
              }
            );
          } else {
            const inputFields =
              formRef.current.querySelectorAll("input, select");
            inputFields.forEach((element) => {
              if (element.id === "HSN_CODE") {
                element.focus();
              }
            });
            dispatch(
              setAlertWithTitle({
                title: "Alert",
                msg: "Please select HSN Code",
              })
            );
          }
        } else {
          const inputFields = formRef.current.querySelectorAll("input, select");
          inputFields.forEach((element) => {
            if (element.id === "DEP_NAME") {
              element.focus();
            }
          });
          dispatch(
            setAlertWithTitle({
              title: "Alert",
              msg: "Enter Departement Name",
            })
          );
        }
      } else {
        const inputFields = formRef.current.querySelectorAll("input, select");
        inputFields.forEach((element) => {
          if (element.id === "SUB_SEC_NAME") {
            element.focus();
          }
        });
        dispatch(
          setAlertWithTitle({
            title: "Alert",
            msg: "Enter Sub. Section Name",
          })
        );
      }
    } else {
      const inputFields = formRef.current.querySelectorAll("input, select");
      inputFields.forEach((element) => {
        if (element.id === "SECTION_NAME") {
          element.focus();
        }
      });
      dispatch(
        setAlertWithTitle({
          title: "Alert",
          msg: "Enter Section Name",
        })
      );
    }
  } else {
    const inputFields = formRef.current.querySelectorAll("input, select");
    inputFields.forEach((element) => {
      if (element.id === "ITEMNAME") {
        element.focus();
      }
    });
    dispatch(
      setAlertWithTitle({
        title: "Alert",
        msg: "Enter Item Name",
      })
    );
  }
};

export const updateItemImages = (imageList, prefix, ICODE, token, dispatch) => {
  return new Promise((resolve, reject) => {
    if (imageList) {
      CallAPIPromise(
        "/api/master/updateItemImages",
        {
          table_prefix: prefix,
          ICODE: ICODE,
          IMAGE_1: imageList[0] ? imageList[0] : "",
          IMAGE_2: imageList[1] ? imageList[1] : "",
          IMAGE_3: imageList[2] ? imageList[2] : "",
          IMAGE_4: imageList[3] ? imageList[3] : "",
        },
        token,
        dispatch
      )
        .then((res) => {
          resolve(res);
        })
        .catch(() => {
          reject();
        });
    }
  });
};
