import { useDispatch, useSelector } from "react-redux";
import { setSessionExpired, setSpinnerLoading } from "../utility/Redux/modal";
import { useState } from "react";
import { selectToken } from "../utility/Redux/security";
import { useEffect } from "react";
import { selectPrefix, selectWidth } from "../utility/Redux/profile";
import MasterHelp from "../utility/component/modals/masterHelp";
import { useNavigate } from "react-router-dom";
import { CallAPIPromise } from "../billing/common";

const ExpenseHelp = ({ onRowClick, status, onClose }) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    height: "80%",
    width: "auto",
    padding: "2px",
    borderRadius: "8px",
    // Change the shadow color
    overflow: "hidden",
    backgroundColor: "white",
  };

  const [rows, setRows] = useState([]);
  const token = useSelector(selectToken);
  const navigate = useNavigate();
  const prefix = useSelector(selectPrefix);

  const width = useSelector(selectWidth);
  const columns = [
    {
      field: "EXPENSE_NAME",
      renderHeader: (params) => <div>Expense Name</div>,
      width:
        width <= 768 ? 300 : width <= 1024 ? 300 : width <= 1440 ? 300 : 400,
      headerAlign: "center",
      align: "left",
    },
  ];

  const [searchQuery, setSearchQuery] = useState("");
  const [fullList, setFullList] = useState([]);

  useEffect(() => {
    if (searchQuery) {
      const regex = /\d+/;

      const searchItems = fullList.filter((item) => {
        if (regex.test(searchQuery.substring(0, 1))) {
          return item.EXPENSE_NAME.toString().includes(searchQuery);
        } else {
          return item.EXPENSE_NAME.toLowerCase().includes(
            searchQuery.toLowerCase()
          );
        }
      });

      setRows(searchItems);
    } else {
      setRows(fullList);
    }
  }, [searchQuery]);

  const dispatch = useDispatch();

  const getData = async () => {
    dispatch(setSpinnerLoading("Loading"));
    const data = {
      table_prefix: prefix,
    };
    CallAPIPromise("/api/master/listExpenseMaster", data, token, dispatch)
      .then((p) => {
        if (!p.data.error) {
          let list = [];
          const data = p.data.response;
          for (let i = 0; i < data.length; i++) {
            list.push({
              ...data[i],
              id: i + 1,
            });
          }
          setFullList(list);
          setRows(list);
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response.data.name === "TokenExpiredError") {
          dispatch(setSessionExpired(true));
          console.log(error.response);
        }
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
      });
  };

  useEffect(() => {
    if (status) {
      getData();
    }
  }, [status]);

  return (
    <>
      <div className="bg-white">
        <MasterHelp
          status={status}
          style={style}
          onClose={() => {
            onClose();
            setRows([]);
            setSearchQuery("");
          }}
          title={"Expense Master | Help"}
          columns={columns}
          list={rows}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          onRowClick={(p) => {
            onRowClick(p);
            setRows([]);
          }}
        />
      </div>
    </>
  );
};

export default ExpenseHelp;
