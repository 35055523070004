import React, { useEffect } from "react";
import {
  AppBar,
  Drawer,
  DrawerHeader,
} from "../../../utility/component/compHome";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import MenuIcon from "@mui/icons-material/Menu";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Close, Logout } from "@mui/icons-material";
import goLogo from "../../../utility/images/go_logo.svg";
import {
  selectFullScreen,
  selectUserName,
  selectUserType,
  selectWidth,
  setFullScreen,
} from "../../Redux/profile";
import { useDispatch, useSelector } from "react-redux";
import { setCustomeTwoButtomAlert } from "../../../utility/Redux/modal";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import RoutbillingHomeLogo from "./homeLogo";
import { TabBarBgColor } from "../../constant";

const AppBarDesign = () => {
  const [MobileMenu, setMobileMenu] = useState(false);
  const location = useLocation();
  const [selectedMenu, setSelectedMenu] = React.useState("Dashboard");
  const navigate = useNavigate();
  const userType = useSelector(selectUserType);
  const userName = useSelector(selectUserName);
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const isFullscreen = useSelector(selectFullScreen);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const requestFullscreen = () => {
    const element = document.documentElement;

    if (element.requestFullscreen) {
      element.requestFullscreen();
      dispatch(setFullScreen(true));
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
      dispatch(setFullScreen(true));
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen();
      dispatch(setFullScreen(true));
    }
  };

  const exitFullscreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
      dispatch(setFullScreen(false));
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
      dispatch(setFullScreen(false));
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
      dispatch(setFullScreen(false));
    }
  };

  const windowSize = useSelector(selectWidth);

  const menuDrawer =
    userType === "MANAGER"
      ? windowSize < 768
        ? ["Dashboard", "Billing", "Masters", "Reports", "Updation"]
        : [
            "Dashboard",
            "Orders",
            "Billing",
            "Masters",
            "Reports",
            "Purchase",
            "Issue",
            "Updation",
          ]
      : userType === "USER"
      ? ["Dashboard", "Orders", "Billing", "Reports", "Updation"]
      : userType === "STORE"
      ? windowSize < 768
        ? ["Dashboard", "Billing", "Masters", "Reports"]
        : ["Dashboard", "Billing", "Masters", "Reports", "Purchase", "Issue"]
      : userType === "ADMIN"
      ? ["Dashboard", "Company Master", "User Master", "Reports"]
      : [];
  return (
    <div>
      <AppBar
        elevation={2}
        position="fixed"
        open={open}
        style={{
          height: 50,
          justifyItems: "start",
          backgroundColor: TabBarBgColor,
        }}
      >
        <Toolbar
          style={{ height: "100%", justifyItems: "start" }}
          className={" -mt-[3px] t:-mt-[7px] ll:-mt-[6px] "}
        >
          {window.innerWidth <= 600 ? (
            <MenuIcon
              sx={{ marginRight: 1 }}
              onClick={() => setMobileMenu(!MobileMenu)}
            />
          ) : location.pathname === "/" ? (
            <MenuIcon
              color="inherit "
              aria-label="open drawer"
              edge="start"
              sx={{
                marginRight: 1,
                marginLeft: -1,

                ...(open && { display: "none" }),
              }}
              onClick={handleDrawerOpen}
            />
          ) : (
            <MenuIcon
              color="inherit"
              aria-label="open drawer"
              edge="start"
              sx={{
                marginRight: 1,
                marginLeft: -1,

                ...(open && { display: "none" }),
              }}
              onClick={handleDrawerOpen}
            />
          )}

          <div className="   flex grow justify-between items-center  w-full     ">
            <div className="flex ">
              <div className={`flex items-center  ${open && "-ml-3"}   `}>
                <RoutbillingHomeLogo />
              </div>

              <div className="ms:hidden mm:flex"></div>
            </div>

            <div
              style={{ fontSize: 14 }}
              className={"tracking-[.1em]    flex items-center ml-4  "}
            >
              {" "}
              <div className="mr-2 text-white">
                {!isOnline && "Internet Not Availval"}
              </div>
              <button
                data-tooltip-place="bottom"
                data-tooltip-id="tip2"
                data-tooltip-offset={25}
                data-tooltip-content={"Full Screen"}
                onClick={!isFullscreen ? requestFullscreen : exitFullscreen}
                className="mr-2 hidden md:flex items-center"
              >
                {" "}
                <i className="bi bi-fullscreen flex items-center text-orange"></i>
              </button>{" "}
              <div
                data-tooltip-place="bottom"
                data-tooltip-id="tip2"
                data-tooltip-offset={25}
                data-tooltip-content={userType}
                className="pl-2 w-[100px] t:w-fit text-right  capitalize overflow-ellipsis overflow-hidden whitespace-nowrap  "
              >
                {userName}
              </div>{" "}
              <div
                className={`flex items-center ml-2 ${
                  userType === "MANAGER"
                    ? " text-[#2bf403e5] "
                    : userType === "ADMIN"
                    ? "text-orange "
                    : "text-[#03a9f4] "
                } `}
              >
                <button
                  onClick={() => {
                    dispatch(setCustomeTwoButtomAlert(true));
                  }}
                  data-tooltip-place="bottom"
                  data-tooltip-id="tip3"
                  data-tooltip-class-name="bg-red-500"
                  data-tooltip-offset={25}
                  data-tooltip-content="Logout"
                >
                  <i
                    style={{ fontSize: 20 }}
                    className={`${
                      userType === "ADMIN"
                        ? "bi bi-person-gear"
                        : "bi bi-person-circle "
                    } flex items-center -mt-[2px] ll:mt-0`}
                  ></i>
                </button>
              </div>
            </div>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        open={open}
        className={`${
          MobileMenu ? " p:flex absolute  " : "ms:hidden p:flex absolute "
        } `}
      >
        <div
          style={{
            backgroundColor: TabBarBgColor,
          }}
          className="  "
        >
          {open && (
            <DrawerHeader className=" justify-start items-start h-full mt-2   ">
              <div className="flex w-full justify-center ml-[24px]  ">
                <div className="flex flex-col items-center ">
                  <img alt="" src={goLogo} className="h-auto  w-36 " />
                </div>
              </div>
              <div
                style={{
                  zIndex: 100,
                  marginRight: "13px",
                }}
                className="  h-full  items-start "
              >
                {" "}
                <Close color="white" onClick={() => setOpen(false)} />
              </div>
            </DrawerHeader>
          )}
        </div>

        <div
          style={{
            backgroundColor: TabBarBgColor,
          }}
          className={`${open ? " pt-4" : " pt-10 "} `}
        >
          <List
            sx={{
              width: "100%",
            }}
          >
            {menuDrawer.map((text, index) => (
              <ListItem
                sx={{
                  width: "100%",
                }}
                onClick={() => {
                  setOpen(false);
                  if (text === "Dashboard") {
                    navigate("/");
                  }
                  if (text === "Masters") {
                    navigate("masters");
                  }
                  if (text === "Billing") {
                    navigate("billing");
                  }
                  if (text === "Reports") {
                    navigate("/reports");
                  }
                  if (text === "Summary") {
                    navigate("/summary");
                  }
                  if (text === "Updation") {
                    navigate("/updation");
                  }
                  if (text === "Issue Stock") {
                    navigate("/issue_stock");
                  }
                  if (text === "Manufacturing") {
                    navigate("/Mfg");
                  }
                  if (text === "Company Master") {
                    navigate("/company_master");
                  }
                  if (text === "User Master") {
                    navigate("/user_master");
                  }
                  if (text === "User List") {
                    navigate("/user_list");
                  }
                  if (text === "Setting") {
                    navigate("/settings");
                  }
                  if (text === "Purchase") {
                    navigate("/purchase");
                  }
                  if (text === "Orders") {
                    navigate("/order_dashboard");
                  }
                  if (text === "Issue") {
                    navigate("/issue");
                  }
                  if (text === "Test") {
                    navigate("/test");
                  }
                  setSelectedMenu(text);
                  setMobileMenu(false);
                }}
                key={index}
                disablePadding
              >
                <div
                  className={`${
                    !open && selectedMenu === text
                      ? " bg-[#2e2d2d] "
                      : !open && "hover:bg-[#2e2d2d]"
                  } w-full  cursor-pointer`}
                  style={
                    selectedMenu === text && open
                      ? {
                          color: "white",
                          display: "flex",
                          paddingLeft: "10px",
                          paddingRight: "10px",
                          paddingTop: "1px",
                          backgroundColor: TabBarBgColor,
                          flexDirection: "row",
                          alignItems: "center",
                        }
                      : {
                          color: "#f2f4f7",
                          paddingLeft: "10px",
                          paddingTop: "1px",

                          paddingRight: "10px",

                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }
                  }
                >
                  <div
                    className={`flex ${open && "hover:bg-[#2e2d2d]"}  ${
                      selectedMenu === text && open && "bg-[#262626]"
                    } w-full p-2 pl-2 items-center rounded-sm`}
                  >
                    <div className="">
                      {text === "Dashboard" && (
                        <div
                          data-tooltip-place="right"
                          data-tooltip-id="tip2"
                          data-tooltip-content="Dashboard"
                          className="w-10  flex justify-start"
                        >
                          <i className="bi  text-[18px]  bi-columns-gap"></i>
                        </div>
                      )}
                      {text === "Masters" && (
                        <div
                          style={{
                            fontSize: "15px",
                          }}
                          data-tooltip-place="right"
                          data-tooltip-id="tip2"
                          data-tooltip-content="Masters"
                          className={`w-10   flex justify-start`}
                        >
                          MS
                        </div>
                      )}
                      {text === "Issue" && (
                        <div
                          style={{
                            fontSize: "15px",
                          }}
                          data-tooltip-place="right"
                          data-tooltip-id="tip2"
                          data-tooltip-content="Masters"
                          className={`w-10   flex justify-start`}
                        >
                          IS
                        </div>
                      )}
                      {text === "Orders" && (
                        <div
                          style={{
                            fontSize: "15px",
                          }}
                          data-tooltip-place="right"
                          data-tooltip-id="tip2"
                          data-tooltip-content={text}
                          className={`w-10   flex justify-start`}
                        >
                          OS
                        </div>
                      )}
                      {text === "Mobile" && (
                        <div
                          style={{
                            fontSize: "15px",
                          }}
                          data-tooltip-place="right"
                          data-tooltip-id="tip2"
                          data-tooltip-content="Masters"
                          className={`w-10   flex justify-start`}
                        >
                          MB
                        </div>
                      )}
                      {text === "Billing" && (
                        <div
                          style={{
                            fontSize: "15px",
                          }}
                          data-tooltip-place="right"
                          data-tooltip-id="tip2"
                          data-tooltip-content="Billing"
                          className={`w-10   flex justify-start`}
                        >
                          BG
                        </div>
                      )}
                      {text === "Reports" && (
                        <div
                          style={{
                            fontSize: "15px",
                          }}
                          data-tooltip-place="right"
                          data-tooltip-id="tip2"
                          data-tooltip-content={text}
                          className={`w-10   flex justify-start`}
                        >
                          RS
                        </div>
                      )}
                      {text === "Purchase" && (
                        <div
                          style={{
                            fontSize: "15px",
                          }}
                          data-tooltip-place="right"
                          data-tooltip-id="tip2"
                          data-tooltip-content={text}
                          className={`w-10   flex justify-start`}
                        >
                          PS
                        </div>
                      )}
                      {text === "Test" && (
                        <div
                          style={{
                            fontSize: "15px",
                          }}
                          data-tooltip-place="right"
                          data-tooltip-id="tip2"
                          data-tooltip-content={text}
                          className={`w-10   flex justify-start`}
                        >
                          TS
                        </div>
                      )}
                      {text === "Setting" && (
                        <div
                          style={{
                            fontSize: "15px",
                          }}
                          data-tooltip-place="right"
                          data-tooltip-id="tip2"
                          data-tooltip-content="Setting"
                          className={`w-10   flex justify-start`}
                        >
                          SS
                        </div>
                      )}
                      {text === "Manufacturing" && (
                        <div
                          style={{
                            fontSize: "15px",
                          }}
                          data-tooltip-place="right"
                          data-tooltip-id="tip2"
                          data-tooltip-content="Manufacturing"
                          className={`w-10   flex justify-start`}
                        >
                          MF
                        </div>
                      )}
                      {text === "Company Master" && (
                        <div
                          style={{
                            fontSize: "15px",
                          }}
                          data-tooltip-place="right"
                          data-tooltip-id="tip2"
                          data-tooltip-content="Company Master"
                          className={`w-10   flex justify-start`}
                        >
                          CM
                        </div>
                      )}
                      {text === "User Master" && (
                        <div
                          style={{
                            fontSize: "15px",
                          }}
                          data-tooltip-place="right"
                          data-tooltip-id="tip2"
                          data-tooltip-content="User Master"
                          className={`w-10   flex justify-start`}
                        >
                          UM
                        </div>
                      )}
                      {text === "User List" && (
                        <div
                          style={{
                            fontSize: "15px",
                          }}
                          data-tooltip-place="right"
                          data-tooltip-id="tip2"
                          data-tooltip-content="User List"
                          className={`w-10   flex justify-start`}
                        >
                          UL
                        </div>
                      )}
                      {text === "Issue Stock" && (
                        <div
                          style={{
                            fontSize: "15px",
                          }}
                          data-tooltip-place="right"
                          data-tooltip-id="tip2"
                          data-tooltip-content="Issue stock"
                          className={`w-10   flex justify-start`}
                        >
                          IS
                        </div>
                      )}

                      {text === "Updation" && (
                        <div
                          style={{
                            fontSize: "15px",
                          }}
                          data-tooltip-place="right"
                          data-tooltip-id="tip2"
                          data-tooltip-content="Updation"
                          className={`w-10   flex justify-start`}
                        >
                          UP
                        </div>
                      )}
                      {text === "Summary" && (
                        <div
                          style={{
                            fontSize: "15px",
                          }}
                          data-tooltip-place="right"
                          data-tooltip-id="tip2"
                          data-tooltip-content="Summary"
                          className={`w-10   flex justify-start`}
                        >
                          SU
                        </div>
                      )}
                    </div>

                    <div>{text}</div>
                  </div>
                </div>
              </ListItem>
            ))}
          </List>
        </div>

        <div
          style={{
            backgroundColor: TabBarBgColor,
          }}
          className="h-full  flex flex-col justify-end pb-0 items-center "
        >
          <div style={{ height: "0.5px" }} className=" bg-[#a9a9a9]  w-11/12" />

          {userType === "MANAGER" || userType === "ADMIN" ? (
            <ListItem disablePadding>
              <ListItemButton
                style={{ paddingLeft: 12 }}
                onClick={() => {
                  navigate("/settings");

                  setMobileMenu(false);
                }}
              >
                <ListItemIcon>
                  <i
                    className="bi bi-gear"
                    style={{ fontSize: 20, color: "#df7943" }}
                  ></i>
                </ListItemIcon>
                <ListItemText
                  primary="Settings"
                  className="text-white text-sm"
                  style={{ marginLeft: -18 }}
                  primaryTypographyProps={{
                    variant: "subtitle",
                  }}
                />
              </ListItemButton>
            </ListItem>
          ) : null}

          <ListItem disablePadding>
            <ListItemButton
              style={{ paddingLeft: 12 }}
              onClick={() => {
                dispatch(
                  setCustomeTwoButtomAlert({
                    status: true,
                  })
                );
                setMobileMenu(false);
              }}
            >
              <ListItemIcon
                onClick={() => {
                  dispatch(
                    setCustomeTwoButtomAlert({
                      status: true,
                    })
                  );
                  setMobileMenu(false);
                }}
              >
                <Logout style={{ fontSize: 20, color: "#03a9f4" }} />
              </ListItemIcon>
              <ListItemText
                primary="Logout"
                className="text-white text-sm"
                style={{ marginLeft: -18 }}
                primaryTypographyProps={{
                  variant: "subtitle",
                }}
              />
            </ListItemButton>
          </ListItem>
        </div>
      </Drawer>
    </div>
  );
};

export default AppBarDesign;
