import React, { useEffect, useRef, useState } from "react";
import PartyDetails from "./partyDetails";
import Datagrid from "./datagrid";
import { useDispatch, useSelector } from "react-redux";
import {
  setAlertWithTitle,
  setSessionExpired,
  setSpinnerLoading,
} from "../utility/Redux/modal";
import { useFocus } from "../utility/hooks/hooks";
import { selectPrefix, selectWidth } from "../utility/Redux/profile";
import { selectToken } from "../utility/Redux/security";
import moment from "moment";
import CallAPI from "../utility/functions/getData";
import uniqueId from "uniqid";
import { useReactToPrint } from "react-to-print";
import { useNavigate } from "react-router-dom";
import AlertModalTwoButton from "../utility/component/modals/alertWithTwoButton";
import ItemMasterModal from "../masters/item-master/itemModal";
import PartyMasterCreaterModal from "../help/partyMasterCreaterModal";
import dataToLocal from "../utility/component/dataToLocal";
import deleteWholeDatabase from "../utility/functions/deleteWholeDatabase";
import IsLocalData from "../utility/component/isLocalData";
import BarcodeList from "./barCodeList";
import { CallAPIPromise } from "../billing/common";
import InvoiceTemplate from "../utility/component/datagrid/purcahsePrint";
import { PrintColumns } from "./printColumns";

const Issue = () => {
  const [itemMasterStatus, setItemMasterStatus] = useState(false);
  const muiTextBox = true;
  const [purchaseBillList, setPurcahseBillList] = useState([]);
  const [inputRef, setInputRefFoucs] = useFocus();
  const [partyNameRef, setPartyNameFocus] = useFocus();
  const [frieghtRef, setFrieghtFocus] = useFocus();
  const [barCodeListStatus, setBarCodeListStatus] = useState(false);
  const [barCodeList, setBarCodeList] = useState([]);
  const [partyMasterCreaterModalStatus, setPartyMasterCreaterModalStatus] =
    useState(false);
  const [refreshList, setRefreshList] = useState(false);
  const handleClose = () => {
    setItemMasterStatus(false);
  };
  const printBarRef = useRef();
  const BillModeOption = [
    {
      name: "Bill",
      value: "Bill",
    },
    { name: "Cash", value: "Cash" },
  ];
  const TaxTypeOption = [
    {
      name: "EXCLUSIVE",
      value: "EXCLUSIVE",
    },
    { name: "INCLUSIVE", value: "INCLUSIVE" },
  ];

  const BillTypeOption = [
    {
      name: "Retail Purchase",
      value: "Retail Purchase",
    },
    { name: "GST Purchase", value: "GST Purchase" },
  ];
  const [alertTwoButtonModal, setAlertTwoButtonModal] = useState({
    title: "",
    msg: "",
    status: false,
    setStatus: "",
    button1: "",
    button1Click: "",
    button2: "",
    button2Click: "",
  });
  const [partyDetails, setPartyDetails] = useState({
    BILL_MODE: BillModeOption[0].value,
    DATE: moment().format("YYYY-MM-DD"),
    PUR_BILL_DATE: moment().format("YYYY-MM-DD"),
    INVNO: "",
    BILL_TYPE: BillTypeOption[0].value,
    ACCOUNT_NO: "",
    NAME: "",
    MOBILE: "",
    ADDRESS: "",
    PUR_BILL_NO: "",
    PUR_BILL_AMOUNT: "0",
    TAX_NUMBER: "",
    TAX_TYPE: TaxTypeOption[0].value,
    REMARKS: "",
    FREIGHT: "0",
    ADDITIONAL_CESS: "0",
    ADD_CHARGES: "0",
    LESS_CHARGES: "0",
    SCHEME_AMOUNT: 0,
  });

  const [purchaseRef, setPurchaseBillNoFocus] = useFocus();
  const [purchaseAmtRef, setPurcahseAmtFocus] = useFocus();
  const [partyRef, setPartyFocus] = useFocus();
  const [currentINVNO, setCurrentINVNO] = useState("");
  const [rows, setRows] = useState([
    {
      id: uniqueId(),
      INVNO: "",
      DATE: moment().format("YYYY-MM-DD"),
      PUR_BILL_NO: "",
      PUR_BILL_DATE: moment().format("YYYY-MM-DD"),
      PUR_BILL_AMOUNT: "0",
      ENT_TIME: moment().format("YYYY-MM-DD"),
      BILL_MODE: BillModeOption[0].value,
      BILL_TYPE: BillTypeOption[0].value,
      ACCOUNT_NO: "",
      NAME: "",
      ADDRESS: "",
      MOBILE: "",
      TAX_NUMBER: "",
      ICODE: "",
      ITEMNAME: "",
      PACKING: "",
      UNIT: "",
      SECTION_NAME: "",
      SUB_SEC_NAME: "",
      BRAND_NAME: "",
      PUR_PRICE: "0",
      MRP_RATE: "0",
      SALE_PRICE: "0",
      QNTY: "0",
      AMOUNT: "0",
      DISQ_RT: "0",
      DISQ_AMT: "0",
      TAX1_RATE: "0",
      TAX1_AMT: "0",
      TAX2_RATE: "0",
      TAX2_AMT: "0",
      TAX3_RATE: "0",
      TAX3_AMT: "0.00",
      NET_AMT: "0.00",
      BASIC_RATE: "0.00",
      BASIC_AMT: "0.00",
      TOTAL: "0",
      FREIGHT: "0.00",
      REMARKS: "",
      ROUND_OFF: "0",
      GTOTAL: "0",
      HSN_CODE: "",
      GR_NO: "0",
      PO_NO: "0",
      INCL_EXCL: "Excluded",
      CESS_RATE: "0",
      CESS_AMOUNT: "0",
      TCS_RATE: "0",
      TCS_AMOUNT: "0",
      ADDITIONAL_CESS: "0",
      VAT_AMOUNT: "0",
      SURCHARGE_RATE: "0",
      SURCHARGE_AMT: "0.00",
      VAT_RATE: "0",
      SCHEME_RATE: "0",
      SCHEME_AMOUNT: "0.00",
      TOTAL_SCHEME_AMOUNT: "0.00",
      ADD_CHARGES: "0.00",
      LESS_CHARGES: "0.00",
      INCL_TAX: "No",
      DEP_NAME: "",
      PURCHASE_PIC: "",
      EXP_DATE: "",
      BATCH: "",
    },
  ]);
  const [accountMasterHelpStatus, setAccountMasterHelpStatus] = useState(false);
  const currentDate = moment().format("YYYY-MM-DD");

  const dispatch = useDispatch();
  const prefix = useSelector(selectPrefix);
  const token = useSelector(selectToken);

  const getBillData = async () => {
    dispatch(setSpinnerLoading("Loading"));
    const data = {
      table_prefix: prefix,
      PUR_BILL_NO: "",
      NAME: "",
    };
    CallAPIPromise("/api/purchase/listPurchaseBill", data, token, dispatch)
      .then((p) => {
        setPurcahseBillList(p.data.response);
      })
      .catch((error) => {
        console.log(error);
        if (error.response.data.name === "TokenExpiredError") {
          dispatch(setSessionExpired(true));
          console.log(error.response);
        }
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
      });
  };

  useEffect(() => {
    getBillData();
  }, []);

  const totalSum = rows.reduce((acc, row) => acc + Number(row.AMOUNT), 0);
  const vatTotal = rows.reduce((acc, row) => acc + Number(row.VAT_AMOUNT), 0);
  const cessTotal = rows.reduce((acc, row) => acc + Number(row.CESS_AMOUNT), 0);
  const cgstTotal = rows.reduce((acc, row) => acc + Number(row.TAX1_AMT), 0);
  const sgstTotal = rows.reduce((acc, row) => acc + Number(row.TAX2_AMT), 0);
  const tcsTotal = rows.reduce((acc, row) => acc + Number(row.TCS_AMOUNT), 0);
  const qntyTotal = rows.reduce((acc, row) => acc + Number(row.QNTY), 0);
  const disqTotal = rows.reduce((acc, row) => acc + Number(row.DISQ_AMT), 0);
  const igstTotal = rows.reduce((acc, row) => acc + Number(row.TAX3_AMT), 0);
  const surchargeTotal = rows.reduce(
    (acc, row) => acc + Number(row.SURCHARGE_AMT),
    0
  );
  const overollNetAmt_Row = rows.reduce(
    (acc, row) => acc + Number(row.NET_AMT),
    0
  );

  const grandTotal_unformated =
    overollNetAmt_Row +
    +Number(partyDetails.FREIGHT ? partyDetails.FREIGHT : 0) +
    +Number(partyDetails.ADD_CHARGES ? partyDetails.ADD_CHARGES : 0) +
    +Number(partyDetails.ADDITIONAL_CESS ? partyDetails.ADDITIONAL_CESS : 0) -
    (Number(partyDetails.LESS_CHARGES ? partyDetails.LESS_CHARGES : 0) +
      Number(partyDetails.SCHEME_AMOUNT ? partyDetails.SCHEME_AMOUNT : 0));

  let grandTotal = Math.floor(grandTotal_unformated).toFixed(2);

  const roundOff = grandTotal_unformated % 1;

  const [duplicatePurchaseBill, setDuplicatePurcahseBill] = useState(false);

  const savePurchaseBill = () => {
    const isDup = purchaseBillList.filter(
      (o) =>
        o.ACCOUNT_NO === partyDetails.ACCOUNT_NO &&
        o.PUR_BILL_NO === partyDetails.PUR_BILL_NO
    );
    if (!isDup[0] || partyDetails.INVNO) {
      const updatedPartyDetails = rows.map((item) => {
        return {
          ...item,
          NAME: partyDetails.NAME,
          SALE_PRICE: item.SALE_PRICE ? item.SALE_PRICE : 0,
          MOBILE: partyDetails.MOBILE,
          PUR_BILL_NO: partyDetails.PUR_BILL_NO,
          PUR_BILL_DATE: partyDetails.PUR_BILL_DATE,
          PUR_BILL_AMOUNT: partyDetails.PUR_BILL_AMOUNT,
          TAX_NUMBER: partyDetails.TAX_NUMBER,
          TAX_TYPE: partyDetails.TAX_TYPE,
          ACCOUNT_NO: partyDetails.ACCOUNT_NO,
          BILL_MODE: partyDetails.BILL_MODE,
          BILL_TYPE: partyDetails.BILL_TYPE,
          ADDRESS: partyDetails.ADDRESS,
          REMARKS: partyDetails.REMARKS,
          DATE: partyDetails.DATE,
          INVNO: partyDetails.INVNO ? partyDetails.INVNO : "",
          ADD_CHARGES: partyDetails.ADD_CHARGES
            ? partyDetails.ADD_CHARGES
            : "0",
          ADDITIONAL_CESS: partyDetails.ADDITIONAL_CESS
            ? partyDetails.ADDITIONAL_CESS
            : "0",
          FREIGHT: partyDetails.FREIGHT ? partyDetails.FREIGHT : "0",
          GTOTAL: grandTotal,
          TOTAL: totalSum.toFixed(2),
          SCHEME_AMOUNT: partyDetails.SCHEME_AMOUNT,
          LESS_CHARGES: partyDetails.LESS_CHARGES,
        };
      });

      const filterData = updatedPartyDetails.filter(
        (item) => item.ICODE !== "" && item.ITEMNAME !== ""
      );

      if (
        partyDetails.NAME &&
        partyDetails.PUR_BILL_NO &&
        filterData.every((row) => row.ITEMNAME) &&
        filterData.every((row) => row.QNTY) &&
        filterData[0] &&
        partyDetails.PUR_BILL_AMOUNT > 0
      ) {
        setAlertTwoButtonModal((o) => {
          return {
            ...o,
            status: false,
          };
        });
        if (Number(partyDetails.PUR_BILL_AMOUNT) !== Number(grandTotal)) {
          setAlertTwoButtonModal({
            title: "Alert",
            msg: "Grand Total and Purchase Amount not matched",
            status: true,
            button1: "Ok",
            button1Click: () => {
              setAlertTwoButtonModal((o) => {
                return {
                  ...o,
                  status: false,
                };
              });

              savePurchaseAfterCheck(
                prefix,
                filterData,
                partyDetails,
                dispatch,
                token,
                setCurrentINVNO,
                setAlertTwoButtonModal,
                clearState,
                setBarCodeListStatus,
                setBarCodeList,
                rows
              );
            },
          });
        } else {
          savePurchaseAfterCheck(
            prefix,
            filterData,
            partyDetails,
            dispatch,
            token,
            setCurrentINVNO,
            setAlertTwoButtonModal,
            clearState,
            setBarCodeListStatus,
            setBarCodeList,
            rows
          );
        }
      } else {
        if (!partyDetails.NAME) {
          dispatch(
            setAlertWithTitle({
              title: "Alert",
              msg: "Please Select Party.",
            })
          );
          setPartyFocus();
        } else {
          if (!filterData[0]) {
            setAlertTwoButtonModal((o) => {
              return {
                ...o,
                title: "Alert",
                status: true,
                msg: "Not found any data to save.",
                button1: "Ok",
                button1Click: () => {
                  delayedFunction(rows[0].id);
                  setAlertTwoButtonModal((e) => {
                    return {
                      ...e,
                      status: false,
                    };
                  });
                },
              };
            });
          } else {
            if (!partyDetails.PUR_BILL_NO) {
              setAlertTwoButtonModal({
                title: "Alert",
                msg: "Please Enter Purchase Bill Number.",
                status: true,
                button1: "Ok",
                button1Click: () => {
                  setAlertTwoButtonModal((o) => {
                    return {
                      ...o,
                      status: false,
                    };
                  });
                },
              });

              purchaseRef.current?.focus();
            } else {
              const itemsWithoutQuantity = filterData.filter(
                (row) => !row.QNTY
              );

              if (itemsWithoutQuantity.length > 0) {
                const itemNames = itemsWithoutQuantity
                  .map((item) => item.ITEMNAME)
                  .join(", ");
                setAlertTwoButtonModal({
                  title: "Alert",
                  msg: ` ${itemNames} item are missing quantity. Please enter quantity for that item. `,

                  status: true,
                  button1: "Ok",
                  button1Click: () => {
                    setAlertTwoButtonModal((o) => {
                      return {
                        ...o,
                        status: false,
                      };
                    });
                  },
                });
              } else {
                if (!filterData.every((row) => row.ITEMNAME)) {
                  setAlertTwoButtonModal({
                    title: "Alert",
                    msg: "Please select item for save records",

                    status: true,
                    button1: "Ok",
                    button1Click: () => {
                      setAlertTwoButtonModal((o) => {
                        return {
                          ...o,
                          status: false,
                        };
                      });
                    },
                  });
                } else {
                  if (!partyDetails.MOBILE) {
                    setAlertTwoButtonModal({
                      title: "Alert",
                      msg: "Please Enter Mobile.",

                      status: true,
                      button1: "Ok",
                      button1Click: () => {
                        setAlertTwoButtonModal((o) => {
                          return {
                            ...o,
                            status: false,
                          };
                        });
                      },
                    });
                  } else {
                    if (partyDetails.PUR_BILL_AMOUNT < 1) {
                      const amountRef =
                        document.getElementById("PUR_BILL_AMOUNT");
                      if (amountRef) {
                        amountRef.focus();
                      }
                      setAlertTwoButtonModal({
                        title: "Alert",
                        msg: "Please Enter Bill Amount",

                        status: true,
                        button1: "Ok",
                        button1Click: () => {
                          setAlertTwoButtonModal((o) => {
                            return {
                              ...o,
                              status: false,
                            };
                          });
                        },
                      });
                    }
                  }
                }
              }
            }
          }
        }
      }
    } else {
      dispatch(
        setAlertWithTitle({
          title: "Alert",
          msg: "This purchase bill number already exists for the selected party. Please enter a different purchase bill number.",
          horizontal: true,
        })
      );
    }
  };

  const delayedFunction = (id) => {
    setTimeout(() => {
      // Function to execute after 30 seconds
      const nearestInput = document.getElementById(id + "-" + "ICODE");
      if (nearestInput) {
        nearestInput.focus();
      }
    }, 200);
  };

  const loadPurchaseBill = async (purchaseBillNo, accountNo) => {
    CallAPIPromise(
      "/api/purchase/findPurchaseBill",
      {
        table_prefix: prefix,
        PUR_BILL_NO: purchaseBillNo,
        ACCOUNT_NO: accountNo,
      },
      token,
      dispatch
    )
      .then((p) => {
        const data = p.data.response;
        const res = data.map((h) => {
          return {
            ...h,
            DATE: moment(h.DATE).format("YYYY-MM-DD"),
            EXP_DATE: h.EXP_DATE ? moment(h.EXP_DATE).format("YYYY-MM-DD") : "",
            PUR_BILL_DATE: moment(h.PUR_BILL_DATE).format("YYYY-MM-DD"),
            id: uniqueId(),
            REMARKS: h.REMARKS,
            PUR_BILL_AMOUNT: h.PUR_BILL_AMOUNT,
            TAX_NUMBER: h.TAX_NUMBER,
            TAX_TYPE: h.TAX_TYPE,
            ACCOUNT_NO: h.ACCOUNT_NO,
            BILL_MODE: h.BILL_MODE,
            BILL_TYPE: h.BILL_TYPE,
            ADDRESS: h.ADDRESS,
            FREIGHT: h.FREIGHT,
            ADDITIONAL_CESS: h.ADDITIONAL_CESS,
            ADD_CHARGES: h.ADD_CHARGES,
          };
        });

        const partyDetailsData = res[0];
        const partyDetailsToUpdate = {
          DATE: partyDetailsData.DATE,
          PUR_BILL_DATE: partyDetailsData.PUR_BILL_DATE,
          INVNO: partyDetailsData.INVNO,
          BILL_TYPE: partyDetailsData.BILL_TYPE,
          ACCOUNT_NO: partyDetailsData.ACCOUNT_NO,
          NAME: partyDetailsData.NAME,
          MOBILE: partyDetailsData.MOBILE,
          ADDRESS: partyDetailsData.ADDRESS,
          PUR_BILL_NO: partyDetailsData.PUR_BILL_NO,
          PUR_BILL_AMOUNT: partyDetailsData.PUR_BILL_AMOUNT,
          TAX_NUMBER: partyDetailsData.TAX_NUMBER,
          TAX_TYPE: partyDetailsData.TAX_TYPE
            ? partyDetailsData.TAX_TYPE
            : "EXCLUSIVE",
          REMARKS: partyDetailsData.REMARKS,
          BILL_MODE: partyDetailsData.BILL_MODE
            ? partyDetailsData.BILL_MODE
            : "Bill",
          FREIGHT: partyDetailsData.FREIGHT,
          ADDITIONAL_CESS: partyDetailsData.ADDITIONAL_CESS,
          ADD_CHARGES: partyDetailsData.ADD_CHARGES,
          LESS_CHARGES: partyDetailsData.LESS_CHARGES,
          SCHEME_AMOUNT: partyDetailsData.SCHEME_AMOUNT,
        };
        setPartyDetails(partyDetailsToUpdate);
        setRows([
          ...res,
          {
            id: uniqueId(),
            INVNO: "",
            DATE: moment().format("YYYY-MM-DD"),
            PUR_BILL_NO: "",
            PUR_BILL_DATE: moment().format("YYYY-MM-DD"),
            PUR_BILL_AMOUNT: "0",
            ENT_TIME: moment().format("YYYY-MM-DD"),
            BILL_MODE: BillModeOption[0].value,
            BILL_TYPE: BillTypeOption[0].value,
            ACCOUNT_NO: "",
            NAME: "",
            ADDRESS: "",
            MOBILE: "",
            TAX_NUMBER: "",
            ICODE: "",
            ITEMNAME: "",
            PACKING: "",
            UNIT: "",
            SECTION_NAME: "",
            SUB_SEC_NAME: "",
            BRAND_NAME: "",
            PUR_PRICE: "0",
            MRP_RATE: "0",
            SALE_PRICE: "0",
            QNTY: "0",
            AMOUNT: "0",
            DISQ_RT: "0",
            DISQ_AMT: "0",
            TAX1_RATE: "0",
            TAX1_AMT: "0",
            TAX2_RATE: "0",
            TAX2_AMT: "0",
            TAX3_RATE: "0",
            TAX3_AMT: "0.00",
            NET_AMT: "0.00",
            BASIC_RATE: "0.00",
            BASIC_AMT: "0.00",
            TOTAL: "0",
            FREIGHT: "0.00",
            REMARKS: "",
            ROUND_OFF: "0",
            GTOTAL: "0",
            HSN_CODE: "",
            GR_NO: "0",
            PO_NO: "0",
            INCL_EXCL: "Excluded",
            CESS_RATE: "0",
            CESS_AMOUNT: "0",
            TCS_RATE: "0",
            TCS_AMOUNT: "0",
            ADDITIONAL_CESS: "0",
            VAT_AMOUNT: "0",
            SURCHARGE_RATE: "0",
            SURCHARGE_AMT: "0.00",
            VAT_RATE: "0",
            SCHEME_RATE: "0",
            SCHEME_AMOUNT: "0.00",
            TOTAL_SCHEME_AMOUNT: "0.00",
            ADD_CHARGES: "0.00",
            LESS_CHARGES: "0.00",
            INCL_TAX: "No",
            DEP_NAME: "",
            PURCHASE_PIC: "",
            EXP_DATE: "",
            BATCH: "",
          },
        ]);
      })
      .catch((error) => {
        console.log(error.response.data.name);
        if (error.response.data.name === "TokenExpiredError") {
          dispatch(setSessionExpired(true));
        }
      });
  };

  const loadPurchaseBillPromise = (purchaseBillNo, accountNo) => {
    return new Promise((resolve, reject) => {
      CallAPIPromise(
        "/api/purchase/findPurchaseBill",
        {
          table_prefix: prefix,
          PUR_BILL_NO: purchaseBillNo,
          ACCOUNT_NO: accountNo,
        },
        token,
        dispatch
      )
        .then((response) => {
          resolve(response.data.response);
        })
        .catch((error) => {
          console.log(error.response.data.name);
          if (error.response.data.name === "TokenExpiredError") {
            dispatch(setSessionExpired(true));
          }
          reject(error);
        });
    });
  };

  const clearState = () => {
    setRows([
      {
        id: uniqueId(),
        INVNO: "",
        DATE: moment().format("YYYY-MM-DD"),
        PUR_BILL_NO: "",
        PUR_BILL_DATE: moment().format("YYYY-MM-DD"),
        PUR_BILL_AMOUNT: "0",
        ENT_TIME: moment().format("YYYY-MM-DD"),
        BILL_MODE: BillModeOption[0].value,
        BILL_TYPE: BillTypeOption[0].value,
        ACCOUNT_NO: "",
        NAME: "",
        ADDRESS: "",
        MOBILE: "",
        TAX_NUMBER: "",
        ICODE: "",
        ITEMNAME: "",
        PACKING: "",
        UNIT: "",
        SECTION_NAME: "",
        SUB_SEC_NAME: "",
        BRAND_NAME: "",
        PUR_PRICE: "0",
        MRP_RATE: "0",
        SALE_PRICE: "0",
        QNTY: "0",
        AMOUNT: "0",
        DISQ_RT: "0",
        DISQ_AMT: "0",
        TAX1_RATE: "0",
        TAX1_AMT: "0",
        TAX2_RATE: "0",
        TAX2_AMT: "0",
        TAX3_RATE: "0",
        TAX3_AMT: "0.00",
        NET_AMT: "0.00",
        BASIC_RATE: "0.00",
        BASIC_AMT: "0.00",
        TOTAL: "0",
        FREIGHT: "0.00",
        REMARKS: "",
        ROUND_OFF: "0",
        GTOTAL: "0",
        HSN_CODE: "",
        GR_NO: "0",
        PO_NO: "0",
        INCL_EXCL: "Excluded",
        CESS_RATE: "0",
        CESS_AMOUNT: "0",
        TCS_RATE: "0",
        TCS_AMOUNT: "0",
        ADDITIONAL_CESS: "0",
        VAT_AMOUNT: "0",
        SURCHARGE_RATE: "0",
        SURCHARGE_AMT: "0.00",
        VAT_RATE: "0",
        SCHEME_RATE: "0",
        SCHEME_AMOUNT: "0.00",
        TOTAL_SCHEME_AMOUNT: "0.00",
        ADD_CHARGES: "0.00",
        LESS_CHARGES: "0.00",
        INCL_TAX: "No",
        DEP_NAME: "",
        PURCHASE_PIC: "",
        EXP_DATE: "",
        BATCH: "",
      },
    ]);

    setPartyDetails({
      BILL_MODE: BillModeOption[0].value,
      DATE: moment().format("YYYY-MM-DD"),
      PUR_BILL_DATE: moment().format("YYYY-MM-DD"),
      INVNO: "",
      BILL_TYPE: BillTypeOption[0].value,
      ACCOUNT_NO: "",
      NAME: "",
      MOBILE: "",
      ADDRESS: "",
      PUR_BILL_NO: "",
      PUR_BILL_AMOUNT: "0",
      TAX_NUMBER: "",
      TAX_TYPE: TaxTypeOption[0].value,
      REMARKS: "",
      FREIGHT: "0",
      ADDITIONAL_CESS: "0",
      ADD_CHARGES: "0",
      LESS_CHARGES: "0",
      SCHEME_AMOUNT: 0,
    });
    getBillData();
  };

  const navigate = useNavigate();
  const printRef = useRef();
  const thermalPrintRef = useRef();
  const [selectedPrintOption, setSelectedPrintOption] = useState("print");

  const handlePrint = useReactToPrint({
    pageStyle: `
    @page {
      size: portrait;
    }
  `,
    content: () =>
      selectedPrintOption === "print"
        ? printRef.current
        : thermalPrintRef.current,
    onAfterPrint: () => {
      // navigate("/purchase");
      // setAccountMasterHelpStatus(true);
      // setSelectedPrintOption("print");
    },
  });

  useEffect(() => {
    function handleKeyPress(event) {
      // Check if Ctrl or Alt key is pressed along with 'S'
      if ((event.ctrlKey || event.altKey) && event.key === "s") {
        event.preventDefault();
        savePurchaseBill();
      }
    }

    // Add event listener when component mounts
    window.addEventListener("keydown", handleKeyPress);

    // Remove event listener when component unmounts
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [partyDetails, rows]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.ctrlKey && event.key === "1") {
        event.preventDefault();

        setPartyNameFocus();
        // Add your logic here
      }

      if (event.ctrlKey && event.key === "2") {
        event.preventDefault();
        delayedFunction(rows[0].id);
        // Add your logic here
      }

      if (event.ctrlKey && event.key === "3") {
        event.preventDefault();

        setFrieghtFocus();
        // Add your logic here
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (rows[0]?.ICODE && rows[0]?.ITEMNAME) {
      deleteWholeDatabase("purchase").then(() => {
        dataToLocal("purchase", rows, [
          {
            ...partyDetails,
            ICODE: 1,
          },
        ]);
      });
    }
  }, [rows]);

  useEffect(() => {
    const callback = (details, data) => {
      if (data[0]) {
        setAlertTwoButtonModal({
          title: "Unsaved Data",
          msg: "We found an unsaved Purchase. Do you want to load it?",
          status: true,
          button1: "Yes",
          button2: "Cancel",
          button1Click: () => {
            setPartyDetails(details[0]);
            setRows(data);
            setAlertTwoButtonModal((o) => {
              return {
                ...o,
                status: false,
              };
            });
          },

          button2Click: () => {
            deleteWholeDatabase("purchase");
            setAlertTwoButtonModal((o) => {
              return {
                ...o,
                status: false,
              };
            });
          },
        });
      }
    };
    const elseFunc = () => {
      console.log("nothing");
    };
    IsLocalData("purchase", callback, elseFunc);
  }, []);
  const width = useSelector(selectWidth);
  const data = {
    sellerInfo: {
      name: partyDetails.NAME,
      address: partyDetails.ADDRESS,
      gstin: partyDetails.TAX_NUMBER,
      phone: partyDetails.MOBILE,
    },
    invoiceDetails: {
      invoiceNo: partyDetails.PUR_BILL_NO,
      date: partyDetails.PUR_BILL_DATE,
      destination: "",
      gstin: partyDetails.TAX_NUMBER,
    },
    columns: PrintColumns(width),
    items: [
      {
        description: "INTEL CORE I5",
        hsn: "8473",
        quantity: 1,
        unitRate: 17800.0,
        cgst: "9%",
        sgst: "9%",
        amount: 17800.0,
      },
      // Add other items here
    ],
    totals: {
      taxableAmount: "37,570.00",
      cgst: "2,865.51",
      sgst: "2,865.51",
      grandTotal: "37,570.00",
    },
  };
  return (
    <div className=" px-3 py-2 bg-[#f9fafc] h-full text-black text-sm overflow-y-auto">
      {/* <div className="font-bold" style={{ fontSize: "20px" }}>
        Purchase Bill Details
      </div> */}

      <PartyDetails
        currentDate={currentDate}
        BillModeOption={BillModeOption}
        TaxTypeOption={TaxTypeOption}
        BillTypeOption={BillTypeOption}
        partyDetails={partyDetails}
        setPartyDetails={setPartyDetails}
        duplicatePurchaseBill={duplicatePurchaseBill}
        setDuplicatePurcahseBill={setDuplicatePurcahseBill}
        dispatch={dispatch}
        rows={rows}
        refreshList={refreshList}
        partyNameRef={partyNameRef}
        muiTextBox={muiTextBox}
        setRows={setRows}
        accountMasterHelpStatus={accountMasterHelpStatus}
        setAccountMasterHelpStatus={setAccountMasterHelpStatus}
        loadPurchaseBill={loadPurchaseBill}
        loadPurchaseBillPromise={loadPurchaseBillPromise}
        purchaseRef={purchaseRef}
        partyRef={partyRef}
        purchaseAmtRef={purchaseAmtRef}
        setPurcahseAmtFocus={setPurcahseAmtFocus}
        setPurchaseBillNoFocus={setPurchaseBillNoFocus}
        purchaseBillList={purchaseBillList}
        setPartyFocus={setPartyFocus}
        setInputRefFoucs={setInputRefFoucs}
        delayedFunction={delayedFunction}
        setPartyMasterCreaterModalStatus={setPartyMasterCreaterModalStatus}
      />
      <Datagrid
        delayedFunction={delayedFunction}
        savePurchaseBill={savePurchaseBill}
        inputRef={inputRef}
        setBarCodeListStatus={setBarCodeListStatus}
        setBarCodeList={setBarCodeList}
        setItemMasterStatus={setItemMasterStatus}
        partyDetails={partyDetails}
        setPartyDetails={setPartyDetails}
        accountMasterHelpStatus={accountMasterHelpStatus}
        setAccountMasterHelpStatus={setAccountMasterHelpStatus}
        rows={rows}
        setRows={setRows}
        totalSum={totalSum}
        vatTotal={vatTotal}
        cessTotal={cessTotal}
        currentINVNO={currentINVNO}
        cgstTotal={cgstTotal}
        sgstTotal={sgstTotal}
        igstTotal={igstTotal}
        overollNetAmt_Row={overollNetAmt_Row}
        disqTotal={disqTotal}
        qntyTotal={qntyTotal}
        surchargeTotal={surchargeTotal}
        tcsTotal={tcsTotal}
        roundOff={roundOff}
        grandTotal={grandTotal}
        clearState={clearState}
        handlePrint={handlePrint}
        printRef={printRef}
        muiTextBox={muiTextBox}
        frieghtRef={frieghtRef}
        alertTwoButtonModal={alertTwoButtonModal}
        setAlertTwoButtonModal={setAlertTwoButtonModal}
      />
      <ItemMasterModal
        status={itemMasterStatus}
        handleClose={handleClose}
        setRows={setRows}
        rows={rows}
        setItemMasterStatus={setItemMasterStatus}
      />
      <AlertModalTwoButton
        details={alertTwoButtonModal}
        setAlertTwoButtonModal={setAlertTwoButtonModal}
      />
      <PartyMasterCreaterModal
        afterCreate={() => {
          setPartyMasterCreaterModalStatus(false);
          setRefreshList((o) => !o);
        }}
        status={partyMasterCreaterModalStatus}
        hidePartyList={true}
        onClose={() => {
          setPartyMasterCreaterModalStatus(false);
        }}
      />
      <BarcodeList
        status={barCodeListStatus}
        list={barCodeList}
        onClose={() => {
          setBarCodeListStatus(false);
        }}
        handleBillPrint={handlePrint}
        printBarRef={printBarRef}
      />
      <div
        style={{
          display: "none",
        }}
      >
        <div ref={printRef}>
          <InvoiceTemplate
            data={data}
            rows={rows.filter((row) => row.ITEMNAME !== "")}
          />
        </div>
      </div>
    </div>
  );
};

export default Issue;
function savePurchaseAfterCheck(
  prefix,
  filterData,
  partyDetails,
  dispatch,
  token,
  setCurrentINVNO,
  setAlertTwoButtonModal,
  clearState,
  setBarCodeListStatus,
  setBarCodeList,
  rows
) {
  const data = {
    table_prefix: prefix,
    cart_pur_item: filterData,
    NEW_MODIFY: filterData[0].INVNO ? "Modify" : "New",
  };
  {
    partyDetails.INVNO
      ? dispatch(setSpinnerLoading("Updating"))
      : dispatch(setSpinnerLoading("Saving"));
  }
  CallAPI(
    "/api/purchase/savePurchaseBill",
    data,
    token,
    (e) => {
      if (!e.data.error) {
        deleteWholeDatabase("purchase");
        setCurrentINVNO(e.data.INVNO);
        const invno = e.data.INVNO;
        setAlertTwoButtonModal((e) => {
          return {
            ...e,
            title: partyDetails.INVNO
              ? `Invoice - ${partyDetails.INVNO} `
              : `Invoice - ${invno}`,
            msg: "Data Saved",
            status: true,
            button1: "Ok",
            button1Bg: "#34b4eb",
            button1Click: () => {
              setAlertTwoButtonModal((e) => {
                return {
                  ...e,
                  status: false,
                };
              });
              setBarCodeListStatus(true);
              setBarCodeList(rows);
              // handlePrint();
              clearState();
            },
            // button2: "Cancel",
            // button2Click: () => {
            //   setAlertTwoButtonModal((e) => {
            //     return {
            //       ...e,
            //       status: false,
            //     };
            //   });
            //   clearState();
            //   setAccountMasterHelpStatus(true);
            // },
          };
        });
      } else {
        dispatch(
          setAlertWithTitle({
            title: "Alert",
            msg: "Something Went Wrong.",
          })
        );
      }
    },
    dispatch,
    () => {
      dispatch(setSpinnerLoading(false));
    }
  );
}
