import React, { useEffect, useState } from "react";

const RejectOrderModal = ({
  isOpen,
  onClose,
  setCustomReason,
  customReason,
  onSubmit,
  reason,
  setReason,
}) => {
  const predefinedReasons = [
    "Out of stock",
    "Invalid payment",
    "Technical issue",
    "Other",
  ];
  const [err, setErr] = useState("");

  const handleReasonChange = (e) => {
    setReason(e.target.value);
    if (e.target.value !== "Other") {
      setCustomReason(""); // Clear custom reason if not "Other"
    }
  };

  const handleCustomReasonChange = (e) => {
    setCustomReason(e.target.value);
  };

  const handleSubmit = () => {
    const finalReason = reason === "Other" ? customReason : reason;
    if (!finalReason) {
      alert("Please provide a reason for rejection.");
      return;
    }
    onSubmit(finalReason);
    onClose();
  };

  useEffect(() => {
    if (isOpen) {
      setReason("");
      setCustomReason("");
    }
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded shadow-lg w-96">
        <div className="flex justify-between mb-4 items-center ">
          <h2 className="text-[16px] font-bold ">Reject Order</h2>
          <button onClick={onClose} className="text-sm ">
            <i className="bi bi-x-lg flex items-center text-red-500"></i>
          </button>
        </div>

        <div className="mb-4">
          <label className="block mb-2 font-semibold">Select a reason:</label>
          <select
            className="w-full p-2 border rounded"
            value={reason}
            onChange={handleReasonChange}
          >
            <option value="" disabled>
              Select Reason
            </option>
            {predefinedReasons.map((r) => (
              <option key={r} value={r}>
                {r}
              </option>
            ))}
          </select>
        </div>
        {reason === "Other" && (
          <div className="mb-4">
            <label className="block mb-2 font-semibold">
              Specify your reason:
            </label>
            <textarea
              className="w-full p-2 border rounded"
              rows="3"
              value={customReason}
              onChange={handleCustomReasonChange}
            />
          </div>
        )}
        <div className="text-red-500">{err}</div>
        <div className="flex justify-end space-x-2">
          <button
            className="px-4 py-2 bg-gray-300 rounded hover:bg-gray-400"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
            onClick={() => {
              if (reason) {
                if (reason !== "Other") {
                  handleSubmit();
                } else {
                  if (customReason) {
                    handleSubmit();
                  } else {
                    setErr("write any message for reject");

                    setTimeout(() => {
                      setErr("");
                    }, 5000);
                  }
                }
              } else {
                setErr("Choose any reason");

                setTimeout(() => {
                  setErr("");
                }, 5000);
              }
            }}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default RejectOrderModal;
