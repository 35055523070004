import React, { useState, useEffect } from "react";
import { formatMobileNumber, toProperCase } from "../utility/component/format";
import moment from "moment";
import pickup from "../utility/json/pickup.json";
import delivery from "../utility/json/delivery.json";
import Lottie from "lottie-react";
import { FormatNumber2 } from "../utility/functions/formatNumber";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCompanyAddress,
  selectLatitude,
  selectLongitude,
  selectPrefix,
} from "../utility/Redux/profile";
import { selectToken } from "../utility/Redux/security";
import { useNavigate } from "react-router-dom";
import { child, get, onValue, ref } from "firebase/database";
import { database } from "../utility/functions/firebase";
import SwipeableEdgeDrawer from "../billing/drawer";
import { GetOrderSummary, LoadBill } from "../billing/function";
import AlertModalTwoButton from "../utility/component/modals/alertWithTwoButton";
import CallAPI from "../utility/functions/getData";
import { setAlertWithTitle, setSpinnerLoading } from "../utility/Redux/modal";
import SettingModal from "./settingModal";
import EmployeeHelp from "../help/employeeHelp";
import { CallAPIPromise } from "../billing/common";
import ActionRemarks from "../utility/component/modals/RemarksModal";
import RejectOrderModal from "../utility/component/rejecteOrderModal";
import { getMessageWallet } from "../utility/constant";

const buttonClasses = "px-4 py-2 rounded-lg";

const emptyCustomer = {
  CUSTOMER_NAME: "",
  CUSTOMER_ADDRESS: "",
  CUSTOMER_MOBILE: "",
  CUSTOMER_EMAIL: "",
  CUSTOMER_TYPE: "",
  CITY: "",
  COUNTRY: "",
  PIN_CODE: "",
  DISQ_RATE: "",
  LOYALTY_POINTS: 0,
  CUSTOMER_STATUS: "Active",
  PRV_BAL: 0,
  BAL_TYPE: "",
  CUSTOMER_CODE: "",
};
const emptyBillingDetails = {
  mobile: "",
  customerName: "",
  cashAmount: 0,
  cardAmount: 0,
  cardNo: "",
  eAmount: 0,
  btcAmount: 0,
  btcName: "",
  couponAmount: 0,
  couponNo: "",
};

const CartItem = ({ item }) => {
  const prefix = useSelector(selectPrefix);

  return (
    <div className="flex z-100  rounded-sm bg-[#d1d4c4] items-center mb-2 ">
      <div
        style={{
          borderRight: "1px solid #c3c7b5",
        }}
        className=" w-[70px] h-[70px] p-2 flex items-center justify-center"
      >
        <img
          src={`https://firebasestorage.googleapis.com/v0/b/retailx-126e1.appspot.com/o/retailx%2Fitems%2F${prefix}${
            item.ICODE
          }?alt=media&token=${"49cc9f81-1858-4029-a612-67d2c918e7ff"}`}
          alt={item.ITEMNAME}
          className="w-[100px] max-h-full rounded-sm"
          onError={(e) => {
            e.target.src = `https://firebasestorage.googleapis.com/v0/b/retailx-126e1.appspot.com/o/retailx%2Fitems%2F${"retailIcon.png"}?alt=media&token=${"49cc9f81-1858-4029-a612-67d2c918e7ff"}`;
          }}
        />
      </div>
      <div className="flex justify-between flex-1 items-center">
        <div className="  ml-3 max-w-[150px] text-[13px]  h-fit ">
          <div className="text-gray-700">{toProperCase(item.ITEMNAME)}</div>
          <div className="text-gray-400 lowercase">
            {" "}
            {item.PACKING} - {item.UNIT}
          </div>
          <div className="font-semibold"> ₹{item.SALE_PRICE}</div>
        </div>
        <div className="text-sm pr-2 flex items-center">
          <i className="bi bi-x flex items-center mt-1"></i>
          <div>{item.QNTY}</div>
        </div>
      </div>
    </div>
  );
};
const RecivedOrderDashboard = () => {
  const [summaryList, setSummaryList] = useState([]);
  const [employeeDetails, setEmployeeDetails] = useState([]);
  const [billOrderDetails, setBillOrderDetails] = useState([]);
  const [customerDetails, setCustomerDetails] = useState(emptyCustomer);
  const [billingDetails, setBillingDetails] = useState(emptyBillingDetails);
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const [fromDate, setFromDate] = useState(moment().format("YYYY-MM-DD"));
  const [toDate, setToDate] = useState(moment().format("YYYY-MM-DD"));
  const [settingModalStatus, setSettingModalStatus] = useState(false);
  const [orderType, setOrderType] = useState("Running");
  const [employeHelpStatus, setEmployeHelpStatus] = useState(false);
  const [selectedOrderToDispatch, setSelectedOrderToDispatch] = useState("");
  const [runningOrderType, setRunningOrderType] = useState("All");
  const [toDispacthType, setToDispatchType] = useState("All");
  const [alertTwoButtonModal, setAlertTwoButtonModal] = useState({
    title: "",
    msg: "",
    status: false,
    setStatus: "",
    button1: "",
    button1Click: "",
    button2: "",
    button2Click: "",
  });
  const navigate = useNavigate();
  const [viewOrderStatus, setViewOrderStatus] = useState(false);
  // State to store addresses
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const [readonModalStatus, setreadonModalStatus] = useState(false);
  const [reason, setReason] = useState("");
  const [customReason, setCustomReason] = useState("");
  // Function to handle reverse geocoding
  const reverseGeocode = (lat, lng, invno, order) => {
    const geocoder = new window.google.maps.Geocoder();
    const latlng = { lat: parseFloat(lat), lng: parseFloat(lng) };

    geocoder.geocode({ location: latlng }, (results, status) => {
      if (status === "OK" && results[0]) {
        setOrderDetails((prev) => ({
          ...prev,
          [invno]: {
            ...prev[invno],
            address: toProperCase(results[0].formatted_address), // Store address by invoice number
          },
        }));
      } else {
        setOrderDetails((prev) => ({
          ...prev,
          [invno]: {
            ...prev[invno],
            address: "Address not available", // Default message if geocode fails
          },
        }));
      }

      calculateDistance(order?.LATITUDE, order.LONGITUDE, order.INVNO, order);
    });
  };
  const [orderDetails, setOrderDetails] = useState({});
  const [selectedOrder, setSelectedOrder] = useState("");
  const lattitude = useSelector(selectLatitude);
  const longitude = useSelector(selectLongitude);
  const STORE_LOCATION = { lat: lattitude, lng: longitude }; // Replace with actual store

  const calculateHaversineDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371; // Radius of the Earth in kilometers
    const dLat = ((lat2 - lat1) * Math.PI) / 180; // Delta latitude in radians
    const dLon = ((lon2 - lon1) * Math.PI) / 180; // Delta longitude in radians

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos((lat1 * Math.PI) / 180) *
        Math.cos((lat2 * Math.PI) / 180) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c; // Distance in kilometers

    return distance.toFixed(2); // Return distance rounded to 2 decimal places
  };
  const calculateDistance = (lat, lng, invno) => {
    const destination = { lat: parseFloat(lat), lng: parseFloat(lng) };
    const origin = STORE_LOCATION;

    // Calculate distance using Haversine formula
    const distance = calculateHaversineDistance(
      origin.lat,
      origin.lng,
      destination.lat,
      destination.lng
    );

    setOrderDetails((prev) => ({
      ...prev,
      [invno]: {
        ...prev[invno],
        distance: `${distance} km`,
      },
    }));
  };
  useEffect(() => {
    if (isMapLoaded) {
      summaryList.forEach((order) => {
        reverseGeocode(order?.LATITUDE, order.LONGITUDE, order.INVNO, order);
      });
    }
    // Trigger reverse geocoding for each order on mount
  }, [isMapLoaded, summaryList]);
  const prefix = useSelector(selectPrefix);
  const token = useSelector(selectToken);
  const dispatch = useDispatch();

  const rejectOrder = (invno) => {
    dispatch(setSpinnerLoading("Rejecting order"));
    LoadBill(
      prefix,
      token,
      dispatch,
      invno,
      setBillOrderDetails,
      setCustomerDetails,
      setBillingDetails,
      setDate,
      "orders"
    )
      .then((d) => {
        const data = d.map((o) => {
          return {
            ...o,
            BILL_STATUS: "Rejected",
            IP_ADDRESS: "",
            BATCH: "",
            LATITUDE: "",
            LONGITUDE: "",
            DELIVERY_DATE: moment(o.DELIVERY_DATE).format(
              "YYYY-MM-DD HH:mm:ss"
            ),
            DELIVERED_TIME: "",
            ORDER_NO: 0,
            DEL_ASSIGNED_EMPLOYEE_CODE: "0",
            DELIVERED_BY_EMP_CODE: "0",
            DELIVERY_ASSIGN_TO: "0",
            DELIVERY_ASSIGN_TIME: "",
            ORDER_RECEIVED_BY: "",
          };
        });
        CallAPI(
          "/api/billing/saveBill",
          {
            table_prefix: prefix,
            bill_cart: data,
            NEW_MODIFY: "Modify",
            BILL_PERFORMA: "orders",
          },
          token,
          (e) => {
            if (!e.data.error) {
              setViewOrderStatus(false);
              dispatch(
                setAlertWithTitle({
                  title: "Rejected",
                  msg: `Order No. ${data[0].INVNO} of Customer ${data[0].CUSTOMER_NAME} - [ ${data[0].CUSTOMER_MOBILE} ] rejected successfully`,
                })
              );
              getMessageWallet(
                companyDetails.email,
                token,
                false,
                dispatch
              ).then((bal) => {
                if (bal > 0) {
                  CallAPIPromise("/api/whatsApp/send-whatsapp-message", {
                    type: "order_rejected",
                    toPhoneNumber: data[0].CUSTOMER_MOBILE,
                    INVNO: data[0].INVNO,
                    AMOUNT: data[0].GTOTAL.toFixed(2),
                    prefix: prefix,
                    template_for: "order_rejected",
                    companyEmail: companyDetails.email,
                    message_for: "order-rejected",
                    company_name: companyDetails.companyName,
                    rejected_reason: customReason ? customReason : reason,
                  }).then(() => {
                    setReason("");
                    setCustomReason("");
                  });
                }
              });

              GetOrderSummary(
                {
                  table_prefix: prefix,
                  BILL_STATUS: "Running",
                  FDATE: fromDate,
                  TDATE: toDate,
                  BILL_PERFORMA: "orders",
                },
                token,
                dispatch,
                setSummaryList
              );
            } else {
              dispatch(
                setAlertWithTitle({
                  title: "Error",
                  msg: e.data.error,
                })
              );
            }
          },
          dispatch,
          () => {
            dispatch(setSpinnerLoading(false));
          }
        );
      })
      .catch(() => {
        dispatch(setSpinnerLoading(false));
      });
  };
  useEffect(() => {
    const connectedRef = ref(
      database,
      `loginAuth/${prefix.replace(/_.+_/, "_")}`
    );
    const connectedRef2 = ref(
      database,
      `loginAuth/${prefix.replace(/_.+_/, "_")}/newOrder`
    );
    get(child(connectedRef, `/newOrder`)).then((sn) => {
      onValue(connectedRef2, (sn) => {
        if (sn.val()) {
          if (orderType !== "Running") {
            setOrderType("Running");
          } else {
            GetOrderSummary(
              {
                table_prefix: prefix,
                BILL_STATUS: "Running",
                FDATE: fromDate,
                TDATE: toDate,
                BILL_PERFORMA: "orders",
              },
              token,
              dispatch,
              setSummaryList
            );
          }
        }
      });
    });
  }, [orderType]);

  useEffect(() => {
    if (viewOrderStatus) {
      LoadBill(
        prefix,
        token,
        dispatch,
        selectedOrder,
        setBillOrderDetails,
        setCustomerDetails,
        setBillingDetails,
        setDate,
        "orders"
      );
    }
  }, [viewOrderStatus]);

  useEffect(() => {
    setSummaryList([]);
    if (orderType === "Running") {
      GetOrderSummary(
        {
          table_prefix: prefix,
          BILL_STATUS: "Running",
          FDATE: fromDate,
          TDATE: toDate,
          BILL_PERFORMA: "orders",
        },
        token,
        dispatch,
        setSummaryList
      );
    } else {
      if (orderType === "Suspended") {
        GetOrderSummary(
          {
            table_prefix: prefix,
            BILL_STATUS: "Suspended",
            FDATE: fromDate,
            TDATE: toDate,
            BILL_PERFORMA: "orders",
          },
          token,
          dispatch,
          setSummaryList
        );
      } else {
        if (orderType === "Rejected") {
          GetOrderSummary(
            {
              table_prefix: prefix,
              BILL_STATUS: "Rejected",
              FDATE: fromDate,
              TDATE: toDate,
              BILL_PERFORMA: "orders",
            },
            token,
            dispatch,
            setSummaryList,
            true
          );
        } else {
          if (orderType === "To-Dispatch") {
            GetOrderSummary(
              {
                table_prefix: prefix,
                BILL_STATUS: "To-Dispatch",
                FDATE: fromDate,
                TDATE: toDate,
                BILL_PERFORMA: "bills",
              },
              token,
              dispatch,
              setSummaryList,
              true
            );
          } else {
            if (orderType === "Delivered") {
              GetOrderSummary(
                {
                  table_prefix: prefix,
                  BILL_STATUS: "Delivered",
                  FDATE: fromDate,
                  TDATE: toDate,
                  BILL_PERFORMA: "bills",
                },
                token,
                dispatch,
                setSummaryList,
                true
              );
            } else {
              if (orderType === "Future") {
                GetOrderSummary(
                  {
                    table_prefix: prefix,
                    BILL_STATUS: "Future Order",
                    FDATE: moment().format("YYYY-MM-DD"),
                    TDATE: moment().add(1, "month").format("YYYY-MM-DD"),
                    BILL_PERFORMA: "orders",
                  },
                  token,
                  dispatch,
                  setSummaryList,
                  true
                );
              }
            }
          }
        }
      }
    }
  }, [orderType, fromDate, toDate]);
  const companyDetails = useSelector(selectCompanyAddress);
  const assignToEmployee = (invno, employee) => {
    dispatch(setSpinnerLoading("Assigning employee"));
    const orderDetails = summaryList.filter((o) => o.INVNO);
    CallAPIPromise("api/dispatch/assignOrderToEmployee", {
      table_prefix: prefix,
      table_prefix_master: prefix.replace(/_.+_/, "_"),
      CODE: employee,
      BILL_PERFORMA: "bills",
      INVNO: invno,
      DELIVERY_ASSIGN_TIME: moment().format("YYYY-MM-DD HH:mm:ss"),
    })
      .then((res) => {
        if (!res.data.error) {
          dispatch(
            setAlertWithTitle({
              title: "success",
              msg: "Order Successfully assigned to employee",
            })
          );

          getMessageWallet(companyDetails.email, token, false, dispatch).then(
            (bal) => {
              if (bal > 0) {
                CallAPIPromise("/api/whatsApp/send-whatsapp-message", {
                  type: "order_dispatch",
                  toPhoneNumber: orderDetails[0].CUSTOMER_MOBILE,
                  INVNO: invno,
                  AMOUNT: orderDetails[0].AMOUNT.toFixed(2),
                  prefix: prefix,
                  template_for: "order_dispatch",
                  companyEmail: companyDetails.email,
                  message_for: "",
                  company_name: companyDetails.companyName,
                  rejected_reason: "",
                });
              }
            }
          );
        }
      })
      .catch((e) => {
        dispatch(
          setAlertWithTitle({
            title: "Error",
            msg: "Something went wrong",
          })
        );
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
      });
  };
  return (
    <div className="w-full h-full flex flex-col">
      <div className="flex justify-between px-6 pt-3">
        <div className="bg-[#e6e4e4] p-1 w-fit rounded-sm mt-2  flex flex-col">
          <div className="grid md:grid-cols-6 grid-cols-2 min-w-[350px]">
            <button
              onClick={() => {
                setOrderType("Running");
                setRunningOrderType("All");
              }}
              className={`${
                orderType === "Running" ? "bg-[#fa5d23]  text-white" : ""
              } py-1 px-3  rounded-sm flex-1 text-center`}
            >
              Running
            </button>
            <button
              onClick={() => {
                setOrderType("Future");
              }}
              className={`${
                orderType === "Future" ? "bg-[#66b3ec]  text-black" : ""
              } py-1 px-3  rounded-sm flex-1 text-center`}
            >
              Future
            </button>
            <button
              onClick={() => {
                setOrderType("Suspended");
              }}
              className={`${
                orderType === "Suspended" ? "bg-orange  text-white" : ""
              } py-1 px-3 rounded-sm flex-1 text-center`}
            >
              Suspended
            </button>

            <button
              onClick={() => {
                setOrderType("Rejected");
              }}
              className={`${
                orderType === "Rejected" ? "bg-red-500  text-white" : ""
              } py-1 px-3 rounded-sm flex-1 text-center`}
            >
              Rejected
            </button>

            <button
              onClick={() => {
                setOrderType("To-Dispatch");
                setToDispatchType("All");
              }}
              className={`${
                orderType === "To-Dispatch" ? "bg-[#3a8e5c] text-white" : ""
              } py-1 px-3  rounded-sm flex-1 text-center`}
            >
              To-Dispatch
            </button>

            <button
              onClick={() => {
                setOrderType("Delivered");
              }}
              className={`${
                orderType === "Delivered" ? "bg-[#3a8e5c] text-white" : ""
              } py-1 px-3 rounded-sm flex-1 text-center`}
            >
              Delivered
            </button>
          </div>
          {orderType === "Suspended" && (
            <div className="flex gap-4 mt-2 mb-1 border-t border-black justify-center pt-2">
              <div className="flex flex-col">
                <label className="text-sm font-medium text-gray-700 mb-1">
                  From Date
                </label>
                <input
                  type="date"
                  value={fromDate}
                  onChange={(e) =>
                    setFromDate(moment(e.target.value).format("YYYY-MM-DD"))
                  }
                  className="rounded-sm px-3 py-1.5 border border-gray-300 focus:ring-2 focus:ring-orange-500 focus:border-orange-500 outline-none"
                  placeholder="From Date"
                />
              </div>
              <div className="flex flex-col">
                <label className="text-sm font-medium text-gray-700 mb-1">
                  To Date
                </label>
                <input
                  type="date"
                  value={toDate}
                  onChange={(e) =>
                    setToDate(moment(e.target.value).format("YYYY-MM-DD"))
                  }
                  className="rounded-sm px-3 py-1.5 border border-gray-300 focus:ring-2 focus:ring-orange-500 focus:border-orange-500 outline-none"
                  placeholder="To Date"
                />
              </div>
            </div>
          )}
          {orderType === "Running" && (
            <div className="flex  mt-2 mb-1 border-t border-black justify-center pt-2">
              <button
                onClick={() => {
                  setRunningOrderType("All");
                }}
                className={`${
                  runningOrderType === "All" ? "bg-[#fa5d23] text-white" : ""
                } py-1 px-5  rounded-sm w-fit text-center`}
              >
                All
              </button>

              <button
                onClick={() => {
                  setRunningOrderType("Delivery");
                }}
                className={`${
                  runningOrderType === "Delivery"
                    ? "bg-[#fa5d23] text-white"
                    : ""
                } py-1 px-5 ml-2  rounded-sm w-fit text-center`}
              >
                Delivery
              </button>

              <button
                onClick={() => {
                  setRunningOrderType("Pickup");
                }}
                className={`${
                  runningOrderType === "Pickup" ? "bg-[#fa5d23] text-white" : ""
                } py-1 px-5 ml-2  rounded-sm w-fit text-center`}
              >
                Pickup
              </button>
            </div>
          )}

          {orderType === "To-Dispatch" && (
            <div className="flex  mt-2 mb-1 border-t border-black justify-center pt-2">
              <button
                onClick={() => {
                  setToDispatchType("All");
                }}
                className={`${
                  toDispacthType === "All" ? "bg-[#3a8e5c] text-white" : ""
                } py-1 px-5  rounded-sm w-fit text-center`}
              >
                All
              </button>

              <button
                onClick={() => {
                  setToDispatchType("Not-Assigned");
                }}
                className={`${
                  toDispacthType === "Not-Assigned"
                    ? "bg-[#3a8e5c] text-white"
                    : ""
                } py-1 px-5 ml-2  rounded-sm w-fit text-center`}
              >
                Not-Assigned
              </button>

              <button
                onClick={() => {
                  setToDispatchType("Assigned");
                }}
                className={`${
                  toDispacthType === "Assigned" ? "bg-[#3a8e5c] text-white" : ""
                } py-1 px-5 ml-2  rounded-sm w-fit text-center`}
              >
                Assigned
              </button>
            </div>
          )}
        </div>
        <button
          onClick={() => {
            setSettingModalStatus(true);
          }}
          className="fixed bottom-4 right-4 flex px-3 h-fit py-2 rounded-md justify-start items-center text-white text-sm 
  bg-[#FF7F50] shadow-[0_4px_6px_rgba(0,0,0,0.1),0_1px_3px_rgba(0,0,0,0.06)] 
  hover:bg-[#FF4500] hover:shadow-[0_6px_8px_rgba(0,0,0,0.15),0_3px_5px_rgba(0,0,0,0.1)] 
  transition-all duration-200 ease-in-out"
        >
          <i className="bi bi-gear text-[18px] mr-2"></i>
          Settings
        </button>
      </div>

      <div className="bg-white px-6 my-4 flex flex-col flex-1 overflow-y-auto">
        {summaryList[0] ? (
          <div className="grid 2xll:grid-cols-4  xl:grid-cols-3 slg:grid-cols-2 grid-cols-1 gap-4">
            {summaryList
              .filter((s) => {
                if (orderType === "Running") {
                  if (runningOrderType === "All") {
                    return true;
                  } else {
                    if (runningOrderType === "Pickup") {
                      return s.ORDER_TYPE === "Pickup";
                    } else {
                      if (runningOrderType === "Delivery") {
                        return s.ORDER_TYPE === "Delivery";
                      }
                    }
                  }
                } else {
                  if (orderType === "To-Dispatch") {
                    if (toDispacthType === "All") {
                      return true;
                    } else {
                      if (toDispacthType === "Not-Assigned") {
                        return s.DELIVERY_ASSIGN_TO === "";
                      } else {
                        if (toDispacthType === "Assigned") {
                          return s.DELIVERY_ASSIGN_TO !== "";
                        } else {
                          return true;
                        }
                      }
                    }
                  } else {
                    return true;
                  }
                }
              })
              .map((i) => {
                const isEmpoyeeSelectedToDispatch = employeeDetails.filter(
                  (o) => o.INVNO === i.INVNO
                );
                const employeeName = isEmpoyeeSelectedToDispatch[0]
                  ? isEmpoyeeSelectedToDispatch[0].employee_name
                  : i.DELIVERY_ASSIGN_TO;
                return (
                  <OrderCard
                    i={i}
                    orderDetails={orderDetails}
                    orderType={orderType}
                    employeeName={employeeName}
                    setViewOrderStatus={setViewOrderStatus}
                    setSelectedOrder={setSelectedOrder}
                    setAlertTwoButtonModal={setAlertTwoButtonModal}
                    navigate={navigate}
                    rejectOrder={rejectOrder}
                    setreadonModalStatus={setreadonModalStatus}
                    setSelectedOrderToDispatch={setSelectedOrderToDispatch}
                    setEmployeHelpStatus={setEmployeHelpStatus}
                    selectedOrderToDispatch={selectedOrderToDispatch}
                    assignToEmployee={assignToEmployee}
                    isEmpoyeeSelectedToDispatch={isEmpoyeeSelectedToDispatch}
                  />
                );
              })}
          </div>
        ) : (
          <div className="h-full w-full flex items-center justify-center">
            <div>No Orders Found.</div>
          </div>
        )}
        {viewOrderStatus && billOrderDetails[0] && (
          <SwipeableEdgeDrawer
            status={viewOrderStatus}
            setStatus={setViewOrderStatus}
            data={
              <div className="bg-[#e0e3d5] h-full rounded-sm overflow-hidden">
                <div className="bg-[#daddcf] p-2 flex min-w-[300px] items-center justify-between border-b border-[#c9ccbc]">
                  <div>Order Details</div>
                  <div
                    onClick={() => setViewOrderStatus(false)}
                    className="cursor-pointer"
                  >
                    <i className="bi bi-x-lg"></i>
                  </div>
                </div>
                <div className="flex flex-col justify-between">
                  <div>
                    <div className="flex p-4 bg-[#daddcf] flex-1">
                      <div className="h-[50px] flex items-center justify-center w-[50px] rounded-[50px] overflow-hidden bg-gray-100">
                        <Lottie
                          animationData={
                            billOrderDetails[0].ORDER_TYPE.toLowerCase() ===
                            "pickup"
                              ? pickup
                              : delivery
                          }
                          loop={true}
                          style={{ fontSize: 15, height: 30 }}
                          color="white"
                        />
                      </div>
                      <div className="ml-4 flex justify-between flex-1">
                        <div>
                          <div className="font-bold text-gray-800">
                            {toProperCase(customerDetails.CUSTOMER_NAME)}
                          </div>
                          <div className="text-[11px] text-gray-700">
                            {formatMobileNumber(
                              customerDetails.CUSTOMER_MOBILE
                            )}
                          </div>
                        </div>
                        <div className="text-right">
                          <div className="font-bold text-green-600">
                            ₹
                            {FormatNumber2(
                              billOrderDetails[0]?.GTOTAL
                                ? billOrderDetails[0]?.GTOTAL
                                : 0
                            )}
                          </div>
                          <div className="text-gray-500 text-[11px]">Cash</div>
                        </div>
                      </div>
                    </div>
                    <div className={"  mx-2  mt-4 "}>
                      {billOrderDetails.map((o, key) => {
                        return <CartItem key={key} item={o} />;
                      })}
                    </div>
                  </div>
                  {orderType !== "Rejected" && (
                    <div className="p-2 w-full absolute bottom-0  ">
                      <button
                        onClick={() => {
                          navigate(`/billing`, {
                            state: {
                              INVNO: billOrderDetails[0].INVNO,
                            },
                          });
                        }}
                        className={`bg-[#4A5530] text-[ #FFFFFF] w-full flex items-center justify-center text-primary-foreground hover:bg-[#404929] ${buttonClasses}`}
                      >
                        <i className="bi bi-check2 flex items-center mt-1"></i>
                        <div className="ml-1">Accept</div>
                      </button>
                      <button
                        onClick={() => {
                          setSelectedOrder(billOrderDetails[0].INVNO);
                          setreadonModalStatus(true);
                        }}
                        className={`bg-[#A23B3B]  text-[#FFFFFF] text-center justify-center flex w-full mt-2 hover:bg-[#973737] ${buttonClasses}`}
                      >
                        <i className="bi bi-x flex items-center mt-1"></i>
                        <div className="ml-1">Reject</div>
                      </button>
                    </div>
                  )}
                </div>
              </div>
            }
          />
        )}
        <RejectOrderModal
          isOpen={readonModalStatus}
          onClose={() => {
            setreadonModalStatus(false);
          }}
          customReason={customReason}
          setCustomReason={setCustomReason}
          reason={reason}
          setReason={setReason}
          onSubmit={() => {
            rejectOrder(selectedOrder);
          }}
        />
        <AlertModalTwoButton
          details={alertTwoButtonModal}
          setAlertTwoButtonModal={setAlertTwoButtonModal}
        />
        <EmployeeHelp
          status={employeHelpStatus}
          onRowClick={(p) => {
            setAlertTwoButtonModal({
              title: "Assign to Employee",
              msg: `Do You want to assign this Order No. ${selectedOrderToDispatch} to Employee ${p.row.employee_name}`,
              status: true,
              setStatus: "",
              button1: "Yes",
              button1Click: () => {
                assignToEmployee(selectedOrderToDispatch, p.row.code);
                setAlertTwoButtonModal((prev) => {
                  return {
                    ...prev,
                    status: false,
                  };
                });
              },
              button2: "No",
              button2Click: () => {
                setAlertTwoButtonModal((prev) => {
                  return {
                    ...prev,
                    status: false,
                  };
                });
              },
            });
            setEmployeeDetails((prev) => {
              const isHaveAllready = prev.filter(
                (d) => d.INVNO === selectedOrderToDispatch
              );
              if (isHaveAllready[0]) {
                return prev.map((d) => {
                  if (d.INVNO === selectedOrderToDispatch) {
                    return {
                      d,
                      ...p.row,
                    };
                  } else {
                    return d;
                  }
                });
              } else {
                return [
                  ...prev,
                  {
                    ...p.row,
                    INVNO: selectedOrderToDispatch,
                  },
                ];
              }
            });
            setEmployeHelpStatus(false);
          }}
          onClose={() => {
            setEmployeHelpStatus(false);
          }}
        />
        <SettingModal
          status={settingModalStatus}
          onClose={() => {
            setSettingModalStatus(false);
          }}
        />
      </div>
    </div>
  );
};

export default RecivedOrderDashboard;
const OrderCard = ({
  i,
  orderDetails,
  orderType,
  employeeName,
  setViewOrderStatus,
  setSelectedOrder,
  setAlertTwoButtonModal,
  navigate,
  rejectOrder,
  setSelectedOrderToDispatch,
  setEmployeHelpStatus,
  selectedOrderToDispatch,
  assignToEmployee,
  isEmpoyeeSelectedToDispatch,
  setreadonModalStatus,
}) => {
  return (
    <div
      key={i.INVNO}
      className="bg-white text-[#2C3E50] min-w-[400px] rounded-lg card shadow-sm overflow-hidden"
    >
      <div className="flex p-4 bg-[#e0e5e3]">
        <div className="h-[50px] flex items-center justify-center w-[50px] rounded-[50px] overflow-hidden bg-gray-200">
          <Lottie
            animationData={
              i.ORDER_TYPE.toLowerCase() === "pickup" ? pickup : delivery
            }
            loop={false}
            style={{ fontSize: 15, height: 30 }}
            color="white"
          />
        </div>
        <div className="ml-4 flex justify-between flex-1">
          <div>
            <div className="font-semibold text-gray-900">
              {toProperCase(i.CUSTOMER_NAME)}
            </div>
            <div className="text-[12px] text-gray-600">
              {formatMobileNumber(i.CUSTOMER_MOBILE)}
            </div>
          </div>
          <div className="text-right">
            <div className="font-semibold text-green-700">
              ₹{FormatNumber2(i.GTOTAL)}
            </div>
            <div className="text-gray-600 text-[12px]">Cash</div>
          </div>
        </div>
      </div>

      <div className="flex pb-4 border-b px-4 bg-[#e0e5e3] border-[#c4c7bb]">
        <div className="flex flex-1">
          <div>
            <div className="text-[14px] flex">
              <div className="font-semibold text-gray-800">Invoice</div>
              <div className="ml-1 text-gray-600">({i.INVNO})</div>
            </div>
            <div className="flex text-[13px] text-gray-600">
              <div className="font-semibold">Order Type:</div>
              <div className="ml-1">{i.ORDER_TYPE}</div>
            </div>
          </div>
        </div>
        <div className="flex flex-1 ml-4">
          <div>
            <div className="text-[14px] flex text-gray-800">
              <div className="font-semibold">Delivery</div>
              <div className="ml-1 text-gray-600">
                (
                {moment(i.DELIVERY_DATE ? i.DELIVERY_DATE : "")
                  .utcOffset("+05:30")
                  .format("DD-MMM-YYYY")}
                )
              </div>
            </div>
            <div className="text-[13px] flex text-gray-600">
              <div className="font-semibold">Time</div>
              <div className="ml-1">{i.DELIVERY_TIME}</div>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{ height: `calc(100% - 167px)` }}
        className={"flex flex-col justify-between"}
      >
        <div className="mt-3 px-4 pb-4">
          <div className="flex items-center">
            <i className="bi bi-geo-alt-fill text-[#429628]"></i>
            <div className="ml-3">
              <div className="text-[13px] font-semibold text-gray-800">
                {i?.CUSTOMER_ADDRESS || "Fetching address..."}
              </div>
              <div className="text-[11px] text-gray-500">
                {i?.distance
                  ? `${i.distance} Distance`
                  : "Fetching distance..."}
              </div>
            </div>
          </div>

          {orderType === "To-Dispatch" && employeeName && (
            <div className="flex items-center mt-2">
              <i className="bi bi-person-fill text-[#429628]"></i>
              <div className="ml-3">
                <div className="text-[13px] font-semibold text-gray-800">
                  Employee
                </div>
                <div className="text-[11px] text-gray-500">{employeeName}</div>
              </div>
            </div>
          )}

          {orderType === "To-Dispatch" && employeeName && (
            <div className="flex items-center mt-2">
              <i className="bi bi-person-fill text-[#429628]"></i>
              <div className="ml-3">
                <div className="text-[13px] font-semibold text-gray-800">
                  Assign Time
                </div>
                <div className="text-[11px] text-gray-500">
                  {i.DELIVERY_ASSIGN_TIME
                    ? moment(i.DELIVERY_ASSIGN_TIME).format("YYYY-MM-DD hh:mm")
                    : ""}
                </div>
              </div>
            </div>
          )}

          {orderType === "Delivered" && (
            <div className="flex items-center mt-2">
              <i className="bi bi-person-fill text-[#429628]"></i>
              <div className="ml-3">
                <div className="text-[13px] font-semibold text-gray-800">
                  Delivered By
                </div>
                <div className="text-[11px] text-gray-500">
                  {i.DELIVERED_BY}
                </div>
              </div>
            </div>
          )}

          {orderType === "Delivered" && (
            <div className="flex items-center mt-2">
              <i className="bi bi-person-fill text-[#429628]"></i>
              <div className="ml-3">
                <div className="text-[13px] font-semibold text-gray-800">
                  Delivered To
                </div>
                <div className="text-[11px] text-gray-500">
                  {i.ORDER_RECEIVED_BY}
                </div>
              </div>
            </div>
          )}
        </div>

        {orderType !== "To-Dispatch" && orderType !== "Delivered" && (
          <div className="flex p-4">
            <button
              onClick={() => {
                setViewOrderStatus(true);
                setSelectedOrder(i.INVNO);
              }}
              className="bg-[#c9ceb8] flex flex-1 mr-3 text-[#33352b] items-center justify-center py-2 px-4 rounded-lg hover:bg-[#b8c1a0]"
            >
              View
            </button>
            {orderType !== "Rejected" && (
              <button
                onClick={() => {
                  setAlertTwoButtonModal({
                    title: "Confirm",
                    msg: "Are You sure you want to create the Bill of this Order?",
                    status: true,
                    button1: "Yes",
                    button2: "Cancel",
                    button1Click: () => {
                      navigate(`/billing`, {
                        state: {
                          INVNO: i.INVNO,
                        },
                      });
                      setAlertTwoButtonModal((o) => {
                        return {
                          ...o,
                          status: false,
                        };
                      });
                    },
                    button2Click: () => {
                      setAlertTwoButtonModal((o) => {
                        return {
                          ...o,
                          status: false,
                        };
                      });
                    },
                  });
                }}
                className="bg-[#4A5530] text-[#FFFFFF] flex flex-1 items-center justify-center py-2 px-4 rounded-lg hover:bg-[#404929]"
              >
                <i className="bi bi-check2 flex items-center mt-1"></i>
                <div className="ml-1">Accept</div>
              </button>
            )}
            {orderType !== "Rejected" && (
              <button
                onClick={() => {
                  setSelectedOrder(i.INVNO);
                  setreadonModalStatus(true);
                }}
                className="bg-[#A23B3B] text-[#FFFFFF] flex flex-1 ml-3 items-center justify-center py-2 px-4 rounded-lg hover:bg-[#973737]"
              >
                <i className="bi bi-x flex items-center mt-1"></i>
                <div className="ml-1">Reject</div>
              </button>
            )}
          </div>
        )}

        {orderType === "To-Dispatch" && (
          <div className="flex px-4 pb-4">
            <button
              onClick={() => {
                setSelectedOrderToDispatch(i.INVNO);
                setEmployeHelpStatus(true);
              }}
              className="bg-[#3a8e5c] flex flex-1 text-white items-center justify-center py-2 px-4 rounded-lg hover:bg-[#2e6f4b]"
            >
              <i className="bi bi-person flex items-center mr-1"></i>
              <div className="ml-1">
                {employeeName ? "Change Employee" : "Select Employee To Assign"}
              </div>
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
