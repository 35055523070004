import React from "react";
import "./TextBox.css"; // Custom CSS for styling
import { FontSizeWidth } from "../constant";
import { useSelector } from "react-redux";
import { selectWidth } from "../Redux/profile";

const OutlinedTextBox = ({
  label = "",
  id = "",
  bgColor = "white",
  fontWeight = "normal",
  align = "left",
  onKeyDown = () => {},
  width = "100%",
  value = "",
  search,
  onChange = () => {},
  type = "text",
  readOnly = false,
  refrance,
  onFocus = () => {},
  onClick = () => {},
  autoFocus = false,
  color = "gray",
  fontSize,
}) => {
  const windowWidth = useSelector(selectWidth);
  return (
    <div className="outlined-textbox-container" style={{ width }}>
      <div className="outlined-textbox-wrapper">
        {label && (
          <label
            htmlFor={id}
            className="outlined-textbox-label"
            style={{
              fontSize: fontSize ? fontSize : FontSizeWidth(windowWidth),
              fontWeight: fontWeight ? fontWeight : "normal",
              color: color ? color : "gray",
            }}
          >
            {label}
          </label>
        )}
        <div className="outlined-input-wrapper">
          <input
            id={id ? id : ""}
            type={type}
            value={value}
            onChange={onChange}
            readOnly={readOnly ? readOnly : false}
            ref={refrance}
            onFocus={onFocus}
            onKeyDown={onKeyDown ? onKeyDown : () => {}}
            onClick={onClick}
            autoFocus={autoFocus ? autoFocus : false}
            style={{
              textAlign: align ? align : "left",
              outline: "none",
              fontSize: FontSizeWidth(windowWidth),
              backgroundColor: bgColor ? bgColor : "white",
            }}
            className="outlined-textbox-input "
          />
          {search && (
            <button className="outlined-textbox-search-btn" onClick={search}>
              <i className="bi bi-search"></i>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default OutlinedTextBox;
