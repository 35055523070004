import { Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setAlertWithTitle } from "../../utility/Redux/modal";
import OtpAlertModal from "../../utility/component/modals/otpAlert";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: "auto",
  width: "auto",
  borderRadius: "8px",
  overflow: "hidden",
  backgroundColor: "white",
};

const textMutedClasses = "text-muted-foreground";
const inputClasses = "mt-1 p-2 border border-border rounded w-full";
const buttonClasses =
  "mt-6 w-full bg-primary text-primary-foreground p-2 rounded hover:bg-primary/80";

const MobileChangeModal = ({
  status,
  onClose,
  mobile,
  sendOTP,
  onRefresh,
  list,
  randome,
  VerifyMobileSentAPI,
}) => {
  const [newMobile, setNewMobile] = useState("");
  const [otpStatus, setOtpStatus] = useState(false);

  const dispatch = useDispatch();

  const mobileRegex = /^[6-9]\d{9}$/; // Regex for valid Indian mobile numbers

  useEffect(() => {
    setNewMobile("");
  }, [status]);

  return (
    <div className="bg-white">
      <Modal
        open={status}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div style={style} className="flex">
          <div className="max-w-[400px] py-4 px-4">
            {/* Close Icon */}
            <div className="flex justify-end">
              <i
                className="bi bi-x cursor-pointer text-[25px]"
                onClick={onClose}
              ></i>
            </div>

            {/* Mobile Icons */}
            <div className="flex justify-center">
              <div className="bg-gray-100 border-brown border-2 flex items-center justify-center h-[40px] w-[40px] rounded-[40px]">
                <i className="bi bi-phone"></i>
              </div>
              <div className="bg-brown -ml-[14px] flex items-center justify-center h-[40px] w-[40px] rounded-[40px]">
                <i className="bi bi-phone-fill text-white"></i>
              </div>
            </div>

            {/* Header */}
            <div className="flex justify-center font-semibold py-3">
              Change Mobile Number
            </div>
            <div className="flex justify-center text-gray-700 text-center">
              Please enter your previous mobile number and new mobile number.
            </div>

            {/* Form */}
            <div className="py-2">
              <div className="mt-4">
                {/* Previous Mobile */}
                <label
                  className={`block ${textMutedClasses}`}
                  htmlFor="prevMobile"
                >
                  Previous Mobile Number
                </label>
                <input
                  type="text"
                  id="prevMobile"
                  placeholder="1234567890"
                  className={inputClasses}
                  value={mobile}
                  required
                  disabled
                />

                {/* New Mobile */}
                <label
                  className={`block ${textMutedClasses} mt-4`}
                  htmlFor="newMobile"
                >
                  New Mobile Number
                </label>
                <input
                  type="text"
                  id="newMobile"
                  value={newMobile}
                  onChange={(t) => {
                    const isDuplicate = list.filter(
                      (c) =>
                        c.CUSTOMER_MOBILE === t.target.value &&
                        c.CUSTOMER_MOBILE
                    );
                    if (!isDuplicate[0]) {
                      setNewMobile(t.target.value);
                    } else {
                      dispatch(
                        setAlertWithTitle({
                          title: "Alert",
                          msg: "Mobile number already exists",
                        })
                      );
                    }
                  }}
                  placeholder="1234567890"
                  className={inputClasses}
                  required
                />

                {/* Submit Button */}
                <button
                  onClick={() => {
                    if (mobileRegex.test(newMobile)) {
                      sendOTP(newMobile).then((o) => setOtpStatus(true));
                    } else {
                      dispatch(
                        setAlertWithTitle({
                          title: "Alert",
                          msg: "Please enter a valid mobile number",
                        })
                      );
                    }
                  }}
                  type="submit"
                  className={buttonClasses}
                >
                  Update Mobile Number
                </button>
              </div>
              {status && (
                <OtpAlertModal
                  status={otpStatus}
                  otp={randome}
                  onCancel={() => {
                    setOtpStatus(false);
                  }}
                  onSubmit={() => {
                    setOtpStatus(false);
                    onRefresh();
                    VerifyMobileSentAPI(newMobile).then((e) => {
                      dispatch(
                        setAlertWithTitle({
                          title: "Alert",
                          msg: "Mobile Number changed",
                        })
                      );

                      onClose();
                    });
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default MobileChangeModal;
