import moment from "moment";

const FirstIcodeItem = (
  id,
  qnty,
  pur_price,
  diq_rate,
  diq_amt,
  tax1_rate,
  tax2_rate,
  tax3_rate,
  vat_rate,
  tcs_rate,
  surcharge_rate,
  partyDetails,
  item,
  EXP_DATE,
  value
) => {
  const purAmount = parseFloat(pur_price * qnty);
  const purAmount_markup = parseFloat(pur_price);
  const disqAmount = parseFloat(diq_amt ? diq_amt : 0).toFixed(2);

  const tax1Rate = parseFloat(tax1_rate ? tax1_rate : 0);
  const tax2Rate = parseFloat(tax2_rate ? tax2_rate : 0);
  const tax3Rate = parseFloat(tax3_rate ? tax3_rate : 0);

  const vatRate = parseFloat(vat_rate ? vat_rate : 0);
  const tcsRate = parseFloat(tcs_rate ? tcs_rate : 0);
  const surchargeRate = parseFloat(surcharge_rate ? surcharge_rate : 0);

  let tax1_amt = 0;
  let tax2_amt = 0;
  let tax3_amt = 0;

  let vat_amt = 0;
  let tcs_amt = 0;
  let surcharge_amt = 0;
  let net_amount = 0;
  let net_amount_mark = 0;

  const purAmount11 = purAmount - disqAmount;
  const purAmount11_mark = purAmount_markup - disqAmount;

  if (partyDetails.TAX_TYPE === "INCLUSIVE") {
    if (
      !isNaN(purAmount11) &&
      !isNaN(purAmount11_mark) &&
      !isNaN(tax1Rate) &&
      !isNaN(tax2Rate) &&
      !isNaN(tax3Rate)
    ) {
      const tax1amt = purAmount11 - purAmount11 / (1 + tax1Rate / 100);
      tax1_amt = tax1amt.toFixed(2);

      const tax1amt_mark =
        purAmount11_mark - purAmount11_mark / (1 + tax1Rate / 100);

      const tax2amt = purAmount11 - purAmount11 / (1 + tax2Rate / 100);
      const tax2amt_mark =
        purAmount11_mark - purAmount11_mark / (1 + tax2Rate / 100);
      tax2_amt = tax2amt.toFixed(2);

      const tax3amt = purAmount11 - purAmount11 / (1 + tax3Rate / 100);
      const tax3amt_mark =
        purAmount11_mark - purAmount11_mark / (1 + tax3Rate / 100);
      tax3_amt = tax3amt.toFixed(2);

      const vatamt = purAmount11 - purAmount11 / (1 + vatRate / 100);
      const vatamt_mark =
        purAmount11_mark - purAmount11_mark / (1 + vatRate / 100);
      vat_amt = vatamt.toFixed(2);

      const tcsamt = purAmount11 - purAmount11 / (1 + tcsRate / 100);
      const tcsamt_mark =
        purAmount11_mark - purAmount11_mark / (1 + tcsRate / 100);
      tcs_amt = tcsamt.toFixed(2);

      const surchargeamt =
        purAmount11 - purAmount11 / (1 + surchargeRate / 100);
      const surchargeamt_mark =
        purAmount11_mark - purAmount11_mark / (1 + surchargeRate / 100);
      surcharge_amt = surchargeamt.toFixed(2);

      const total_tax =
        Number(tax1_amt) +
        Number(tax2_amt) +
        Number(tax3_amt) +
        Number(tcs_amt) +
        Number(surcharge_amt) +
        Number(vat_amt);
      const total_tax_mark =
        Number(tax1amt_mark.toFixed(2)) +
        Number(tax2amt_mark.toFixed(2)) +
        Number(tax3amt_mark.toFixed(2)) +
        Number(tcsamt_mark.toFixed(2)) +
        Number(surchargeamt_mark.toFixed(2)) +
        Number(vatamt_mark.toFixed(2));
      const basic_pur_amt = purAmount11 - total_tax;
      const basic_pur_amt_mark = purAmount11_mark - total_tax_mark;
      net_amount = basic_pur_amt + total_tax;
      net_amount_mark = basic_pur_amt_mark + total_tax_mark;
    }
  } else {
    if (
      !isNaN(purAmount11) &&
      !isNaN(purAmount11_mark) &&
      !isNaN(tax1Rate) &&
      !isNaN(tax2Rate) &&
      !isNaN(tax3Rate)
    ) {
      const tax1amt = (purAmount11 * tax1Rate) / 100;
      const tax1amt_mark = (purAmount11_mark * tax1Rate) / 100;
      tax1_amt = tax1amt.toFixed(2);

      const tax2Amt = (purAmount11 * tax2Rate) / 100;
      const tax2amt_mark = (purAmount11_mark * tax2Rate) / 100;
      tax2_amt = tax2Amt.toFixed(2);

      const tax3amt = (purAmount11 * tax3Rate) / 100;
      const tax3amt_mark = (purAmount11_mark * tax3Rate) / 100;
      tax3_amt = tax3amt.toFixed(2);

      const vatamt = (purAmount11 * vatRate) / 100;
      const vatamt_mark = (purAmount11_mark * vatRate) / 100;
      vat_amt = vatamt.toFixed(2);

      const tcsamt = (purAmount11 * tcsRate) / 100;
      const tcsamt_mark = (purAmount11_mark * tcsRate) / 100;
      tcs_amt = tcsamt.toFixed(2);

      const surchargeamt = (purAmount11 * surchargeRate) / 100;
      const surchargeamt_mark = (purAmount11_mark * surchargeRate) / 100;
      surcharge_amt = surchargeamt.toFixed(2);

      const total_tax =
        Number(tax1_amt) +
        Number(tax2_amt) +
        Number(tax3_amt) +
        Number(tcs_amt) +
        Number(surcharge_amt) +
        Number(vat_amt);

      const total_tax_mark =
        Number(tax1amt_mark.toFixed(2)) +
        Number(tax2amt_mark.toFixed(2)) +
        Number(tax3amt_mark.toFixed(2)) +
        Number(tcsamt_mark.toFixed(2)) +
        Number(surchargeamt_mark.toFixed(2)) +
        Number(vatamt_mark.toFixed(2));

      net_amount = purAmount11 + total_tax;
      net_amount_mark = purAmount11_mark + total_tax_mark;
    }
  }
  const markupamount = net_amount_mark * (item.MARK_RATE / 100);
  const salePrice = Math.round(net_amount_mark + markupamount);
  return {
    id: id,
    INVNO: partyDetails.INVNO,
    DATE: moment(partyDetails.PUR_BILL_DATE).format("YYYY-MM-DD"),
    PUR_BILL_NO: partyDetails.PUR_BILL_NO,
    PUR_BILL_DATE: moment(partyDetails.PUR_BILL_DATE).format("YYYY-MM-DD"),
    PUR_BILL_AMOUNT: partyDetails.PUR_BILL_AMOUNT,
    ENT_TIME: moment(partyDetails.PUR_BILL_DATE).format("YYYY-MM-DD"),
    BILL_MODE: partyDetails.BILL_MODE,
    BILL_TYPE: partyDetails.BILL_TYPE,
    ACCOUNT_NO: partyDetails.ACCOUNT_NO,
    NAME: partyDetails.NAME,
    ADDRESS: partyDetails.ADDRESS,
    MOBILE: partyDetails.MOBILE,
    TAX_NUMBER: partyDetails.TAX_NUMBER,
    ICODE: item.ICODE,
    ITEMNAME: item.ITEMNAME,
    PACKING: item.PACKING,
    UNIT: item.UNIT,
    SECTION_NAME: item.SECTION_NAME,
    SUB_SEC_NAME: item.SUB_SEC_NAME,
    BRAND_NAME: item.BRAND_NAME,
    PUR_PRICE: pur_price,
    MRP_RATE: item.MRP_RATE,
    SALE_PRICE: item.SALE_PRICE,
    QNTY: value === "-" || value === "0-" || value === "-0" ? "-" : qnty,
    AMOUNT: qnty * pur_price,
    DISQ_RT: !isNaN(diq_rate) ? (diq_rate ? diq_rate : 0) : 0,
    DISQ_AMT: diq_amt ? diq_amt : "0",
    TAX1_RATE: tax1Rate,
    TAX1_AMT: tax1_amt,
    TAX2_AMT: tax2_amt,
    TAX3_AMT: tax3_amt,
    TAX2_RATE: tax2Rate,
    TAX3_RATE: tax3Rate,
    NET_AMT: net_amount ? net_amount.toFixed(2) : 0,
    BASIC_RATE: "0.00",
    BASIC_AMT: "0.00",
    TOTAL: "0",
    FREIGHT: partyDetails.FREIGHT,
    REMARKS: "",
    ROUND_OFF: "0",
    GTOTAL: "0",
    HSN_CODE: item.HSN_CODE,
    GR_NO: "0",
    PO_NO: "0",
    INCL_EXCL: partyDetails.TAX_TYPE,
    CESS_RATE: item.CESS_RATE,
    CESS_AMOUNT: "0",
    TCS_RATE: tcsRate,
    TCS_AMOUNT: tcs_amt,
    ADDITIONAL_CESS: "0",
    VAT_RATE: vatRate,
    VAT_AMOUNT: vat_amt,
    SURCHARGE_RATE: surchargeRate,
    SURCHARGE_AMT: surcharge_amt,
    SCHEME_RATE: "0",
    SCHEME_AMOUNT: "0.00",
    TOTAL_SCHEME_AMOUNT: "0.00",
    ADD_CHARGES: "0.00",
    LESS_CHARGES: "0.00",
    INCL_TAX: partyDetails.TAX_TYPE === "INCLUSIVE" ? "Yes" : "No",
    DEP_NAME: item.DEP_NAME,
    PURCHASE_PIC: "",
    EXP_DATE: EXP_DATE,
    BATCH: item.BATCH ? item.BATCH : "",
    ALIAS_CODE: item.ALIAS_CODE,
    SALE_PRICE: salePrice,
    MARK_RATE: item.MARK_RATE,
    FREE_QNTY: item?.FREE_QNTY ? item.FREE_QNTY : 0,
    PUR_REFUND: item?.PUR_REFUND ? item.PUR_REFUND : 0,
    SALE_QNTY: item?.SALE_QNTY ? item.SALE_QNTY : 0,
    REF_QNTY: item?.REF_QNTY ? item.FREE_QNTY : 0,
  };
};

export default FirstIcodeItem;
