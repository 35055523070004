import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import IsLocalData from "../component/isLocalData";
export const itemData = {
  ICODE: "",
  ALIAS_CODE: "",
  ITEMNAME: "",
  ITEM_DESC: "",
  PUR_PRICE: "",
  MRP_RATE: "",
  SALE_PRICE: "",
  WHOLE_SALE_PRICE: "",
  SUPPLIER_1: "",
  SUPPLIER_2: "",
  ITEM_AVAILABILITY: "Online & InStore",
  SUPPLIER_3: "",
  HEAD_CODE: "",
  SECTION_NAME: "",
  SUB_SEC_NAME: "",
  UNIT: "",
  PACKING: "",
  BRAND_NAME: "",
  MIN_STOCK: "",
  MAX_STOCK: "",
  TAX1_RATE: "",
  TAX2_RATE: "",
  TAX3_RATE: "",
  OPBAL: "",
  OPBAL_DATE: moment().format("YYYY-MM-DD"),
  MARK_RATE: "",
  HSN_CODE: "",
  DISQ_YN: "",
  DISQ_RATE: "",
  STOP_YN: "No",
  STOCKYN: "",
  CESS_RATE: "",
  OPBAL_RATE: "",
  OPBAL_AMOUNT: "",
  PRINT_ORDER_SLIP: "No",
  GIFT_PACK: "No",
  DEP_NAME: "",
  NON_MOVING: "",
  ITEM_TYPE: "",
  AISLE: "",
  SHELF: "",
  BAY: "",
};
const callback = (details, data) => {
  itemData = {
    ...data[0],
    ICODE: "",
    ALIAS_CODE: "",
    ITEMNAME: "",
    ITEM_DESC: "",
    PUR_PRICE: "",
    MRP_RATE: "",
    SALE_PRICE: "",
    WHOLE_SALE_PRICE: "",
  };
};
IsLocalData("newItemDetails", callback, () => {});

export const Masters = createSlice({
  name: "masters",
  initialState: {
    newItemDetails: itemData,
  },

  reducers: {
    setNewItemDetails(state, action) {
      state.newItemDetails = action.payload;
    },
  },
});

export default Masters.reducer;
export const { setNewItemDetails } = Masters.actions;
export const SelectnewItemDetails = (state) => state.masters.newItemDetails;
