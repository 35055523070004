import CallAPI from "../../utility/functions/getData";
import {
  setAlertWithTitle,
  setSpinnerLoading,
} from "../../utility/Redux/modal";

export const GetItemWiseSaleReport = (
  data,
  token,
  dispatch,
  setSummaryList,
  setModalStatus
) => {
  dispatch(setSpinnerLoading("Loading..."));

  CallAPI(
    "/api/purchaseReports/itemStatus",
    data,
    token,
    (e) => {
      console.log(e);

      if (!e.data.error) {
        setModalStatus(true);
        console.log(e);

        setSummaryList(e.data);
      } else {
        dispatch(
          setAlertWithTitle({
            title: "Alert",
            msg: "Something Went Wrong.",
          })
        );
      }
    },
    dispatch,
    () => {
      dispatch(setSpinnerLoading(false));
    }
  );
};
