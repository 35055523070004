import React from "react";
import "./dropDown.css";
import { FontSizeWidth } from "../constant";
import { useSelector } from "react-redux";
import { selectWidth } from "../Redux/profile";

const DropdownSelect = ({
  label = "",
  options = [],
  value = "",
  onChange = () => {},

  width = "100%",
}) => {
  const windowWidth = useSelector(selectWidth);
  return (
    <div className="dropdown-container" style={{ width }}>
      <div className="dropdown-wrapper">
        {label && (
          <label
            className={`dropdown-label ${
              value ? "dropdown-label-focused" : ""
            }`}
            style={{ fontSize: FontSizeWidth(windowWidth) }}
          >
            {label}
          </label>
        )}
        <select
          className="dropdown-select"
          value={value}
          onChange={(e) => onChange(e.target.value)}
          style={{ fontSize: FontSizeWidth(windowWidth) }}
        >
          <option value="" disabled>
            Select {label}
          </option>
          {options.map((option, index) => (
            <option key={index} value={option.value}>
              {option.value}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default DropdownSelect;
