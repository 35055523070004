import React from "react";
import { FormatNumber2 } from "../../utility/functions/formatNumber";
import { useSelector } from "react-redux";
import { selectWidth } from "../../utility/Redux/profile";

const Footer = ({ columns, rows }) => {
  const validBill = rows.filter((row) => row.BILL_STATUS !== "Void");
  const width = useSelector(selectWidth);

  // Calculate sums once instead of multiple times
  const totals = validBill.reduce(
    (acc, row) => {
      return {
        GTOTAL: acc.GTOTAL + Number(row.GTOTAL),
        TOTAL: acc.TOTAL + Number(row.TOTAL),
        AMOUNT: acc.AMOUNT + Number(row.AMOUNT),
        TAX1_AMT: acc.TAX1_AMT + Number(row.TAX1_AMT),
        TAX2_AMT: acc.TAX2_AMT + Number(row.TAX2_AMT),
        DISQ_AMT: acc.DISQ_AMT + Number(row.DISQ_AMT),
      };
    },
    {
      GTOTAL: 0,
      TOTAL: 0,
      AMOUNT: 0,
      TAX1_AMT: 0,
      TAX2_AMT: 0,
      DISQ_AMT: 0,
    }
  );

  const getFontSize = () => {
    if (width < 500) return "11px";
    if (width < 1281) return "12px";
    return "14px";
  };

  const getBorderStyle = (index) => ({
    fontSize: getFontSize(),
    width: columns[index].width,
    borderLeft: "1px solid black",
    borderRight:
      index === columns.length - 1 ? "1px solid black" : "0px solid black",
    borderBottom: "1px solid black",
    borderTop: "1px solid black",
  });

  const renderCellContent = (field) => {
    if (field === "NAME") return "Total";

    const total = totals[field];
    if (total > 0) {
      return FormatNumber2(total);
    }
    return null;
  };

  return (
    <div className="flex w-full mt-1">
      <div className="flex">
        {columns.map((column, index) => (
          <div
            key={index}
            style={getBorderStyle(index)}
            className="p-1 text-right"
          >
            {renderCellContent(column.field)}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Footer;
