import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import logo2 from "../utility/Logo/icons/orangeIcon.png";
import logo from "../utility/Logo/TextLogo/onlyBlackText.svg";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import CryptoJS from "crypto-js";
import { decodeToken } from "../utility/constant";
import { setAlertWithTitle, setSpinnerLoading } from "../utility/Redux/modal";
import { CallAPIPromise } from "../billing/common";
import { setToken } from "../utility/Redux/security";
import Lottie from "lottie-react";
import spinner from "../utility/json/Spinner.json";
const NewLoginModal = () => {
  const [mobile, setMobile] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [gmailAccountDetails, setGmailAccountDetails] = useState("");
  return (
    <div className=" flex items-center justify-center bg-gray-100">
      {/* Modal */}
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white rounded-lg shadow-lg w-full max-w-md ">
          <div style={styles.header}>
            {/* Grid Background */}
            <div style={styles.gridContainer}>
              {[...Array(60)].map((_, index) => (
                <div
                  key={index}
                  style={
                    index <= 2
                      ? {}
                      : index <= 11
                      ? styles.gridCell
                      : index <= 15
                      ? {}
                      : index <= 19
                      ? {}
                      : index < 25
                      ? styles.gridCell
                      : index < 30
                      ? {}
                      : index <= 35
                      ? {}
                      : index <= 38
                      ? {}
                      : {}
                  }
                ></div>
              ))}
            </div>

            {/* Logo Section */}
          </div>
          <div style={{}} className="p-6 ">
            {/* Modal Content */}

            <div
              style={{
                marginTop: -50,
              }}
              className="flex flex-col justify-end    items-center "
            >
              <div style={{}} className="flex items-center ">
                <img
                  src={logo2}
                  style={{
                    height: 35,
                    width: 35,
                  }}
                />
                <img
                  src={logo}
                  style={{
                    width: 60,
                  }}
                />
              </div>
              {/* <div
                  style={{
                    marginTop: -7,
                  }}
                  className="text-[25px]  font-semibold "
                >
                  Login with
                </div> */}
              <div
                style={{
                  marginTop: -2,
                }}
                className="text-sm text-gray-500 "
              >
                Please enter your details to{" "}
                <label className="font-semibold text-gray-600">SignIn</label>
              </div>
            </div>

            {!gmailAccountDetails && (
              <div style={{ marginTop: 10 }} className="text-center mt-4 ">
                <div className="flex justify-center space-x-4 ">
                  <GoogleOAuthProvider clientId="63087371523-ile4jrs20gof4l4mvequ2i91btj9kg0h.apps.googleusercontent.com">
                    <GoogleLogin
                      onSuccess={(response) => {
                        const details = decodeToken(response.credential);

                        const hmac = CryptoJS.HmacSHA256(
                          process.env.REACT_APP_AUTH_HMA_KEY,
                          process.env.REACT_APP_AUTH_SECRET_KEY
                        );
                        const hmacString = hmac.toString(CryptoJS.enc.Base64);
                        dispatch(setSpinnerLoading("Signing"));
                        CallAPIPromise(
                          "/api/crossCustomerProfile/listCustomerMaster",
                          {
                            table_prefix_master: "",
                            mobile: "",
                            user_email: details.email,
                          },
                          hmacString,
                          dispatch
                        )
                          .then(async (res) => {
                            if (res?.data?.response[0]) {
                              CallAPIPromise(
                                "/api/crossGoogleLogin/googleTokenVerify",
                                {
                                  token: response.credential,
                                }
                              )
                                .catch(() => {
                                  dispatch(
                                    setAlertWithTitle({
                                      title: "Alert",
                                      msg: "Google Login Error",
                                    })
                                  );
                                })
                                .then((response) => {
                                  if (response?.data?.userToken) {
                                    dispatch(
                                      setToken(response?.data?.userToken)
                                    );
                                    dispatch();
                                    //   setCustomerDetails(res.data.response[0])
                                    dispatch(setGmailAccountDetails(details));
                                  }
                                  //   onClose();
                                })
                                .finally(() => {
                                  dispatch(setSpinnerLoading(false));
                                });
                            } else {
                              dispatch(setSpinnerLoading(false));
                              dispatch(setGmailAccountDetails(details));
                            }
                          })
                          .catch(() => {
                            dispatch(setSpinnerLoading(false));
                          });
                      }}
                      width={250}
                      onError={() => console.log("Login Failed")}
                    ></GoogleLogin>
                  </GoogleOAuthProvider>
                </div>
                <p className="flex items-center text-gray-500 bg-red mt-4">
                  <span className="flex-1 flex border-t border-gray-200"></span>
                  <span className="mx-2">OR</span>
                  <span className="flex-1 flex border-t border-gray-200"></span>
                </p>
              </div>
            )}

            {gmailAccountDetails && (
              <>
                <div className="mb-4 mt-4">
                  <label className="block text-gray-900 text-[13px]">
                    Your Email
                  </label>
                  <input
                    type="email"
                    placeholder="Your Email"
                    value={gmailAccountDetails.email}
                    readOnly={true}
                    className="w-full px-3 py-2 border rounded-lg focus:outline-none text-[14px] focus:ring-2 focus:ring-blue-500"
                  />
                </div>

                <div className="mb-4 mt-4">
                  <label className="block text-gray-900 text-[13px]">
                    Your Name
                  </label>
                  <input
                    type="name"
                    placeholder="Your Name"
                    readOnly={true}
                    value={gmailAccountDetails.name}
                    onChange={(e) => setMobile(e.target.value)}
                    className="w-full px-3 py-2 border rounded-lg focus:outline-none text-[14px] focus:ring-2 focus:ring-blue-500"
                  />
                </div>
              </>
            )}

            {
              <div className="mt-4">
                <div className="mb-4">
                  <label className="block text-gray-900 text-[13px]">
                    Your Mobile Number
                  </label>
                  <input
                    type="email"
                    placeholder="Your Mobile"
                    // onKeyDown={(e) => {
                    //   if (e.code === "Enter" || e.code === "NumpadEnter") {
                    //     clickSendButton();
                    //   }
                    // }}
                    value={mobile}
                    onChange={(e) => setMobile(e.target.value)}
                    className="w-full px-3 py-2 border rounded-lg focus:outline-none text-[14px] focus:ring-2 focus:ring-blue-500"
                  />
                </div>

                <button
                  //   onClick={clickSendButton}
                  type="submit"
                  disabled={loading}
                  className="w-full bg-black flex justify-center items-center text-white py-2 rounded-lg hover:bg-gray-800"
                >
                  {loading && (
                    <Lottie
                      animationData={spinner}
                      loop={true}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        height: "20px",
                        marginRight: "8px",
                      }}
                    />
                  )}
                  Sign in
                </button>

                <button
                  //   onClick={onClose}
                  type="submit"
                  className="w-full bg-gray-200 mt-2 text-black py-2 rounded-lg hover:bg-gray-800"
                >
                  Cancel
                </button>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
};
const styles = {
  header: {
    borderTopLeftRadius: "12px",
    padding: 4,
    borderTopRightRadius: "12px",
    boxShadow: "inset 50px 50px 100px #ffffff", // Same horizontal and vertical offset
  },
  gridContainer: {
    inset: 0,
    display: "grid",
    gridTemplateColumns: "repeat(15, 1fr)",
    gridTemplateRows: "repeat(3, 1fr)",
    opacity: 0.1,
    pointerEvents: "none",
    height: 90,
    background: "linear-gradient(to bottom, orange, #eee3cc)",
  },
  gridCell: {
    border: "1px solid #e47036", // Light gray border for the grid
  },
  content: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    zIndex: 1,
  },
  logo: {
    width: "32px",
    height: "16px",
    backgroundColor: "#2563eb", // Blue color for the semi-circle
    borderTopLeftRadius: "16px",
    borderTopRightRadius: "16px",
  },
  title: {
    marginTop: "8px",
    fontSize: "20px",
    fontWeight: "600",
    color: "#111827", // Dark text color
  },
};

export default NewLoginModal;
