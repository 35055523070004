import { setAlertWithTitle, setSpinnerLoading } from "../Redux/modal";
import { CallAPIPromise } from "../../billing/common";
import { setOtpMobile } from "../Redux/security";
import Store from "../Redux/store";
import { getMessageWallet } from "../constant";

const SendOtp = (
  MobileNumber,
  prefix,
  randomNumber,
  email,
  dispatch,
  token,
  otpFor,
  templateFor
) => {
  return new Promise((resolve, reject) => {
    dispatch(setSpinnerLoading("Sending OTP"));
    const state = Store.getState();
    const companyDetails = state.profile.companyAddress;
    getMessageWallet(companyDetails.email, token, false, dispatch).then(
      (bal) => {
        if (bal > 0) {
          CallAPIPromise(
            `/api/whatsApp/send-whatsapp-message`,
            {
              type: "otp",
              toPhoneNumber: MobileNumber,
              INVNO: "",
              AMOUNT: "",
              prefix: prefix,
              companyEmail: companyDetails.email,
              message_for: otpFor ? otpFor : "",
              template_for: templateFor ? templateFor : "",
              rejected_reason: "",
            },
            token,
            dispatch
          )
            .then(async function (response) {
              if (response.status === 200) {
                dispatch(setOtpMobile(MobileNumber));
                resolve();
              } else {
                reject();
                dispatch(
                  setAlertWithTitle({
                    title: "Alert",
                    msg: "Something went wrong",
                  })
                );
              }
            })
            .catch(function (error) {
              console.log(error);
              reject();
              dispatch(
                setAlertWithTitle({
                  title: "Alert",
                  msg: error?.response?.data?.details
                    ? error?.response?.data?.details
                    : "Something went wrong",
                })
              );
            })
            .finally(() => {
              dispatch(setSpinnerLoading(false));
            });
        }
      }
    );
  });
};

export default SendOtp;
