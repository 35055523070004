import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Modal,
  Box,
  Typography,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Alert,
} from "@mui/material";

function PurchasePDFDetails({ file, status, onClose }) {
  const [processing, setProcessing] = useState(false);
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);

  const handleSubmit = async (file) => {
    if (!file) {
      alert("Please select a file.");
      return;
    }

    setProcessing(true);
    setResponse(null);
    setError(null);

    try {
      const formData = new FormData();
      formData.append("document", file);

      const result = await axios.post(
        "http://localhost:5000/process-document",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      if (result?.data) {
        setResponse(result.data); // Mock response for now
      }
    } catch (err) {
      setError(
        err.response?.data || "An error occurred while processing the document."
      );
    } finally {
      setProcessing(false);
    }
  };

  useEffect(() => {
    if (status) {
      handleSubmit(file);
    }
  }, [status]);

  return (
    <Modal
      open={status}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "80%",
          maxHeight: "90%",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          overflowY: "auto",
          borderRadius: 2,
        }}
      >
        <div className="flex justify-between">
          <Typography id="modal-title" variant="h6" component="h2">
            Purchase PDF Details
          </Typography>
          <button
            onClick={() => {
              onClose();
            }}
          >
            <i className="bi bi-x-square-fill  text-[22px] text-red-500 font-bold p-1"></i>
          </button>
        </div>

        {processing && <CircularProgress />}

        {response && (
          <>
            <Box mb={3}>
              <Typography variant="h6" gutterBottom>
                Party Details
              </Typography>
              <Typography variant="body2">
                <strong>Supplier Name:</strong>{" "}
                {response.partyData.supplier_name}
              </Typography>
              <Typography variant="body2">
                <strong>Invoice Date:</strong> {response.partyData.invoice_date}
              </Typography>
              <Typography variant="body2">
                <strong>Invoice ID:</strong> {response.partyData.invoice_id}
              </Typography>
              <Typography variant="body2">
                <strong>Total Amount:</strong> {response.partyData.total_amount}
              </Typography>
              <Typography variant="body2">
                <strong>Net Amount:</strong> {response.partyData.net_amount}
              </Typography>
              <Typography variant="body2">
                <strong>Supplier Address:</strong>{" "}
                {response.partyData.supplier_address}
              </Typography>
            </Box>

            <Box>
              <Typography variant="h6" gutterBottom>
                Extracted Items
              </Typography>
              <TableContainer component={Paper}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>#</TableCell>
                      <TableCell>Description</TableCell>
                      <TableCell>Quantity</TableCell>
                      <TableCell>Unit Price</TableCell>
                      <TableCell>Amount</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.keys(response.itemData).map((key, index) => (
                      <TableRow key={index}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>
                          {response.itemData[key]["line_item/description"]}
                        </TableCell>
                        <TableCell>
                          {response.itemData[key]["line_item/quantity"]}
                        </TableCell>
                        <TableCell>
                          {response.itemData[key]["line_item/unit_price"]}
                        </TableCell>
                        <TableCell>
                          {response.itemData[key]["line_item/amount"]}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </>
        )}

        {error && (
          <Alert severity="error" sx={{ mt: 3 }}>
            {error}
          </Alert>
        )}
      </Box>
    </Modal>
  );
}

export default PurchasePDFDetails;
