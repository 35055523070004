import React, { useState } from "react";
import GeniustextInput from "../utility/component/mastertext";
import AccountMasterHelp from "../help/listAccountMasterHelp";
import PurchaseBillHelp from "../help/purchaseBillHelp";
import { setAlertWithTitle } from "../utility/Redux/modal";
import TextBoxMui from "../utility/component/textBoxMui";
import { useDispatch, useSelector } from "react-redux";
import {
  selectDefultProfileSetting,
  selectWidth,
} from "../utility/Redux/profile";
import OutlinedInputCustom from "../utility/component/textInput";
import DropdownWithSearch from "../utility/component/dropDown";

const PartyDetails = ({
  partyDetails,
  setPartyDetails,
  refreshList,
  loadPurchaseBill,
  duplicatePurchaseBill,
  accountMasterHelpStatus,
  setAccountMasterHelpStatus,
  BillModeOption,
  rows,
  delayedFunction,
  TaxTypeOption,
  setPurcahseAmtFocus,
  BillTypeOption,
  purchaseAmtRef,
  setInputRefFoucs,
  purchaseRef,
  partyRef,
  muiTextBox,
  loadPurchaseBillPromise,
  setPurchaseBillNoFocus,
  partyNameRef,
  setPartyMasterCreaterModalStatus,
}) => {
  const [purchaseBillHelpStatus, setPurchaseBillHelpStatus] = useState(false);
  const windowWidth = useSelector(selectWidth);
  const dispatch = useDispatch();
  return (
    <div className="flex h-auto w-auto py-2  ">
      <div
        style={{ border: "1px solid #b5b3b3", borderRadius: "8px" }}
        className="pt-3 px-3 pb-3   bg-[#ffffff]"
      >
        <div className="-mt-7 bg-white text-[15px] font-semibold w-fit px-2">
          Issue Metarial
        </div>
        <div className="grid grid-cols-[160px,190px]  d:grid-cols-[200px,150px,180px] gap-3 mt-3 w-full ">
          <OutlinedInputCustom
            readOnly={true}
            value={partyDetails.INVNO}
            label={"Issue No."}
            search={() => {
              if (true) {
                setPurchaseBillHelpStatus(true);
              } else {
                dispatch(
                  setAlertWithTitle({
                    title: "Alert",
                    msg: `You don't have permission to modify the purchase.`,
                  })
                );
              }
            }}
          />
          <OutlinedInputCustom
            readOnly={true}
            value={partyDetails.INVNO}
            label={"Intend No."}
          />

          <OutlinedInputCustom
            type={"date"}
            onChange={(e) => {
              const inputValue = e.target.value;
              if (/^\d{4}-\d{2}-\d{2}$/.test(inputValue)) {
                setPartyDetails((prevState) => ({
                  ...prevState,
                  DATE: inputValue,
                }));
              }
            }}
            value={partyDetails.DATE}
            label={"Date"}
          />
        </div>
      </div>

      <div
        style={{ border: "1px solid #b5b3b3", borderRadius: "8px" }}
        className=" bg-white grid grid-cols-3 gap-3 p-3 flex-1 ml-3"
      >
        <div className="">
          <OutlinedInputCustom
            search={() => {
              setAccountMasterHelpStatus(true);
            }}
            onChange={() => {
              setAccountMasterHelpStatus(true);
            }}
            width={`calc(100% - 0px)`}
            value={partyDetails.NAME}
            label={"Department Name"}
            autoFucus={true}
            refrance={partyNameRef}
            onKeyDown={(e) => {
              if (e.code === "Enter" || e.code === "NumpadEnter") {
                if (partyDetails.NAME) {
                  setPurchaseBillNoFocus(true);
                } else {
                  setAccountMasterHelpStatus(true);
                }
              }
            }}
          />
        </div>

        <OutlinedInputCustom
          width={`calc(100% - 0px)`}
          value={partyDetails.NAME}
          label={"Issues To"}
          autoFucus={true}
          refrance={partyNameRef}
          onKeyDown={(e) => {
            if (e.code === "Enter" || e.code === "NumpadEnter") {
              if (partyDetails.NAME) {
                setPurchaseBillNoFocus(true);
              } else {
                setAccountMasterHelpStatus(true);
              }
            }
          }}
        />
        <OutlinedInputCustom
          width={`calc(100% - 0px)`}
          value={partyDetails.NAME}
          label={"Issued By"}
          autoFucus={true}
          refrance={partyNameRef}
          onKeyDown={(e) => {
            if (e.code === "Enter" || e.code === "NumpadEnter") {
              if (partyDetails.NAME) {
                setPurchaseBillNoFocus(true);
              } else {
                setAccountMasterHelpStatus(true);
              }
            }
          }}
        />
      </div>

      <AccountMasterHelp
        onRowClick={(row) => {
          setAccountMasterHelpStatus(false);
          setPartyDetails((old) => {
            return {
              ...old,
              NAME: row.row.NAME,
              ADDRESS: row.row.ADDRESS,
              MOBILE: row.row.MOBILE,
              TAX_NUMBER: row.row.TAX_NUMBER,
              ACCOUNT_NO: row.row.ACCOUNT_NO,
            };
          });

          const nearestInput = document.getElementById("PUR_BILL_NO");
          // Focus on the nearest input if it exists
          if (nearestInput) {
            setTimeout(() => {
              nearestInput.focus();
            }, 200);
          }
        }}
        status={accountMasterHelpStatus}
        onClose={() => {
          setAccountMasterHelpStatus(false);
        }}
        onCreatePartyClick={() => {
          setPartyMasterCreaterModalStatus(true);
        }}
        refreshList={refreshList}
      />
      <PurchaseBillHelp
        onRowClick={(row) => {
          delayedFunction(rows[0].id);
          setPurchaseBillHelpStatus(false);
          loadPurchaseBill(row.row.PUR_BILL_NO, row.row.ACCOUNT_NO);
        }}
        status={purchaseBillHelpStatus}
        onClose={() => {
          setPurchaseBillHelpStatus(false);
        }}
      />
    </div>
  );
};

export default PartyDetails;
