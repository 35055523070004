import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { selectPrefix, selectWidth } from "../../utility/Redux/profile";
import { setAlertWithTitle } from "../../utility/Redux/modal";
import { GetItemWiseSaleReport } from "./func";
import { selectToken } from "../../utility/Redux/security";
import FromToDateMobile from "../../utility/component/fromToDateMobile";
import { isItemHaveOffer } from "../../billing/function";
import BarcodeScanner from "../../utility/component/barcodeScanner";
import { ItemMasterHelp } from "../../help";
import FullWidthModal from "./fullWidthModal";
import { DownloadFile } from "../../utility/functions/firebase";
import { FormatNumber2 } from "../../utility/functions/formatNumber";
import ItemWisePurchaseHistoryHelp from "../../help/itemWisePurchaseHistory";
import { getItemByAlios } from "../../utility/constant";

const InputValues = ({ title, value, style, onClick }) => {
  return (
    <div style={style ? style : {}} className="flex py-1  pl-2">
      <div className="w-[150px] font-semibold">{title}</div>:
      <div onClick={onClick} className="ml-3 flex-1">
        {value}
      </div>
    </div>
  );
};
const SaleTaxReport = () => {
  const prefix = useSelector(selectPrefix);
  const [summaryList, setSummaryList] = useState("");
  const [tabScreen, setTabScreen] = useState("Item Info");
  const [fullWidthModalStatus, setFullWidthModalStatus] = useState(false);
  const [
    itemWisePurchaseHistoryHelpStatus,
    setItemWisePurchaseHistoryHelpStatus,
  ] = useState(false);
  const width = useSelector(selectWidth);
  const [barCode, setBarCode] = useState("");
  const [img, setImg] = useState("");
  const [offerCart, setOfferCart] = useState("");
  useEffect(() => {
    if (fullWidthModalStatus && summaryList) {
      const path = `retailx/items/${prefix + summaryList?.itemInfo[0].ICODE}`;
      DownloadFile(path).then((e) => {
        if (!e.includes("FirebaseError")) {
          setImg(e);
        }
      });
    }
  }, [fullWidthModalStatus, summaryList]);
  const [barCodeStatus, setBarCodeStatus] = useState(false);
  const [itemHelpStatus, setItemHelpStatus] = useState(false);
  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const [itemData, setItemData] = useState("");

  const onOkClick = () => {
    if (barCode || itemData) {
      const alios = barCode ? barCode : itemData?.ALIAS_CODE;
      getItemByAlios(prefix, alios, token, dispatch).then((icodeList) => {
        if (barCode) {
          setItemData(icodeList[0]);
          setBarCode("");
        }

        if (icodeList[0]) {
          GetItemWiseSaleReport(
            {
              table_prefix: prefix,
              ICODE: itemData ? itemData?.ICODE : icodeList[0].ICODE,
            },
            token,
            dispatch,
            setSummaryList,
            setFullWidthModalStatus
          );
          isItemHaveOffer(
            prefix,
            itemData ? itemData?.ICODE : icodeList[0].ICODE,
            token,
            dispatch
          ).then((re) => {
            if (re[0]) {
              setOfferCart(re[0]);
            } else {
              setOfferCart("");
            }
          });
        }
      });
    } else {
      dispatch(
        setAlertWithTitle({
          title: "Alert",
          msg: "Please select item first or scan",
        })
      );
    }
  };

  return (
    <div className=" bg-white flex flex-col h-full overflow-y-auto   ">
      <div className=" flex flex-col h-full p-3  ">
        <div>
          {
            <>
              <FromToDateMobile
                maxWidth={500}
                inputClear={() => {
                  setBarCode("");
                  setSummaryList("");
                  setItemData("");
                  setImg("");
                }}
                onItemClick={() => {
                  setItemHelpStatus(true);
                }}
                itemData={itemData}
                hideDate={true}
                otherButton={
                  <div className="flex items-center w-full">
                    <button
                      onClick={() => {
                        setBarCodeStatus(true);
                      }}
                      className="mx-2 bg-orange px-6 w-full  h-fit py-1 shadow-md  rounded-sm"
                    >
                      Scan Item
                    </button>
                  </div>
                }
                submit={onOkClick}
                inputTextPlaceHolder={"Bar Code"}
                inputTextValue={
                  barCode
                    ? barCode
                    : itemData?.ALIAS_CODE
                    ? itemData.ALIAS_CODE
                    : ""
                }
                onChangeInputText={(e) => {
                  setBarCode(e.target.value);
                }}
              />
            </>
          }
        </div>
        {summaryList &&
          width > 500 &&
          itemDetailsUi(setTabScreen, tabScreen, img, summaryList, offerCart)}
      </div>

      <BarcodeScanner
        onChange={(bar) => {
          getItemByAlios(prefix, bar, token, dispatch).then((icodeList) => {
            setBarCode(bar);
            setItemData(icodeList[0]);
            if (icodeList[0]) {
              GetItemWiseSaleReport(
                {
                  table_prefix: prefix,
                  ICODE: icodeList[0].ICODE,
                },
                token,
                dispatch,
                setSummaryList,
                setFullWidthModalStatus
              );
            }
          });
        }}
        setStatus={setBarCodeStatus}
        status={barCodeStatus}
      />
      <ItemMasterHelp
        status={itemHelpStatus}
        onClose={() => setItemHelpStatus(false)}
        onRowClick={(p) => {
          setItemData(p.row);
          setBarCode("");
          setSummaryList("");
          setItemHelpStatus(false);
        }}
      />

      {width <= 500 && summaryList && (
        <FullWidthModal
          data={summaryList}
          status={fullWidthModalStatus}
          handleClose={() => {
            setFullWidthModalStatus(false);
            setItemData("");
            setBarCode("");
            setSummaryList("");
          }}
          offerCart={offerCart}
          ItemDataUi={itemDetailsUi(
            setTabScreen,
            tabScreen,
            img,
            summaryList,
            offerCart
          )}
          img={img}
        />
      )}

      <ItemWisePurchaseHistoryHelp
        status={itemWisePurchaseHistoryHelpStatus}
        onClose={() => {
          setItemWisePurchaseHistoryHelpStatus(false);
        }}
        ICODE={summaryList[0] ? summaryList[0].ICODE : ""}
      />
    </div>
  );
};

export default SaleTaxReport;
function itemDetailsUi(setTabScreen, tabScreen, img, summaryList, offerCart) {
  return (
    <div
      style={{
        maxWidth: 500,
        border: "1px solid gray",
      }}
      className=" h-full rounded-sm mt-2 overflow-hidden"
    >
      <div className="flex space-x-4 border-b">
        {/* Item Info Tab */}
        <div
          onClick={() => setTabScreen("Item Info")}
          className={`flex items-center space-x-2 flex-1 px-4 py-2 border-b-2 ${
            tabScreen === "Item Info" ? " bg-orange" : "border-transparent"
          } hover:border-orange cursor-pointer`}
        >
          <i className="bi bi-info-circle text-xl"></i>
          <span className="font-medium">Item </span>
        </div>

        {/* Pricing Tab */}
        <div
          onClick={() => setTabScreen("Pricing")}
          className={`flex items-center flex-1 space-x-2 px-4 py-2 border-b-2 ${
            tabScreen === "Pricing" ? " bg-orange" : "border-transparent"
          } hover:border-orange cursor-pointer`}
        >
          <i className="bi bi-tag text-xl"></i>
          <span className="font-medium">Pricing</span>
        </div>

        {/* Supplier Tab */}
        <div
          onClick={() => setTabScreen("Supplier")}
          className={`flex items-center flex-1 space-x-2 px-4 py-2 border-b-2 ${
            tabScreen === "Supplier" ? " bg-orange" : "border-transparent"
          } hover:border-orange cursor-pointer`}
        >
          <i className="bi bi-people text-xl"></i>
          <span className="font-medium">Supplier</span>
        </div>
      </div>

      {/* Item Info Tab Content */}
      {tabScreen === "Item Info" && (
        <div className="p-3 flex">
          <div className="flex justify-start mb-4">
            <div className="flex">
              <div className="flex justify-start border shadow-sm rounded-[10px] h-[100px] w-[100px] bg-gray-200">
                {img && (
                  <img
                    src={img}
                    alt="Selected"
                    className="h-full w-full rounded-[10px]"
                  />
                )}
              </div>
            </div>
          </div>
          <div className="ml-3">
            {console.log(summaryList)}
            <InputValues
              title={"Item Code"}
              value={summaryList?.itemInfo[0]?.ICODE}
            />
            <InputValues
              title={"Bar Code"}
              value={summaryList?.itemInfo[0]?.ALIAS_CODE}
            />
            <InputValues
              title={"Bal. Stock"}
              value={summaryList?.itemInfo[0]?.BAL_STOCK}
            />
            <InputValues
              title={"Expiry Date"}
              value={summaryList?.itemInfo[0]?.EXP_DATE}
            />
            <InputValues
              title={"Last Pur. Price"}
              value={
                summaryList?.itemInfo[0]?.PUR_PRICE
                  ? FormatNumber2(summaryList?.itemInfo[0]?.PUR_PRICE)
                  : 0
              }
            />
            <InputValues
              title={"Current Sale Price"}
              value={
                summaryList?.itemInfo[0]?.SALE_PRICE
                  ? FormatNumber2(summaryList?.itemInfo[0]?.SALE_PRICE)
                  : 0
              }
            />
            <InputValues
              title={"CGST%"}
              value={
                summaryList?.itemInfo[0]?.TAX1_RATE
                  ? summaryList?.itemInfo[0]?.TAX1_RATE + " %"
                  : ""
              }
            />
            <InputValues
              title={"SGST%"}
              value={
                summaryList?.itemInfo[0]?.TAX2_RATE
                  ? summaryList?.itemInfo[0]?.TAX2_RATE + " %"
                  : ""
              }
            />

            {offerCart && (
              <InputValues
                style={{
                  color: "red",
                  cursor: "pointer",
                  borderTop: "1px solid #e3e1e0",
                  marginTop: "12px",
                }}
                title={"Offer Price"}
                value={FormatNumber2(offerCart.OFFER_SALE_PRICE)}
              />
            )}
            {offerCart && (
              <InputValues
                style={{
                  color: "red",
                  cursor: "pointer",
                }}
                title={"From"}
                value={moment(offerCart.FDATE).format("DD-MM-YYYY")}
              />
            )}
            {offerCart && (
              <InputValues
                style={{
                  color: "red",
                  cursor: "pointer",
                }}
                title={"To"}
                value={moment(offerCart.TDATE).format("DD-MM-YYYY")}
              />
            )}
          </div>
        </div>
      )}

      {/* Pricing Tab Content */}
      {tabScreen === "Pricing" && (
        <div className="p-3">
          <h4 className="font-semibold mb-2">Price History</h4>
          {summaryList?.priceHistory.map((price, index) => (
            <div key={index} className="border-b py-2">
              <InputValues
                title={"Date"}
                value={moment(price.DATE).format("DD-MM-YYYY")}
              />
              <InputValues title={"Supplier Name"} value={price.NAME} />
              <InputValues
                title={"Purchase Price"}
                value={FormatNumber2(price.PUR_PRICE)}
              />
              <InputValues
                title={"Sale Price"}
                value={FormatNumber2(price.SALE_PRICE)}
              />
            </div>
          ))}
        </div>
      )}

      {/* Supplier Tab Content */}
      {tabScreen === "Supplier" && (
        <div className="p-3 flex-1">
          <h4 className="font-semibold mb-2">Suppliers</h4>
          {summaryList?.suppliers.map((supplier, index) => (
            <div key={index} className="py-2 flex-1 ">
              <InputValues title={"Supplier"} value={supplier.SUPPLIER} />
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

function ItemStatusData({ img, summaryList, width, offerCart }) {
  return (
    <div
      style={
        width > 500
          ? {
              maxWidth: 400,
              border: "1px solid gray",
            }
          : {
              width: "100%",
            }
      }
      className=" h-full  rounded-sm mt-2 py-3  "
    >
      <div className="flex justify-center mb-4">
        <div className="flex">
          <div className="flex justify-center border shadow-sm rounded-[10px] h-[100px] w-[100px] bg-gray-200">
            {img && (
              <img
                src={img}
                alt="Selected"
                className="h-full w-full rounded-[10px]"
              />
            )}
          </div>
        </div>
      </div>

      <InputValues
        title={"Item Code"}
        value={summaryList?.itemInfo[0]?.ICODE}
      />
      <InputValues
        title={"Bar Code"}
        value={summaryList?.itemInfo[0]?.ALIAS_CODE}
      />
      <InputValues
        title={"Bal. Stock"}
        value={summaryList?.itemInfo[0]?.BAL_STOCK}
      />
      <InputValues
        title={"Expiry Date"}
        value={
          summaryList?.itemInfo[0]?.EXP_DATE
            ? moment(summaryList?.itemInfo[0]?.EXP_DATE).format("YYYY-MM-DD")
            : ""
        }
      />
      <InputValues
        title={"Last Pur. Price"}
        value={
          summaryList?.itemInfo[0]?.PUR_PRICE
            ? FormatNumber2(summaryList?.itemInfo[0]?.PUR_PRICE)
            : 0
        }
      />
      <InputValues
        title={"Current Sale Price"}
        value={
          summaryList?.itemInfo[0]?.SALE_PRICE
            ? FormatNumber2(summaryList?.itemInfo[0]?.SALE_PRICE)
            : 0
        }
      />
      <InputValues
        title={"CGST%"}
        value={
          summaryList?.itemInfo[0]?.TAX1_RATE
            ? summaryList?.itemInfo[0]?.TAX1_RATE + " %"
            : ""
        }
      />
      <InputValues
        title={"SGST%"}
        value={
          summaryList?.itemInfo[0]?.TAX2_RATE
            ? summaryList?.itemInfo[0]?.TAX2_RATE + " %"
            : ""
        }
      />
      <InputValues
        title={"Party Name"}
        value={summaryList?.itemInfo[0]?.NAME}
      />
      {offerCart && (
        <InputValues
          style={{
            color: "red",
            cursor: "pointer",
            borderTop: "1px solid #e3e1e0",
            marginTop: "12px",
          }}
          title={"Offer Price"}
          value={FormatNumber2(offerCart.OFFER_SALE_PRICE)}
        />
      )}
      {offerCart && (
        <InputValues
          style={{
            color: "red",
            cursor: "pointer",
          }}
          title={"From"}
          value={moment(offerCart.FDATE).format("DD-MM-YYYY")}
        />
      )}
      {offerCart && (
        <InputValues
          style={{
            color: "red",
            cursor: "pointer",
          }}
          title={"To"}
          value={moment(offerCart.TDATE).format("DD-MM-YYYY")}
        />
      )}

      {/* {width >500 &&   <InputValues title={'Purchase History'} value={"Open"} style={{color:'green', cursor: 'pointer'}} onClick={()=>setPurchasehelpStatus(true)} />} */}
    </div>
  );
}
