import React, { useEffect, useRef, useState } from "react";

import GeniustextInput from "../../utility/component/mastertext";

import ModalHeaderStyle from "../../utility/component/styles/modalHeader";
import {
  DeleteButton,
  SaveButton,
  UndoButton,
} from "../../utility/component/buttons";
import { useNavigate } from "react-router-dom";
import CompanyHelp from "../../help/companyHelp";
import { CallAPIPromise } from "../../billing/common";
import { useDispatch } from "react-redux";
import {
  setAlertWithTitle,
  setSpinnerLoading,
} from "../../utility/Redux/modal";
import TemplateMasterHelp from "../../help/templatehelp";
import { templateForList } from "../../utility/constant";

const TemplateMaster = () => {
  const formRef = useRef(null);
  const defaultValues = {
    company_email: "",
    template_id: "",
    template_text: "",
    template_for: "",
  };
  const [data, setData] = useState(defaultValues);
  const [companyHelpStatus, setCompanyHelpStatus] = useState(false);
  const [deleteVisible, setDeleteVisible] = useState(false);
  const [templateHelpStats, setTemplateHelpStatus] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    const inputFields = formRef.current.querySelectorAll(
      "input, select,textarea"
    );

    inputFields.forEach((field, index) => {
      field.addEventListener("keydown", (event) => {
        if (event.key === "Enter") {
          event.preventDefault(); // Prevent form submission
          const nextIndex = (index + 1) % inputFields.length;
          if (index === 20) {
            inputFields[0].focus();
          } else {
            if (index === 7) {
              inputFields[11].focus();
            } else {
              inputFields[nextIndex].focus();
            }
          }
        }
      });
    });

    return () => {
      inputFields.forEach((field) => {
        field.removeEventListener("keydown", () => {});
      });
    };
  }, []);
  const navigate = useNavigate();
  const onChangeValues = (v) => {
    const { name, value } = v.target;
    setData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const handleSaveData = () => {
    dispatch(setSpinnerLoading("Saving"));
    CallAPIPromise("/api/userSettings/messageTemplateMaster", data)
      .then((response) => {
        console.log("");
      })
      .then(() => {
        setData(defaultValues);
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
        setDeleteVisible(false);
      });
  };

  const handleDELETEdata = () => {
    dispatch(setSpinnerLoading("Deleting"));
    CallAPIPromise("/api/userSettings/messageTemplateDelete", data)
      .then((response) => {
        console.log("");
      })
      .then(() => {
        setData(defaultValues);
        dispatch(
          setAlertWithTitle({
            title: "Deleted",
            msg: "Data deleted successfully",
          })
        );
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
        setDeleteVisible(false);
      });
  };

  return (
    <div
      ref={formRef}
      className="w-full  flex justify-center bg-[#f5f5f7] h-full  px-3"
    >
      <div className="text-sm   rounded-sm   flex flex-col   h-full py-4 ">
        <div>
          <ModalHeaderStyle
            header={"Template Master"}
            onClose={() => {
              navigate("/masters");
            }}
          />
        </div>
        <div
          style={{
            height: `90%`,
          }}
          className="   rounded-b-sm  bg-[#fdfffe] flex flex-col   p-1 "
        >
          <div className="h-[16px] text-red-500 pl-6">{alert}</div>
          <div className=" h-full overflow-y-auto overflow-x-hidden  text-black   flex-col l:flex l:flex-row px-4 pb-4">
            <div className="h-fit   l:ml-4 ">
              <div className="border min-w-[380px]  border-[#b5b3b3] grid grid-cols-1 bg-white rounded-sm p-4 mt-2">
                <GeniustextInput
                  title={"Company Email"}
                  type={"text"}
                  grid={true}
                  value={data.company_email}
                  name={"company_email"}
                  search={() => {
                    setCompanyHelpStatus(true);
                  }}
                  onChange={onChangeValues}
                />

                <GeniustextInput
                  title={"Template ID"}
                  type={"mobile"}
                  grid={true}
                  search={() => {
                    setTemplateHelpStatus(true);
                  }}
                  value={data.template_id}
                  name={"template_id"}
                  onChange={onChangeValues}
                />

                <GeniustextInput
                  title={"Template For"}
                  grid={true}
                  option={templateForList.map((o) => {
                    return {
                      name: o,
                      value: o,
                    };
                  })}
                  value={data.template_for}
                  name={"template_for"}
                  onChange={onChangeValues}
                />

                <GeniustextInput
                  title={"Template Text"}
                  textArea={true}
                  grid={true}
                  value={data.template_text}
                  name={"template_text"}
                  onChange={onChangeValues}
                />
              </div>

              <div className="mt-2 flex justify-end">
                {deleteVisible && <DeleteButton onClick={handleDELETEdata} />}

                <div className="ml-2">
                  <SaveButton onClick={handleSaveData} />
                </div>

                <div>
                  {/* {id ? (
                    <ModifyButton onClick={saveCustomerMaster} />
                  ) : (
                  )} */}
                </div>

                <div className="ml-1">
                  <UndoButton />
                </div>
              </div>
            </div>
            <CompanyHelp
              status={companyHelpStatus}
              onClose={() => {
                setCompanyHelpStatus(false);
              }}
              onRowClick={(parma) => {
                setCompanyHelpStatus(false);
                setData((p) => {
                  return {
                    ...p,
                    company_email: parma.row.company_email,
                  };
                });
              }}
            />
            <TemplateMasterHelp
              status={templateHelpStats}
              onClose={() => {
                setTemplateHelpStatus(false);
              }}
              onRowClick={(param) => {
                setData(param.row);
                setDeleteVisible(true);
                setTemplateHelpStatus(false);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TemplateMaster;
