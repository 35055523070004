import React, { useEffect } from "react";
import GeniustextInput from "../../utility/component/mastertext";
import { useSelector } from "react-redux";
import { selectWidth } from "../../utility/Redux/profile";
import { PartyColumns } from "./columns";
import currencySymbolMap from "currency-symbol-map/map";
import { getAllISOCodes } from "iso-country-currency";
// import "/node_modules/flag-icons/css/flag-icons.min.css";
import GeniusSelectWithTitle from "../../utility/component/geniusComponents/customeSelect";

const AddressInformation = ({
  address,
  setAddress,
  selectedState,
  setAddressMissingId,
  addressMissingId,
  stateList,
  cityList,
  setSelectedCountry,
  handleCountryChange,
  countries,
  pinCode,
  setPinCode,
  handleStateChange,
  city,
  setCity,
  selectedCountry,
  stop,
  setStop,
  currencyName,
  currencySymbol,
  setCurrencyName,
  setCurrencySymbol,
  setTabScreen,
  formRef,
  addressRef,
  setSelectedState,
}) => {
  const windowWidth = useSelector(selectWidth);
  const currencyData = currencySymbolMap;

  const symbleList = getAllISOCodes();
  const symbelList2 = symbleList.map((d) => {
    return {
      name: `${d.symbol} ---- ${d.currency}`,
      value: d.currency,
      isoCode: d.iso,
      symbol: d.symbol,
    };
  });

  useEffect(() => {
    if (formRef) {
      const inputFields = formRef.current.querySelectorAll("input, select");

      // Add a keydown event listener to each input and select field
      inputFields.forEach((field, index) => {
        field.addEventListener("keydown", (event) => {
          if (event.key === "Enter") {
            event.preventDefault(); // Prevent form submission
            const nextIndex = (index + 1) % inputFields.length;
            if (index === 7) {
              setTabScreen(1);
            } else {
              inputFields[nextIndex].focus();
            }
          }
        });
      });

      // Cleanup event listeners on unmount
      return () => {
        inputFields.forEach((field) => {
          field.removeEventListener("keydown", () => {});
        });
      };
    }
  }, []);
  const columns = PartyColumns(windowWidth);
  const symbolList = currencyName
    ? symbelList2.filter((column) => column.value === currencyName)
    : [
        {
          symbol: "",
        },
      ];
  const symbl = symbolList[0]?.symbol;
  useEffect(() => {
    if (addressMissingId) {
      const inputFields = addressRef.current.querySelectorAll("input, select");
      inputFields.forEach((element) => {
        if (element.id === addressMissingId) {
          element.focus();
          setAddressMissingId("");
        }
      });
    }
  }, [addressMissingId]);
  return (
    <div
      ref={addressRef}
      style={{
        height:
          windowWidth < 500 ? "410px" : windowWidth <= 1280 ? "310px" : "350px",
      }}
      className="h-full min-w-[320px]  grid-grid-cols-1 gap-2 pt-4 px-2 overflow-y-auto"
    >
      <GeniustextInput
        title={"Address"}
        grid={true}
        type={"text"}
        id={"address"}
        value={address}
        onChange={(e) => {
          setAddress(e.target.value);
        }}
        autofocus={true}
      />
      <div className="grid grid-cols-1 l:grid-cols-2 gap-2">
        <GeniusSelectWithTitle
          height={"160px"}
          grid={true}
          title={"Country"}
          country={true}
          options={countries}
          value={selectedCountry}
          setValue={setSelectedCountry}
          handleClick={(e) => {
            handleCountryChange(e);
          }}
        />

        <GeniusSelectWithTitle
          height={"160px"}
          grid={true}
          title={"State"}
          options={stateList}
          value={selectedState}
          setValue={setSelectedState}
          handleClick={(e) => {
            handleStateChange(e);
          }}
        />
      </div>

      <div className="grid grid-cols-1 l:grid-cols-2 gap-2">
        <GeniusSelectWithTitle
          height={"160px"}
          grid={true}
          title={"City"}
          options={cityList}
          value={city}
          setValue={setCity}
          handleClick={(e) => {
            setCity(e);
          }}
        />

        <GeniustextInput
          title={"Pin-Code"}
          grid={true}
          type={"text"}
          value={pinCode}
          onChange={(e) => {
            setPinCode(e.target.value);
          }}
        />
      </div>
      <div className="grid grid-cols-1 l:grid-cols-2 gap-2">
        <GeniusSelectWithTitle
          height={"200px"}
          title={"Currency Name"}
          country={true}
          grid={true}
          options={symbelList2}
          value={currencyName}
          setValue={setCurrencyName}
          handleClick={(e) => {
            setCurrencyName(e);
          }}
        />

        <GeniustextInput
          title={"Currency Symbol"}
          type={"text"}
          grid={true}
          value={symbl}
        />
      </div>
      <div></div>
    </div>
  );
};

export default AddressInformation;
