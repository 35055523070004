import { Fade, Modal } from "@mui/material";
import React from "react";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: "auto",
  width: "auto",
  borderRadius: "8px",
  overflow: "hidden",
  backgroundColor: "white",
};
const EmptyModal = ({ status, data, onClose, afterCreate, Component }) => {
  return (
    <Modal
      open={status}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Fade
        in={status}
        style={{
          transitionDelay: status ? "100ms" : "0ms",
        }}
      >
        <div style={style}>
          {Component ? (
            <Component onClose={onClose} afterCreate={afterCreate} />
          ) : null}
          {data ? data : null}
        </div>
      </Fade>
    </Modal>
  );
};

export default EmptyModal;
