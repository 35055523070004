import React from "react";
import { useSelector } from "react-redux";
import { selectCompanyAddress } from "../../Redux/profile";
import "./print.css";
const InvoiceTemplate = ({ data, rows }) => {
  const { sellerInfo, invoiceDetails, items, totals, terms, columns } = data;
  const companyDetails = useSelector(selectCompanyAddress);
  console.log(companyDetails);

  return (
    <div
      style={{
        padding: "20px",
        border: "1px solid black",
        fontFamily: "Arial, sans-serif",
        fontSize: "12px",
      }}
    >
      <div id="print-component">
        {/* Header Section */}
        <div style={{ textAlign: "center", marginBottom: "10px" }}>
          <h3 style={{ fontWeight: "bold", fontSize: "16px" }}>
            {companyDetails?.companyName}
          </h3>
          <p style={{ fontSize: "14px" }}>DUPLICATE INVOICE</p>
        </div>

        {/* Seller Information */}
        <div style={{ marginBottom: "10px" }}>
          <div style={{ fontWeight: "bold" }}>{sellerInfo.name}</div>
          <div>{sellerInfo.address}</div>
          <div>Mobile: {sellerInfo.phone}</div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "10px",
            marginBottom: "10px",
          }}
        >
          <div>
            <div>
              Invoice No.: <strong>{invoiceDetails.invoiceNo}</strong>
            </div>
            <div>
              Dated: <strong>{invoiceDetails.date}</strong>
            </div>
          </div>
          <div>
            <div>GSTIN: {invoiceDetails.gstin}</div>
          </div>
        </div>

        {/* Itemized Table */}
        <table
          style={{
            border: "1px solid black",
            marginTop: "20px",
            marginBottom: "20px",
            width: "100%",
          }}
        >
          <thead>
            <tr>
              {columns.map((c) => (
                <th
                  style={{
                    border: "1px solid black",
                    fontWeight: "bold",
                    padding: "5px",
                  }}
                  key={c.field}
                >
                  {c.headerName}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {rows.map((r, index) => (
              <tr key={index}>
                {columns.map((c) => (
                  <td
                    style={{ border: "1px solid black", padding: "5px" }}
                    key={c.field}
                  >
                    {r[c.field]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>

        {/* Totals */}
        {/* <div style={{ textAlign: "right", marginBottom: "10px" }}>
          <p>Taxable Amount: {totals.taxableAmount}</p>
          <p>CGST Total: {totals.cgst}</p>
          <p>SGST Total: {totals.sgst}</p>
          <p style={{ fontWeight: "bold" }}>Grand Total: {totals.grandTotal}</p>
        </div> */}
      </div>
    </div>
  );
};

export default InvoiceTemplate;
